import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { updateLocalStorageObject } from "../../../services/Auth";
import { patchAvatar, patchBanner } from "../../../services/no-user";
import "../UserSettings.scss";

const profiles = Array.from(
  { length: 5 },
  (_, i) =>
    `https://assets.next.wolfiesports.com/media/assets/user/profile/${i}.png`
);

const banners = Array.from(
  { length: 5 },
  (_, i) =>
    `https://assets.next.wolfiesports.com/media/assets/user/banner/${i}.png`
);

type ProfilePictureContentProps = {
  user: any;
  updateUser: () => void;
};

export const ProfilePictureContent = ({
  user,
  updateUser,
}: ProfilePictureContentProps) => {
  const [selectedProfile, setSelectedProfile] = useState(user.avatar);
  const [selectedBanner, setSelectedBannner] = useState(user.banner);

  useEffect(() => {
    updateBanner();
  }, [selectedBanner]);

  useEffect(() => {
    updateAvatar();
  }, [selectedProfile]);

  const handleSelectProfile = (profileSelected: string) => () => {
    setSelectedProfile(profileSelected);
  };

  const handleBannerProfile = (bannerSelected: string) => () => {
    setSelectedBannner(bannerSelected);
  };

  const updateAvatar = async () => {
    await patchAvatar(user.userId, selectedProfile.toString()).then(() => {
      updateUser();
      updateLocalStorageObject("user", "settings.avatar", selectedProfile);
    });
  };

  const updateBanner = async () => {
    await patchBanner(user.userId, selectedBanner.toString()).then(() => {
      updateUser();
      updateLocalStorageObject("user", "settings.banner", selectedBanner);
    });
  };

  return (
    <div className="account-settings-content">
      <div className="title">
        <h4>
          <FormattedMessage id={"webapp.settings.media"} />
        </h4>
        <h5>
          <FormattedMessage id={"webapp.settings.media.profile-picture"} />
        </h5>
        <span>
          <FormattedMessage
            id={"webapp.settings.media.select-your-profile-picture"}
          />
        </span>
      </div>
      <div className="content">
        <div className="profile-selection">
          {profiles.map((imgSrc, index) => (
            <div
              key={index}
              className={`profile-item ${
                selectedProfile === index.toString() ? "active" : ""
              }`}
              onClick={handleSelectProfile(index.toString())}
            >
              <img
                src={imgSrc}
                alt={`Profile ${index}`}
                className="profile-card"
              />
            </div>
          ))}
        </div>
      </div>
      <div className="title">
        <h5>
          <FormattedMessage id={"webapp.settings.media.banner"} />
        </h5>
        <span>
          <FormattedMessage
            id={"webapp.settings.media.select-your-profile-banner"}
          />
        </span>
      </div>
      <div className="content">
        <div className="banner-selection">
          {banners.map((imgSrc, index) => (
            <div
              key={index}
              className={`banner-item ${
                selectedBanner === index.toString() ? "active" : ""
              }`}
              onClick={handleBannerProfile(index.toString())}
            >
              <img
                src={imgSrc}
                alt={`Banner ${index}`}
                className="banner-card"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
