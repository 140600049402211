import { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import "./OnBoarding.scss";
import { OnBoardingFavoriteGames } from "./Steps/FavoriteGames";
import { OnBoardingGameAccounts } from "./Steps/GameAccounts";
import { OnBoardingSuccess } from "./Steps/Success";
import { OnBoardingUserSettings } from "./Steps/UserSettings";
import { patchCountry } from "../../services/no-user";
import { getUserCountry } from "../../utils/userCountry";

export const OnBoardingPage = () => {
  const user = JSON.parse(localStorage.getItem("user") ?? "");
  const [currentStep, setCurrentStep] = useState(0);
  const steps = [0, 1, 2, 3];

  const handleNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const handlePrevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  useEffect(() => {
    if (user) {
      patchCountry(user.identity.id, getUserCountry());
    }
  }, [user]); 

  return (
    <div className="onboarding-page-container">
      <h3>
        <FormattedMessage id={"webapp.onboarding.welcome"} />{" "}
        {user.identity.nickname} !
      </h3>
      <div className="stepper-container">
        {steps.map((step) => (
          <div
            key={step}
            className={currentStep === step ? "step active" : "step"}
          ></div>
        ))}
      </div>

      {currentStep === 0 && (
        <OnBoardingFavoriteGames user={user} nextStep={handleNextStep} />
      )}
      {currentStep === 1 && (
        <OnBoardingGameAccounts
          nextStep={handleNextStep}
          prevStep={handlePrevStep}
        />
      )}
      {currentStep === 2 && (
        <OnBoardingUserSettings
          nextStep={handleNextStep}
          prevStep={handlePrevStep}
        />
      )}
      {currentStep === 3 && <OnBoardingSuccess />}
    </div>
  );
};
