import { Button, ButtonType, NCInput } from "@wolfiesports/srm-component";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import {
  GameAccountModel,
  UserGameAccountModel,
} from "../../../models/GameModel";
import {
  getPublicGameAccounts,
  getUserGameAccounts,
  putUserGameAccount,
} from "../../../services/no-games";

type OnBoardingGameAccountsProps = {
  nextStep: () => void;
  prevStep: () => void;
};
export const OnBoardingGameAccounts = ({
  nextStep,
  prevStep,
}: OnBoardingGameAccountsProps) => {
  const [publicGameAccounts, setPublicGameAccounts] = useState<
    GameAccountModel[]
  >([]);
  const [userGameAccounts, setUserGameAccounts] = useState<
    Record<string, string>
  >({});

  const fetchUserGameAccounts = async () => {
    try {
      const res = await getUserGameAccounts();
      const accounts: Record<string, string> = {};
      res.list.forEach((account: UserGameAccountModel) => {
        accounts[account.id] = account.gamerTag;
      });
      setUserGameAccounts(accounts);
    } catch (error) {
      console.error("Error fetching user game accounts", error);
    }
  };

  const fetchPublicGameAccounts = async () => {
    try {
      const res = await getPublicGameAccounts();
      setPublicGameAccounts(res.list);
    } catch (error) {
      console.error("Error fetching public game accounts", error);
    }
  };

  const handleInputChange = (id: string, value: string) => {
    setUserGameAccounts((prev) => ({ ...prev, [id]: value }));
  };

  const sendUserGameAccount = async (id: string, gamerTag: string) => {
    try {
      await putUserGameAccount(id, gamerTag);
    } catch (error) {
      console.error("Error updating game account", error);
    }
  };

  const saveChanges = () => {
    Object.entries(userGameAccounts).forEach(([id, gamerTag]) => {
      sendUserGameAccount(id, gamerTag);
    });

    nextStep();
  };

  useEffect(() => {
    if (!publicGameAccounts.length) {
      fetchPublicGameAccounts();
    }
    if (!Object.keys(userGameAccounts).length) {
      fetchUserGameAccounts();
    }
  }, []);

  return (
    <div className="game-account-container">
      <h5>
        <FormattedMessage
          id={"webapp.onboarding.game-account.enter-your-games-accounts"}
        />
      </h5>
      <div className="forms-container">
        {publicGameAccounts.map((account) => (
          <div className="form-item" key={account.id}>
            <div
              className="game-logo"
              style={{
                backgroundImage: `url(${process.env.REACT_APP_AWS_S3_BUCKET}/media/games/${account.id}/LogoImage)`,
              }}
            />
            <NCInput
              label={account.title}
              value={userGameAccounts[account.id] || ""}
              onChange={(value: string) => handleInputChange(account.id, value)}
            />
          </div>
        ))}
      </div>
      <Button
        setClick={prevStep}
        label={<FormattedMessage id={"webapp.onboarding.back-button"} />}
        type={ButtonType.TEXT}
      />
      <Button
        setClick={saveChanges}
        label={<FormattedMessage id={"webapp.onboarding.save-changes"} />}
      />
    </div>
  );
};
