export enum senderType {
    User,
    Admin,
    Server,
}

export type NotificationEvent =
    | PremiumNotificationEvent
    | CoinNotificationEvent
    | WalletNotificationEvent
    | ChallengeNotificationEvent
    | TournamentNotificationEvent;

export interface NotificationEventAbstract {
    type: NotificationType;
    target: string;
    date: Date | number;
    state?: NotificationState;
    i18n?: string;
    link?: { text: string; to: string };
    showInList?: boolean;
}

export enum NotificationType {
    Premium = 'premium',
    Coin = 'coin',
    Wallet = 'wallet',
    Challenge = 'challenge',
    Tournament = 'tournament',
}

export interface PremiumNotificationEvent extends NotificationEventAbstract {
    type: NotificationType.Premium;
    content: {
        status: string;
        paymentState: string;
    };
}

export interface CoinNotificationEvent extends NotificationEventAbstract {
    type: NotificationType.Coin;
    content: {
        reason: string;
        origin: string;
        coin?: number;
        originId?: string;
        originRank?: string;
        originName?: string;
    };
    totalCoins?: number;
}

export interface WalletNotificationEvent extends NotificationEventAbstract {
    type: NotificationType.Wallet;
    content: {
        origin: string;
        reason: string;
        value: number;
        originRank?: string;
        originName?: string;
    };
    totalCoins?: number;
}

export interface ChallengeNotificationEvent extends NotificationEventAbstract {
    type: NotificationType.Challenge;
    content: {
        from: string;
        reason: string;
        value: {
            cactusPoint: number;
            cashPrize: number;
        };
        originRank?: string;
        originName?: string;
    };
    totalPrize?: number;
    totalCoins?: number;
}

export interface TournamentNotificationEvent extends NotificationEventAbstract {
    type: NotificationType.Tournament;
    content: {
        origin: string;
        reason: string;
        firstplay: boolean;
        rank: string;
        g4gid?: string;
        route?: string;
    };
}

export interface BaseNotification {
    state: NotificationState;
    i18n: string;
    sendBNotification: boolean;
    showInList: boolean;
    // eslint-disable-next-line
    [others: string]: any;
}

export enum NotificationState {
    default = 'default',
    good = 'good',
    warning = 'warning',
    pending = 'pending',
    error = 'error',
    coin = 'coin',
    challengeRewarded = 'challengeRewarded',
    challengeEnded = 'challengeEnded',
    trainingFailed = 'trainingFailed',
    trainingSuccess = 'trainingSuccess',
    tournament = 'tournament',
}

export type NotificationTemplate = {
    // eslint-disable-next-line
    [name in NotificationType]: Array<BaseNotification>;
};
