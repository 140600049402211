import { axiosAuthenticated } from "./Auth";
import { Team } from "../models/Team";

export const getUserTeams = async (userId: string): Promise<Array<Team>> => {
    let tmpTeams: Array<Team> = [];

    try {
        const res = await axiosAuthenticated.get(`${process.env.REACT_APP_BASE_API_NF_URL}/teams/users/${userId}`);
        tmpTeams.push(...res.data.list);
    } catch (error) {
        console.error(error);
    }

    return tmpTeams;
};
