import TagManager from "react-gtm-module";

export class GoogleTagService {
    static initialize() {
        const gtmId = process.env.REACT_APP_GTAG_ID || "";
        TagManager.initialize({ gtmId });
    }

    static locationView(pageViewed: string) {
        TagManager.dataLayer({
            dataLayer: {
                ...this.defaultOptions,
                event: 'pageview',
                page: pageViewed
            }
        });
    }

    static event(event: GaEvent, options?: TagDataLayer) {
        TagManager.dataLayer({
            dataLayer: {
                ...this.defaultOptions,
                ...options,
                page_category: options && options.page_category || 'none',
                page: options && options.page || 'none',
                event
            }
        });
    }

    static getUserType(subscriptionStatus: string) {
        if (subscriptionStatus) {
            return {
                'NONE': 'freemium',
                'TRIAL': 'trialist',
                'PREMIUM': 'premium',
            }[subscriptionStatus];
        }
        return 'anonymous';
    }

    static get defaultOptions() {
        let user;
        try {
            user = JSON.parse(localStorage.getItem("user") ?? "")
        } catch (error) {
            user = null
        }

        return {
            language: user?.settings?.lang || 'none',
            currency: user?.settings?.currency?.toLowerCase() || 'none',
            user_type: this.getUserType(user?.account?.subscriptionStatus) || 'none',
            user_id: user?.identity?.id || 'none',
            user_email: user?.identity?.email || 'none',
            affiliate: user?.account?.registrationOrigin || 'wolfiesports',
            device: window.navigator.userAgent,
            page: 'none',
            page_category: 'none'
        };
    }

}

export interface TagDataLayer {
    page_category: TagCategory | string;
    page: TagPage | string;
    id?: string | null;
    game?: string;
    language?: string;
    currency?: string;
    user_type?: string;
    user_id?: string;
    affiliate?: string;
    device?: string;
    [key: string]: string | number | null | undefined;
}

export enum TagCategory {
    'generic' = 'generic',
    'account' = 'account',
    'tournament' = 'tournament',
    'organisation' = 'organisation',
}

export enum TagPage {
    'login' = 'login',
    'register' = 'register',
    'premium_offer' = 'premium_offer',
    'premium_member' = 'premium_member',
    'billing' = 'billing',
    'tournaments_home' = 'tournaments_home',
    'tournaments_bracket' = 'tournaments_bracket',
    'tournaments_game_selection' = 'tournaments_game_selection',
    'tournaments_game' = 'tournaments_game',
    'tournaments_game_change' = 'tournaments_game_change',
    'tournaments_item' = 'tournaments_item',
    'teams_home' = 'teams_home',
    'teams_item' = 'teams_item',
    'profile' = 'profile',
    'my_account' = 'my_account',
    'wallet' = 'wallet',
    'organisation_item' = 'organisation_item',
    'gameaccount' = 'gameaccount',
    'challenges' = 'challenges',
}

export enum GaEvent {
    'register_confirmed' = 'register_confirmed',
    'tournament' = 'tournament',
    'premium_trial' = 'premium_trial',
    'premium_paid' = 'premium_paid',
    'challenges_initiated' = 'challenges_initiated',
}