import './FavoriteGames.scss';

import { Button, ButtonType, Icon, IconType } from '@wolfiesports/srm-component';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { useEffect, useState } from 'react';

import { GameModel } from '../../models/GameModel';

// UserService / NoGameService a ajouter
import { Pagination } from '@wolfiesports/srm-component';

import { PublicUserModel, UserModel } from '../../models/User';
import { getPublicGames, getUserGames, putUserGames } from '../../services/no-games';

const USER_GAMES_DISPLAYED: number = 4;

interface UserGamesProps {
    user: PublicUserModel;
    setCallback?: any;
}

export const FavoriteGames: React.FunctionComponent<UserGamesProps> = ({
    user, setCallback
}) => {
    const [edit, setEdit] = useState<boolean>(false);
    const [games, setGames] = useState<Array<GameModel>>([]);
    const [favoriteGames, setFavoriteGames] = useState<
        Array<string>
    >([]);
    const [gamesToRemove, setGamesToRemove] = useState<Array<string>>([]);
    const [page, setPage] = useState<number>(1);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [currentUser, setCurrentUser] = useState<UserModel | null>();
    const intl = useIntl();

    useEffect(() => {
        if (games.length === 0) {
            getGames();
        }
        getFavoriteGames();
        if (!currentUser) {
            getCurrentUser();
        }
    }, [user]);

    const getGames = async () => {
        const res = await getPublicGames();
        setGames(res.list);
    };

    const getCurrentUser = async () => {
        const u = JSON.parse(localStorage.getItem("user") ?? "");
        setCurrentUser(u);
    };

    const extractGameId = (route: string) => {
        var index = route.indexOf("/");
        if (index !== -1) {
            return route.substring(index + 1);
        }
        return "";

    }

    const getFavoriteGames = async () => {
        if (!user) {
            return;
        }
        let res = await getUserGames(user.userId);
        setFavoriteGames([]);

        let tempGames = new Array();
        res.list.forEach((game: GameModel) => {
            tempGames.push(extractGameId(game.route));
        });
        setFavoriteGames(tempGames);
        setIsLoading(false);
    };

    const addGame = async (gameId: string) => {
        if (favoriteGames.find((tg) => tg === gameId)) {
            gamesToRemove.push(gameId);
            setGamesToRemove([...gamesToRemove]);
            const tmp = favoriteGames.filter((tg) => tg !== gameId);
            setFavoriteGames(tmp);
        } else {
            if (gamesToRemove.find((g) => g === gameId)) {
                const t = gamesToRemove.filter((g) => g !== gameId);
                setGamesToRemove([...t]);
            }

            let updatedFavoriteGames = [...favoriteGames, gameId];
            setFavoriteGames(updatedFavoriteGames);
        }
    };

    const saveGames = async () => {
        let tmp = new Array<string>;
        favoriteGames.forEach((g) => {
            tmp.push(g)
        })

        putUserGames(user.userId, tmp)
        setCallback(true);
        setEdit(false);
    };

    const renderUserGames = (): React.ReactElement => {
        return (
            <>
                {favoriteGames.map((g, idx: number) => {
                    if (
                        idx >= (page - 1) * USER_GAMES_DISPLAYED &&
                        idx < page * USER_GAMES_DISPLAYED
                    ) {
                        return (
                            <div
                                key={idx}
                                className={'favorite-games__container--list--element no-click'}
                            >
                                <img
                                    src={`${process.env.REACT_APP_AWS_S3_BUCKET}/media/games/${g}/LogoImage`}
                                    alt={g}
                                />
                            </div>
                        );
                    } else {
                        return null;
                    }
                })}
            </>
        );
    };

    const renderGamesList = (): React.ReactElement => {
        return (
            <>
                {games.map((g: GameModel, idx: number) => {
                    return (
                        <div
                            key={idx}
                            className={`favorite-games__container--list--element ${favoriteGames.find((tg) => tg === g.id) ? 'active' : ''
                                }`}
                            onClick={() => addGame(g.id)}
                        >
                            <img
                                src={`${process.env.REACT_APP_AWS_S3_BUCKET}/media/games/${g.id}/LogoImage`}
                                alt={g.title}
                            />
                        </div>
                    );
                })}
            </>
        );
    };

    return (
        <div className="favorite-games">
            {!isLoading &&
                (favoriteGames.length > 0 ||
                    (currentUser && currentUser.identity.id === user.userId)) && (
                    <>
                        <div className="favorite-games__container">
                            <div className="favorite-games__container--title">
                                <div>
                                    <FormattedMessage
                                        id="favorite.games.title"
                                        description="TEAM - GAMES - TITLE"
                                    />
                                </div>
                                {currentUser && currentUser.identity.id === user.userId && (
                                    <div
                                        className="favorite-games--edit"
                                        onClick={() => setEdit(!edit)}
                                    >
                                        {!edit && (
                                            <div title={intl.formatMessage({ id: 'tooltip.edit' })}>
                                                <Icon
                                                    icon={IconType.Edit}
                                                    width={20}
                                                    height={20}
                                                />
                                            </div>
                                        )}
                                        {edit && (
                                            <div title={intl.formatMessage({ id: 'tooltip.close' })}>
                                                <Icon
                                                    icon={IconType.Cross}
                                                    width={20}
                                                    height={20}
                                                />
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="favorite-games__container">
                            <div
                                className="favorite-games__container--list"
                                data-testid="favorite-games-list"
                            >
                                {!edit && renderUserGames()}
                                {edit && renderGamesList()}
                            </div>
                        </div>
                        {favoriteGames.length > USER_GAMES_DISPLAYED && !edit && (
                            <Pagination
                                collectionSize={favoriteGames.length}
                                pageSize={USER_GAMES_DISPLAYED}
                                page={page}
                                setPage={setPage}
                            />
                        )}

                        <div
                            onClick={saveGames}
                            className="favorite-games--save"
                            style={{ display: edit ? '' : 'none' }}
                        >
                            <Button
                                type={ButtonType.SECONDARY}
                                label={intl.formatMessage({ id: 'team.button.save' })}
                            />
                        </div>
                    </>
                )}
        </div>
    );
};
