import { NCToastContainer, ToastPosition } from "@wolfiesports/srm-component";
import { useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import { BrowserRouter } from "react-router-dom";
import "./App.scss";
import { Footer } from "./components/Footer/Footer";
import { WebappMenu } from "./components/Menu/WebappMenu";
import { BaseRouter } from "./routes/BaseRouter";
import { useStoreActions, useStoreState } from "./store";
import { WebSocketProvider } from "./utils/websocket/WebSocketProvider";
import { GoogleTagService } from "./services/googletag.service";
import { CheckinBroadcast } from "./components/Broadcast/CheckinBroadcast/CheckinBroadcast";

const loadLocaleData = (locale: string) => {
  switch (locale) {
    case "en":
      return import("./_translations/en.json");
    case "fr":
      return import("./_translations/fr.json");
    default:
      return import("./_translations/en.json");
  }
};

export const App = () => {
  const toastsList = useStoreState((s) => s.toast.list);
  const deleteToast = useStoreActions((a) => a.toast.deleteToast);

  const [currentLanguage, setCurrentLanguage] = useState(
    localStorage.getItem("i18nextLng") ?? "en"
  );
  const [messages, setMessages] = useState({});

  useEffect(() => {
    loadLocaleData(currentLanguage).then((msgs) => {
      setMessages(msgs);
    });
  }, [currentLanguage]);

  useEffect(() => {
    const storedLang = localStorage.getItem("i18nextLng");
    if (storedLang) {
      setCurrentLanguage(storedLang);
    }
  }, []);

  useEffect(() => {
    const handleLanguageChange = () => {
      const newLang = localStorage.getItem("i18nextLng") ?? "en";
      setCurrentLanguage(newLang);
      loadLocaleData(newLang).then(setMessages);
    };

    window.addEventListener("languageChange", handleLanguageChange);
    GoogleTagService.initialize();

    return () => {
      window.removeEventListener("languageChange", handleLanguageChange);
    };
  }, []);

  useEffect(() => {
    GoogleTagService.locationView(window.location.pathname)
  }, [window.location]);

  return (
    <IntlProvider
      locale={currentLanguage}
      messages={messages}
      defaultLocale="en"
    >
      <WebSocketProvider>
    
          <BrowserRouter>
            <div className="page-layout">
              <WebappMenu />
              <div className="router-layout">
               <CheckinBroadcast />
                <BaseRouter />
              </div>
              <Footer />
            </div>
          </BrowserRouter>
      </WebSocketProvider>
      <NCToastContainer
        toastList={toastsList}
        onDeleteToast={deleteToast}
        position={ToastPosition.TOP_RIGHT}
        duration={3000}
      />
    </IntlProvider>
  );
};
