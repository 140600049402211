import { ReactSRMWrapper } from "@cactus/react-srm-wrapper";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  EventSend,
  EventSubscribe,
  useWebSocket,
} from "../../utils/websocket/WebSocketProvider";

export const ChallengePage = () => {
  const originUrl = process.env.REACT_APP_BASE_BUNDLE_URL + "webapp-challenges";
  const [event, setEvent] = useState<any>(null);
  const navigate = useNavigate();
  const [language, setLanguage] = useState(
    localStorage.getItem("i18nextLng") ?? "en"
  );

  useEffect(() => {
    const handleLanguageChange = () => {
      const newLang = localStorage.getItem("i18nextLng") ?? "en";
      setLanguage(newLang);
    };

    window.addEventListener("languageChange", handleLanguageChange);

    return () => {
      window.removeEventListener("languageChange", handleLanguageChange);
    };
  }, []);
  const { subscribe, sendMessage } = useWebSocket();

  const handleNavigateByUrl = async (url: string) => {
    navigate(url);
  };


  const sendSrmEvent = async(data?: any): Promise<any> => {
    setEvent({ type: 'ws', data: data });
  }

  const handleSubscribe = async (data?: EventSubscribe) => {
    if (data?.rt) {
      subscribe('srm', data.id,
        (room, message) => {
            if (!message || !room) {
                return;
            }
            sendSrmEvent(message);
            
      });
    }
    return;
  };

  const handleEventSend = async (data?: EventSend) => {
    if (data) {
      sendMessage(data.type, data.target, data.content);
    }
  };

  const eventHandlers = {
    navigateByUrl: handleNavigateByUrl,
    "srm.subscribe": handleSubscribe,
    "srm.rt-send": handleEventSend,
    /*'login-user': this.loginUser.bind(this),
    'seo.generate-tags': this.setSEO.bind(this),
    'modal.payment': this.openPaymentModal.bind(this),
    'gtag.tournament_registration': this.gtagEvent.bind(this),*/
  };

  return (
    <ReactSRMWrapper
      key={language}
      originUrl={originUrl}
      exportPath={"nicecactus.challenges"}
      nav={navigate}
      language={language}
      basename={"/challenges"}
      eventHandlers={eventHandlers}
      event={event}
    />
  );
};
