import { IntlShape } from 'react-intl';
import {
    BaseNotification,
    ChallengeNotificationEvent,
    NotificationEvent,
    NotificationState,
    NotificationTemplate,
    NotificationType,
    PremiumNotificationEvent,
    TournamentNotificationEvent,
    WalletNotificationEvent,
} from '../models/notification-event.model';

export class NotificationService {
    static formatEventToNotification(
        event: NotificationEvent,
        intl?: IntlShape,
    ): NotificationEvent | null {
        event.date = new Date(event.date).setMilliseconds(0);
        return this.getTranslation(event, intl);
    }

    private static getTranslation(
        event: NotificationEvent,
        intl?: IntlShape,
    ): NotificationEvent | null {
        if (!event.type) {
            return null;
        }
        const base: Array<BaseNotification> = notificationTemplate[event.type];
        if (!base) {
            return null;
        }

        switch (event.type) {
            case NotificationType.Premium:
                return this.formatPremium(base, event, intl);
            case NotificationType.Wallet:
                return this.formatWallet(base, event, intl);
            case NotificationType.Challenge:
                return this.formatChallenge(base, event, intl);
            case NotificationType.Tournament:
                return this.formatTournament(base, event, intl);
            default:
                return null;
        }
    }

    private static formatPremium(
        base: Array<BaseNotification>,
        event: PremiumNotificationEvent,
        intl?: IntlShape,
    ): NotificationEvent | null {
        const string = base.find(
            (b) =>
                event.content &&
                b.status === event.content.status &&
                b.paymentState === event.content.paymentState,
        );
        if (!string) {
            return null;
        }
        event.i18n = intl?.formatMessage({ id: string.i18n });
        event.state = string.state;
        event.showInList = string.showInList;
        if (string.link) {
            event.link = string.link;
        }
        return event;
    }

    private static formatWallet(
        base: Array<BaseNotification>,
        event: WalletNotificationEvent,
        intl?: IntlShape,
    ): NotificationEvent | null {
        const string = base.find(
            (b) =>
                event.content &&
                b.from === event.content.origin &&
                b.reason === event.content.reason,
        );
        if (!string) {
            return null;
        }

        event.state = string.state;
        event.showInList = string.showInList;
        const totalPrize = event.content.value;
        event.totalCoins = totalPrize;

        event.i18n = intl?.formatMessage(
            { id: string.i18n },
            {
                prize: totalPrize,
                pos: event.content.originRank,
                name: event.content.originName,
            },
        );

        return event;
    }

    private static formatChallenge(
        base: Array<BaseNotification>,
        event: ChallengeNotificationEvent,
        intl?: IntlShape,
    ): NotificationEvent | null {
        const string = base.find(
            (b) =>
                event.content &&
                b.from === event.content.from &&
                b.reason === event.content.reason,
        );
        if (!string) {
            return null;
        }

        event.state = string.state;
        event.showInList = string.showInList;

        const { cactusPoint, cashPrize } = event.content.value;
        event.totalPrize = cashPrize;
        event.totalCoins = cactusPoint;

        event.i18n = intl?.formatMessage(
            { id: string.i18n },
            {
                prize: cashPrize,
                coin: cactusPoint,
                pos: event.content.originRank,
                name: event.content.originName,
            },
        );

        return event;
    }

    private static formatTournament(
        base: Array<BaseNotification>,
        event: TournamentNotificationEvent,
        intl?: IntlShape,
    ): NotificationEvent | null {
        const string = base.find(
            (b) =>
                event.content &&
                b.origin === event.content.origin &&
                b.reason === event.content.reason &&
                b.firstPlay === event.content.firstplay,
        );
        if (!string) {
            return null;
        }
        event.state = string.state;
        event.showInList = string.showInList;
        event.link = string.link || {};

        event.i18n = intl?.formatMessage(
            { id: string.i18n },
            {
                rank: event.content.rank,
            },
        );

        if (
            (event.content.g4gid || event.content?.route) &&
            event.link &&
            typeof event.link !== 'string'
        ) {
            let tID;
            try {
                tID =
                    event.content?.route?.split('/')[2] || event.content.g4gid;
            } catch (e) {
            }
            event.link = {
                to: `/tournament/${tID}/matchs`,
                text: event.link.text
                    ? intl?.formatMessage({ id: event.link.text }) || ''
                    : '',
            };
        }
        return event;
    }
}

const notificationTemplate: NotificationTemplate = {
    [NotificationType.Premium]: [
        {
            state: NotificationState.good,
            status: 'PREMIUM',
            paymentState: 'PAID',
            i18n: 'notification.premium.started',
            sendBNotification: false,
            showInList: true,
        },
        {
            state: NotificationState.warning,
            status: 'PREMIUM',
            paymentState: 'PAYMENTFAILED',
            i18n: 'notification.premium.warning',
            sendBNotification: false,
            showInList: true,
        },
        {
            state: NotificationState.warning,
            status: 'PREMIUM',
            paymentState: 'PAYMENTFAILURE',
            i18n: 'notification.premium.warning',
            sendBNotification: false,
            showInList: true,
        },
        {
            state: NotificationState.warning,
            status: 'PREMIUM',
            paymentState: 'CANCELED',
            i18n: 'notification.premium.canceled',
            sendBNotification: false,
            showInList: true,
        },
        {
            state: NotificationState.error,
            status: 'NONE',
            paymentState: 'END',
            i18n: 'notification.premium.none',
            link: {
                text: 'notification.premium.renew',
                to: '/premium',
            },
            sendBNotification: false,
            showInList: true,
        },
        {
            state: NotificationState.good,
            status: 'PREMIUM',
            paymentState: 'GIFT',
            i18n: 'notification.premium.gift',
            sendBNotification: false,
            showInList: true,
        },
    ],
    [NotificationType.Coin]: [
        {
            state: NotificationState.coin,
            status: 'COIN',
            from: 'training',
            reason: 'TRAIN_FIRST_TRAINING',
            i18n: 'notification.coin.premium_first_time',
            sendBNotification: false,
            showInList: true,
        },
        {
            state: NotificationState.coin,
            status: 'COIN',
            from: 'training',
            reason: 'TRAIN_GA_FINISHED',
            i18n: 'notification.coin.premium',
            i18n_v2: 'notification.coin.premiumV2',
            link: {
                text: 'notification.view_more',
            },
            sendBNotification: false,
            showInList: true,
        },
        {
            state: NotificationState.trainingFailed,
            status: 'COIN',
            from: 'training',
            reason: 'TRAIN_GA_FAILED',
            i18n: 'notification.coin.training_failed',
            i18n_v2: 'notification.coin.training_failedV2',
            link: {
                text: 'notification.view_more',
            },
            sendBNotification: false,
            showInList: true,
        },
        {
            state: NotificationState.coin,
            status: 'COIN',
            from: 'training',
            reason: 'TRAIN_MODULE_FINISHED',
            i18n: 'notification.coin.premium_finish_module',
            sendBNotification: false,
            showInList: true,
        },
        {
            state: NotificationState.coin,
            status: 'COIN',
            from: 'training',
            reason: 'TRAIN_PROGRAM_FINISHED',
            i18n: 'notification.coin.premium_finish_program',
            sendBNotification: false,
            showInList: true,
        },
        {
            state: NotificationState.coin,
            status: 'COIN',
            from: 'auth',
            reason: 'WEEKLY_CONNECTION',
            i18n: 'notification.coin.weekly',
            sendBNotification: false,
            showInList: true,
        },
        {
            state: NotificationState.coin,
            status: 'COIN',
            from: 'training',
            reason: 'CHALLENGE_REWARD',
            i18n: 'notification.coin.reward_challenge',
            sendBNotification: false,
            showInList: true,
        },
        {
            state: NotificationState.coin,
            status: 'COIN',
            from: 'training',
            reason: 'CHALLENGE_FIRST_PARTICIPATION',
            i18n: 'notification.coin.first_challenge',
            sendBNotification: false,
            showInList: true,
        },
    ],
    [NotificationType.Wallet]: [
        {
            state: NotificationState.coin,
            status: 'COIN',
            from: 'training',
            reason: 'CHALLENGE_REWARD',
            i18n: 'notification.coin.reward_challenge',
            sendBNotification: false,
            showInList: true,
        },
    ],
    [NotificationType.Challenge]: [
        {
            state: NotificationState.challengeRewarded,
            status: 'REWARDED',
            from: 'training',
            reason: 'CHALLENGE_REWARD',
            i18n: 'notification.challenge.reward',
            sendBNotification: false,
            showInList: true,
        },
        {
            state: NotificationState.challengeEnded,
            status: 'ENDED',
            from: 'training',
            reason: 'CHALLENGE_END',
            i18n: 'notification.challenge.end',
            sendBNotification: false,
            showInList: true,
        },
    ],
    [NotificationType.Tournament]: [
        {
            state: NotificationState.tournament, // tournament start
            origin: 'tournament.match',
            reason: 'checkin',
            firstPlay: true, // do not change
            i18n: 'notification.tournament.started_details',
            link: {
                text: 'notification.tournament.started_details_link',
            },
            sendBNotification: true,
            showInList: true,
        },
        {
            state: NotificationState.tournament, // match started: checkin
            origin: 'tournament.match',
            reason: 'checkin',
            firstPlay: false, // do not change
            i18n: 'notification.tournament.checkin_notification_message',
            link: {
                text: 'notification.tournament.match_checkin_here_link',
            },
            sendBNotification: true,
            showInList: true,
        },
        {
            state: NotificationState.tournament, // match checkin well
            origin: 'tournament.match',
            reason: 'checkedin',
            firstPlay: false, // do not change
            i18n: 'notification.tournament.match_checked_id',
            sendBNotification: true,
            showInList: false,
        },
        {
            state: NotificationState.tournament, // tournament match start
            origin: 'tournament.match',
            reason: 'started',
            firstPlay: false, // do not change
            i18n: 'notification.tournament.match_started',
            link: {
                text: 'notification.tournament.match_started_link',
            },
            sendBNotification: true,
            showInList: true,
        },
        {
            state: NotificationState.tournament, // tournament need to confirm score
            origin: 'tournament.match',
            reason: 'confirmation',
            firstPlay: false, // do not change
            i18n: 'notification.tournament.match_confirmation',
            link: {
                text: 'notification.tournament.match_confirmation_link',
            },
            sendBNotification: true,
            showInList: true,
        },
        {
            state: NotificationState.tournament, // tournament end: ranking
            origin: 'tournament',
            reason: 'rank',
            firstPlay: false, // do not change
            i18n: 'notification.tournament.rank',
            link: {
                text: 'notification.tournament.rank_link',
            },
            sendBNotification: true,
            showInList: true,
        },
    ],
};
