import { Button, NCInput } from "@wolfiesports/srm-component";
import { CognitoUser } from "amazon-cognito-identity-js";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useChangePassword, useSession } from "../../../utils/session/session";
import "../UserSettings.scss";

export const PasswordContent = () => {
  const intl = useIntl();
  const passwordChangeCognito = useChangePassword();
  const session = useSession();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  useEffect(() => {
    setIsButtonDisabled(
      oldPassword === "" ||
        newPassword === "" ||
        confirmNewPassword === "" ||
        confirmNewPassword !== newPassword
    );
  }, [oldPassword, newPassword, confirmNewPassword]);

  const handleOldPasswordChange = (value: string) => {
    setOldPassword(value);
  };

  const handleNewPasswordChange = (value: string) => {
    setNewPassword(value);
  };

  const handleConfirmNewPasswordChange = (value: string) => {
    setConfirmNewPassword(value);
  };

  const sendForm = async () => {
    setSuccess("");
    setError("");
    passwordChangeCognito.mutate(
      {
        user: session.data as CognitoUser,
        oldPassword,
        newPassword,
      },
      {
        onSuccess: () => {
          setSuccess(
            intl.formatMessage({ id: "webapp.settings.password.success" })
          );
        },
        onError: (e) => {
          setError(e.message);
        },
      }
    );
  };

  return (
    <div className="account-settings-content">
      <div className="title">
        <h4>
          <FormattedMessage id={"webapp.settings.password"} />
        </h4>
        <h5>
          <FormattedMessage
            id={"webapp.settings.password.personal-information"}
          />
        </h5>
        <span>
          <FormattedMessage
            id={"webapp.settings.password.personal-information-sentence"}
          />
        </span>
      </div>
      <div className="content">
        <div className="input-styles">
          <h6>
            <FormattedMessage id={"webapp.settings.password.old-password"} />
          </h6>
          <NCInput
            value={oldPassword}
            type="password"
            label={intl.formatMessage({
              id: "webapp.settings.password.old-password",
            })}
            onChange={handleOldPasswordChange}
          />
        </div>
        <div className="input-styles">
          <h6>
            <FormattedMessage id={"webapp.settings.password.new-password"} />
          </h6>
          <NCInput
            value={newPassword}
            type="password"
            label={intl.formatMessage({
              id: "webapp.settings.password.new-password",
            })}
            onChange={handleNewPasswordChange}
          />
        </div>
        <div className="input-styles">
          <h6>
            <FormattedMessage id={"webapp.settings.password.confirm"} />
          </h6>
          <NCInput
            value={confirmNewPassword}
            type="password"
            label={intl.formatMessage({
              id: "webapp.settings.password.confirm",
            })}
            onChange={handleConfirmNewPasswordChange}
          />
        </div>
        {error !== "" && <span className="error-message">{error}</span>}
        {success !== "" && <span className="success-message">{success}</span>}
        <div className="send-button">
          <Button
            setClick={sendForm}
            label={intl.formatMessage({ id: "webapp.settings.password.send" })}
            disabled={isButtonDisabled}
          />
        </div>
      </div>
    </div>
  );
};
