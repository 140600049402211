import { NCLoader } from "@wolfiesports/srm-component";
import { useCallback, useEffect, useState } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { AuthPage } from "../pages/Auth/Auth";
import { ForgotPasswordPage } from "../pages/Auth/Forgot/Forgot";
import { ChallengePage } from "../pages/Challenge/Challenge";
import { ContactPage } from "../pages/Contact/Contact";
import { GameAccountPage } from "../pages/GameAccount/GameAccount";
import { PrivacyPage } from "../pages/Legals/Privacy";
import { TermOfSalesPage } from "../pages/Legals/TermOfSales";
import { TermOfUsePage } from "../pages/Legals/TermOfUse";
import { NotificationPage } from "../pages/Notifications/Notifications";
import { OnBoardingPage } from "../pages/OnBoarding/OnBoarding";
import { OrganisationPage } from "../pages/Organisation/Organisation";
import { PremiumPage } from "../pages/Premium/Premium";
import { ProfilePage } from "../pages/Profile/Profile";
import { UserSettingsPage } from "../pages/Settings/UserSettings";
import { TeamPage } from "../pages/Team/Team";
import { TournamentPage } from "../pages/Tournament/Tournament";
import { WalletPage } from "../pages/Wallet/Wallet";
import { getUserInfos } from "../services/Auth";
import { useStoreActions } from "../store";

enum USER_STATUS {
  LOGGED,
  NOT_LOGGED,
  WAITING,
}

const PrivateRoute = () => {
  const logOutAction = useStoreActions((a) => a.login.logOutAction);
  const [userStatus, setUserStatus] = useState<USER_STATUS>(
    USER_STATUS.WAITING
  );

  const checkUser = useCallback(async () => {
    const user = await getUserInfos();
    if (!user) {
      setUserStatus(USER_STATUS.NOT_LOGGED);
    } else {
      setUserStatus(USER_STATUS.LOGGED);
    }
  }, []);

  useEffect(() => {
    if (userStatus === USER_STATUS.WAITING) {
      checkUser();
    }
  }, []);

  switch (userStatus) {
    case USER_STATUS.NOT_LOGGED:
      logOutAction();
      return <Navigate to={"/login"} replace />;
    case USER_STATUS.LOGGED:
      return <Outlet />;
    case USER_STATUS.WAITING:
      return <NCLoader />;
  }
};

export const BaseRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/tournament/home" />} />
      <Route path="/tournament">
        <Route index element={<TournamentPage />} />
        <Route path="*" element={<TournamentPage />} />
      </Route>
      <Route path="/login" element={<AuthPage />} />
      <Route path="/forgot" element={<ForgotPasswordPage />} />
      <Route path="/register" element={<AuthPage />} />
      <Route path="/terms-of-use" element={<TermOfUsePage />} />
      <Route path="/terms-of-sales" element={<TermOfSalesPage />} />
      <Route path="/privacy" element={<PrivacyPage />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route element={<PrivateRoute />}>
        <Route path="/profile/:userId" element={<ProfilePage />} />
        <Route path="/onboarding" element={<OnBoardingPage />} />
        <Route path="/team" element={<TeamPage />} />
        <Route path="/team/profile/:teamid" element={<TeamPage />} />
        <Route path="/game-accounts" element={<GameAccountPage />} />
        <Route path="/wallet" element={<WalletPage />} />
        <Route path="/settings" element={<UserSettingsPage />} />
        <Route path="/premium" element={<PremiumPage />} />
        <Route path="/notifications" element={<NotificationPage />} />
        <Route
          path="/organisation/:organisationDomain?"
          element={<OrganisationPage />}
        />
        <Route path="/challenges/*" element={<ChallengePage />} />
      </Route>
      <Route path="*" element={<Navigate to="/tournament/home" />} />
    </Routes>
  );
};
