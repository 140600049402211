import { Button } from "@wolfiesports/srm-component";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

export const OnBoardingSuccess = () => {
  const navigate = useNavigate();
  const handleRedirecToDashboard = () => {
    navigate("/");
  };

  return (
    <div className="game-account-container">
      <h5>
        <FormattedMessage
          id={"webapp.onboarding.success.you-can-now-use-wolfie"}
        />
      </h5>
      <div className="forms-container"></div>
      <Button
        setClick={handleRedirecToDashboard}
        label={
          <FormattedMessage
            id={"webapp.onboarding.favorite-games.start-button"}
          />
        }
      />
    </div>
  );
};
