import { Button, NCToastType, ToastModel } from "@wolfiesports/srm-component";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { GameModel } from "../../../models/GameModel";
import { UserModel } from "../../../models/User";
import {
  getPublicGames,
  getUserGames,
  putUserGames,
} from "../../../services/no-games";
import { useStoreActions } from "../../../store";
import "../UserSettings.scss";

type MyGamesContentProps = {
  user: UserModel;
};

export const MyGamesContent = ({ user }: MyGamesContentProps) => {
  const [selectedGames, setSelectedGames] = useState<string[]>([]);
  const [games, setGames] = useState<Array<GameModel>>();
  const [isGamesFetched, setIsGamesFetched] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const pushToast = useStoreActions((a) => a.toast.pushToast);
  const intl = useIntl();

  useEffect(() => {
    if (!isGamesFetched) {
      getGames();
    }
  }, [isGamesFetched]);

  const getGames = async () => {
    try {
      const publicGamesRes = await getPublicGames();
      setGames(publicGamesRes.list);
      const userGamesRes = await getUserGames(user.identity.id);

      const userGameRoutes = userGamesRes.list.map((game: any) => game.route);
      const filteredGames = publicGamesRes.list.filter((game: any) => {
        return userGameRoutes.includes(game.route + "/" + game.id);
      });

      setSelectedGames(filteredGames.map((game: any) => game.id));
    } catch (error) {
      console.error("Failed to fetch games:", error);
    }
    setIsGamesFetched(true);
  };

  const toggleGameSelection = (game: string) => {
    setSelectedGames((prevSelectedGames) => {
      if (prevSelectedGames.includes(game)) {
        return prevSelectedGames.filter(
          (selectedGame) => selectedGame !== game
        );
      } else {
        return [...prevSelectedGames, game];
      }
    });
  };

  useEffect(() => {
    setIsButtonDisabled(selectedGames.length === 0);
  }, [selectedGames]);

  const handleSendGames = async () => {
    await putUserGames(user.identity.id, selectedGames).then(() => {
      pushToast(
        new ToastModel(
          "",
          intl.formatMessage({ id: "webapp.settings.success-to-update-games" }),
          NCToastType.SUCCESS
        )
      );
    });
  };

  return (
    <div className="account-settings-content">
      <div className="title">
        <h4>
          <FormattedMessage id={"webapp.settings.my-games"} />
        </h4>
        <h5>
          <FormattedMessage id={"webapp.settings.my-games.games"} />
        </h5>
        <span>
          <FormattedMessage
            id={"webapp.settings.my-games.select-your-prefered-games"}
          />
        </span>
      </div>
      <div className="content">
        <div className="game-selection">
          {games?.map((game) => {
            return (
              <div
                key={game.id}
                className={`game-item ${
                  selectedGames.includes(game.id) ? "active" : ""
                }`}
                onClick={() => toggleGameSelection(game.id)}
              >
                <div
                  className="game-card"
                  style={{
                    backgroundImage: `url(${process.env.REACT_APP_AWS_S3_BUCKET}/media/games/${game.id}/BannerImage)`,
                  }}
                />
                <div className="game-title">{game.title}</div>
              </div>
            );
          })}
        </div>
        <div className="send-button">
          <Button
            setClick={handleSendGames}
            label={intl.formatMessage({
              id: "webapp.settings.my-games.send-button",
            })}
            disabled={isButtonDisabled}
          />
        </div>
      </div>
    </div>
  );
};
