import { SubscriptionPlan } from "../../../models/SubscriptionModel";
import "./PlanItem.scss";

type PlanItemProps = {
  plan: SubscriptionPlan;
  handleOnClick?: (plan: SubscriptionPlan) => void;
  isSubscription?: boolean;
  isMostPopular?: boolean;
  savingMoney?: number;
  isSelected?: boolean;
  isDisabled?: boolean;
};

export const PlanItem = ({
  plan,
  handleOnClick,
  isSubscription,
  isMostPopular = false,
  savingMoney,
  isSelected = false,
  isDisabled,
}: PlanItemProps) => {
  const handleClick = () => {
    if (isDisabled && !plan.oneTimePayment) {
      return;
    }

    if (handleOnClick) {
      handleOnClick(plan);
    }
  };

  return (
    <div
      className={`plan-item-container ${handleOnClick ? "cursor-pointer" : ""} ${isSelected ? "selected" : ""} ${isDisabled && !plan.oneTimePayment ? "disabled" : ""}`}
      onClick={handleClick}
    >
      {isDisabled && !plan.oneTimePayment && (
        <p className="already-subscribed">Already subscribed</p>
      )}
      {isMostPopular && <p>Most popular</p>}
      {isSubscription && <p>Subscription</p>}
      <h6>{plan.name}</h6>
      <p>
        {plan.price}
        {plan.currency}
      </p>
      {savingMoney && <p className="color-premium">Save {savingMoney}€</p>}
    </div>
  );
};
