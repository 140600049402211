import { FormattedMessage } from "react-intl";
import { SubscriptionPlan } from "../../../models/SubscriptionModel";
import { PublicUserModel } from "../../../models/User";
import { PlanItem } from "../../../pages/Premium/PlanItem/PlanItem";

type SelectionPlanProps = {
  handleSelectPlan: (plan: SubscriptionPlan) => void;
  plans: SubscriptionPlan[];
  user: PublicUserModel;
};

export const SelectionPlan = ({
  handleSelectPlan,
  plans,
  user,
}: SelectionPlanProps) => {
  const handleSelectedPlan = (plan: SubscriptionPlan) => {
    handleSelectPlan(plan);
  };

  console.log("----------------", user);

  return (
    <div className="selection-plan-container">
      {plans.length > 0 ? (
        plans.map((plan: SubscriptionPlan) => (
          <PlanItem
            key={plan._id}
            plan={plan}
            handleOnClick={() => handleSelectedPlan(plan)}
            isSubscription={!plan.oneTimePayment}
            isDisabled={user.premium.status === "PREMIUM"}
          />
        ))
      ) : (
        <FormattedMessage id="premium-modal-no-plan-available" />
      )}
    </div>
  );
};
