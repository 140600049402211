import { QueryClient } from 'react-query';

// Create a client
const appQueryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: 0,
        },
    },
});

export default appQueryClient;
