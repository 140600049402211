import React, { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { BroadcastMessage, BroadcastMessageType } from '../Broadcast';
import { Broadcast } from '../../../models/Broadcast';
import { RTService } from '../../../services/rt.service';
import { NotificationType, TournamentNotificationEvent } from '../../../models/notification-event.model';
import { EventMessage, useWebSocket } from '../../../utils/websocket/WebSocketProvider';

import './CheckinBroadcast.scss';

interface CheckinBroadcastProps { }

export const CheckinBroadcast: React.FunctionComponent<CheckinBroadcastProps> = ({ }) => {
  const { subscribe } = useWebSocket();
  const [broadcasts, setBroadcasts] = useState<Broadcast[]>([]);
  const [visible, setVisible] = useState(false);
  const location = useLocation();
  const user = JSON.parse(localStorage.getItem("user") ?? "null");

  const updateVisibility = useCallback(() => {
    /*const isNotTournamentPage = !location.pathname.startsWith("/tournament");
    setVisible(isNotTournamentPage);
    if (isNotTournamentPage) {
      setBroadcasts([]);
    }*/
   setVisible(true)
  }, [location]);

  const removeBroadcast = useCallback((id: string) => {
    setBroadcasts((prev) => prev.filter((broadcast) => broadcast._id !== id));
  }, []);

  const extractTournamentId = (route: string) => {
    const parts = route.split('/');
    const tournamentIndex = parts.indexOf('tournaments') + 1;
    return parts[tournamentIndex] || "notfound";
  }

  const broadcastFromCheckin = useCallback((event: TournamentNotificationEvent): Broadcast => {
    const tournamentId = extractTournamentId(event.content.route || "");
    const htmlMessage = `Your tournament is ongoing! Click <a class="mx-1 tnlink" href="/tournament/${tournamentId}/matchs">here</a> to check in on your match!`;
    return new Broadcast({ _id: event.content.route || "", htmlMessage });
  }, []);

  const fetchInitialBroadcasts = useCallback(async () => {
    if (user) {
      try {
        const lastEvents = await RTService.getEvents(user.identity.id, null);
        const lastCheckinEvent = lastEvents?.list.find(
          (event) => event.type === "tournament" && event.content.reason === "checkin"
        ) as TournamentNotificationEvent;

        if (lastCheckinEvent && moment.duration(moment().diff(lastCheckinEvent.date)).asMinutes() <= 11) {
          const checkedIn = lastEvents?.list.find(
            (event) =>
              event.type === "tournament" &&
              event.content.reason === "checkedin" &&
              event.content.route === lastCheckinEvent.content.route
          );
          if (!checkedIn || moment(checkedIn.date).isBefore(lastCheckinEvent.date)) {
            setBroadcasts((prev) => [...prev, broadcastFromCheckin(lastCheckinEvent)]);
          }
        }
      } catch (error) {
        console.error("Error fetching initial broadcasts:", error);
      }
    }
  }, [user]);

  const convertToTournamentNotificationEvent = (eventMessage: EventMessage): TournamentNotificationEvent | null => {
    if (eventMessage.type !== "tournament") {
      return null;
    }
  
    const content = eventMessage.content;
    const tournamentNotificationEvent: TournamentNotificationEvent = {
      type: NotificationType.Tournament,
      target: eventMessage.target,
      date: new Date(eventMessage.date), 
      content: {
        origin: content.origin,
        reason: content.reason,
        firstplay: content.firstplay,
        rank: content.rank,
        g4gid: content.g4gid,  
        route: content.route,  
      },
    };
  
    return tournamentNotificationEvent;
  };
  

  const handleEvent = useCallback(
    (_room: string, message: EventMessage) => {
      const tournamentNotif = convertToTournamentNotificationEvent(message);
      
      if(!tournamentNotif)
        return;

      if (message.type === "tournament") {
        switch (tournamentNotif.content.reason) {
          case "checkin":
            setBroadcasts((prev) => {
              if (prev.some((b) => b._id === tournamentNotif.content.route)) {
                return prev;
              }
              return [...prev, broadcastFromCheckin(tournamentNotif)];
            });
            break;
          case "checkedin":
            setBroadcasts((prev) => prev.filter((b) => b._id !== tournamentNotif.content.route));
            break;
          default:
            break;
        }
      }
    },
    [broadcastFromCheckin]
  );

  useEffect(() => {
    subscribe("tournament", "*", handleEvent);
    fetchInitialBroadcasts();

    return () => {
      //unsubscribe("tournament", "default-room");
    };
  }, []);

  useEffect(() => {
    updateVisibility();
  }, [location]);

  if (!visible) return null;

  return (
    <div className="checkin-broadcast-container w-100">
      {broadcasts.map((broadcast) => (
        <BroadcastMessage
          key={broadcast._id}
          broadcastMessageType={BroadcastMessageType.TOURNAMENT}
          broadcast={broadcast}
          closeCallback={() => removeBroadcast(broadcast._id)}
        />
      ))}
    </div>
  );
};