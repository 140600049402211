import "./UserInformations.scss";

import {
  Button,
  ButtonType,
  Icon,
  IconType,
  TextArea,
} from "@wolfiesports/srm-component";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { PublicUserModel, UserModel } from "../../models/User";

import { getUserSettings, patchBiography } from "../../services/no-preference";
import { calculateAge } from "../../utils/DateUtil";

interface UserInformationsProps {
  user: PublicUserModel;
  setCallback?: any;
}

export const UserInformations: React.FunctionComponent<
  UserInformationsProps
> = ({ user, setCallback }) => {
  const [edit, setEdit] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<UserModel | null>();
  const [settings, setSettings] = useState<any>();
  const [biography, setBiography] = useState<string>("");
  const [memberSince, setMemberSince] = useState<string>("");

  const intl = useIntl();

  useEffect(() => {
    getCurrentUser();
    getSettings();

    let age = calculateAge(user.reg);

    if (age.years > 0) {
      setMemberSince(
        intl.formatMessage({ id: "user-joined" }) +
          " " +
          age.years +
          " " +
          intl.formatMessage({ id: "user-years-ago" })
      );
    } else if (age.months > 0) {
      setMemberSince(
        intl.formatMessage({ id: "user-joined" }) +
          " " +
          age.months +
          " " +
          intl.formatMessage({ id: "user-months-ago" })
      );
    } else {
      setMemberSince(
        intl.formatMessage({ id: "user-joined" }) +
          " " +
          age.days +
          " " +
          intl.formatMessage({ id: "user-days-ago" })
      );
    }

    // eslint-disable-next-line
  }, [user]);

  const editUser = async () => {
    if (biography !== settings?.about?.bio) {
      await updateBiography();
    }
    setBiography("");
    setCallback(true);
    setEdit(false);
  };

  const updateBiography = async () => {
    if (!user) {
      return;
    }
    const res = await patchBiography(user.userId, biography);

    if (!res) {
      setBiography(settings?.about?.bio);
      return;
    }
  };

  const getCurrentUser = async () => {
    const u = JSON.parse(localStorage.getItem("user") ?? "");
    setCurrentUser(u);
  };

  const getSettings = async () => {
    let temp = await getUserSettings(user.userId);
    setSettings(temp);
    setBiography(temp?.about?.bio || "");
  };

  if (!settings) {
    return;
  }

  return (
    <div className="user-informations">
      <div className="userinfos-container">
        <div className="name-container">
          <span className="username-text">{user.name}</span>
          <span className="username-code">#{user.code}</span>
        </div>
        <div className="usersince-container">
          <div className="user-membersince">{memberSince}</div>
        </div>
      </div>

      {currentUser && currentUser.identity.id === user.userId && (
        <>
          <div
            className="user-informations-edit"
            onClick={() => setEdit(!edit)}
          >
            {!edit && (
              <div title={intl.formatMessage({ id: "tooltip.edit" })}>
                <Icon icon={IconType.Edit} width={20} height={20} />
              </div>
            )}
            {edit && (
              <div title={intl.formatMessage({ id: "tooltip.close" })}>
                <Icon icon={IconType.Cross} width={20} height={20} />
              </div>
            )}
          </div>
          <div className="user-biography">
            <div className="biography-title">
              <FormattedMessage
                id="user.informations.desc"
                description="USER - INFORMATIONS - DESC TITLE"
              />
            </div>
            <div className="biography-text">
              {!edit && settings?.about?.bio}
            </div>
          </div>
        </>
      )}

      {edit && (
        <div className="userinfos-edit">
          <TextArea
            placeholder={intl.formatMessage({
              id: "user.textarea.placeholder.description",
            })}
            value={biography}
            setValue={setBiography}
            rows={2}
          />
          <div onClick={() => editUser()}>
            <Button
              label={intl.formatMessage({ id: "team.button.save" })}
              type={ButtonType.SECONDARY}
              disabled={false}
              setClick={undefined}
            />
          </div>
        </div>
      )}
    </div>
  );
};
