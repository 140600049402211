import Cookies from "js-cookie";
export type Cookie = {
  value: string;
  Expires?: string;
  path?: string;
};

export const STORAGE_COOKIE_PREFIX = "@cookie";

/**
 * set a cookie + it's expiration date in another cookie to be able to retrieve it
 * if no expiration date is provided throught options, cookie will persist 'indefinitely'
 * @param name name of the cookie
 * @param value value of the cookie
 * @param options (optionnal) option object to further enriche cookie metadatas;
 */
export const setCookie = (
  name: string,
  value: string,
  options?: Omit<Cookie, "value">
): void => {
  const Expires = options?.Expires;
  const path = options?.path;
  const cookieName = `${STORAGE_COOKIE_PREFIX}/${name}`;

  Cookies.set(cookieName, JSON.stringify({ value, Expires, path }), options);
};

/**
 * return the value of the provided cookie name
 * @param name name of the request cookie
 * @returns current value of the cookie
 */
export const getCookie = async (name: string): Promise<Cookie | null> => {
  const cookieName = `${STORAGE_COOKIE_PREFIX}/${name}`;
  const myCookie = Cookies.get(cookieName);
  if (myCookie) {
    const value = JSON.parse(myCookie) as Cookie;
    if (value?.Expires && new Date(value.Expires).getTime() < Date.now()) {
      deleteCookie(name);
      return null;
    } else return value;
  }
  return null;
};

/**
 * remove a cookie matching provided name
 * @param name name of the cookie to remove
 */
export const deleteCookie = (name: string): void => {
  const cookieName = `${STORAGE_COOKIE_PREFIX}/${name}`;
  Cookies.remove(cookieName);
};
