import { PaymentIntentType } from "../models/SubscriptionModel";
import { axiosAuthenticated } from "./Auth";

export const postPayPalPayment = async (
  planId: string,
  subscriptionId: string
): Promise<any> => {
  try {
    const res = await axiosAuthenticated.post(
      `${process.env.REACT_APP_BASE_API_SUBSCRIPTION_URL}/apply/paypal/plans/${planId}`,
      { subscriptionId }
    );
    return res;
  } catch (e: any) {
    throw new Error(e.message);
  }
};

export const postPayPalIntent = async (type: PaymentIntentType, data: any) => {
  try {
    const res = await axiosAuthenticated.post(
      `${process.env.REACT_APP_BASE_API_SUBSCRIPTION_URL}/paymentintent/paypal/`,
      { type, data }
    );
    return res;
  } catch (e: any) {
    throw new Error(e.message);
  }
};

export const processPaidPaypalIntent = async (id: string) => {
  try {
    const res = await axiosAuthenticated.post(
      `${process.env.REACT_APP_BASE_API_SUBSCRIPTION_URL}/paymentintent/paypal/${id}/succeded`
    );
    return res;
  } catch (e: any) {
    throw new Error(e.message);
  }
};

export const deletePaidPaypalIntent = async (id: string) => {
  try {
    const res = await axiosAuthenticated.delete(
      `${process.env.REACT_APP_BASE_API_SUBSCRIPTION_URL}/paymentintent/paypal/${id}`
    );
    return res;
  } catch (e: any) {
    throw new Error(e.message);
  }
};
