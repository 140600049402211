import { PaymentMethodResult } from "@stripe/stripe-js";

export interface SubscriptionAccount {
  _id?: string;
  userId?: string;
  email?: string;
  status?: SubscriptionStatus | string;
  active?: boolean;
  endDate?: number;
  trial?: boolean;
  stripeId?: string | null;
  coin?: number;
  paymentMethod?: string;
  isPremium?: boolean;
}

export interface BillingDetails {
  name: string;
  city: string;
  country: string;
  line1: string;
  line2?: string;
  postal_code: string;
}

export enum SubscriptionStatus {
  TRIAL = "TRIAL",
  PREMIUM = "PREMIUM",
  NONE = "NONE",
}

export enum PaymentIntentType {
  TOURNAMENT_TICKET = "tournament-ticket",
  TOURNAMENT_MARKETPLACE_OFFER = "tournament-marketplace-offer",
  PREMIUM = "premium",
}

export interface StripePaymentIntentResponse {
  clientSecret: string;
  paymentIntentId: string;
  paymentMethods: PaymentMethodResult;
}

export interface StripeProcessPaidResponse {
  receipt: string;
}

export const PAYPAL_SUPPORTED_CURRENCIES: Array<string> = [
  "AUD",
  "BRL",
  "CAD",
  "CNY",
  "CZK",
  "DKK",
  "EUR",
  "HKD",
  "HUF",
  "ILS",
  "JPY",
  "MYR",
  "MXN",
  "TWD",
  "NZD",
  "NOK",
  "PHP",
  "PLN",
  "GBP",
  "RUB",
  "SGD",
  "SEK",
  "CHF",
  "THB",
  "USD",
];

export enum PaymentState {
  PAID = "PAID",
  FAILURE = "PAYMENTFAILURE",
}

export interface PaymentMethod {
  value: PaymentMethodEnum;
  viewValue: string;
  style?: { [key: string]: string };
}

export enum PaymentMethodEnum {
  CREDIT_CARD = "creditCard",
  PAY_PAL = "payPal",
  MPESA = "mpesa",
  TPAY = "tpay",
}

export interface SubscriptionPlan {
  _id: string;
  product: string;
  name: string;
  ids: {
    stripe: string;
    paypal: string;
  };
  currency: string;
  price: string;
  interval: string;
  duration: string;
  active: boolean;
  oneTimePayment: boolean;
  countries: string[];
}

export interface PremiumProduct {
  ids: [paypal: string, stripe: string];
  name: string;
  _id: string;
}
