import { ChallengeName, CognitoUser } from "amazon-cognito-identity-js";

export class MissingTokenError extends Error {
  tokenName: string;
  constructor(tokenName: string) {
    super(`token ${tokenName} non trouvé`);
    this.tokenName = tokenName;
  }
}

export class CognitoNewPasswordRequired extends Error {
  userAttributes: any;
  requiredAttributes: any;
  cognitoUser: CognitoUser;
  constructor(
    cognitoUser: CognitoUser,
    userAttributes: any,
    requiredAttributes: any
  ) {
    super("A new password is required for this user");
    this.cognitoUser = cognitoUser;
    this.userAttributes = userAttributes;
    this.requiredAttributes = requiredAttributes;
  }
}

export class CognitoPasswordResetRequired extends Error {
  cognitoUser: CognitoUser;
  constructor(cognitoUser: CognitoUser) {
    super("Password must be reset");
    this.cognitoUser = cognitoUser;
  }
}

export class CognitoEmailConfirmationRequired extends Error {
  cognitoUser: CognitoUser;
  constructor(cognitoUser: CognitoUser) {
    super("Email must be confirmed");
    this.cognitoUser = cognitoUser;
  }
}

export class CognitoMfaRequired extends Error {
  challengeName: ChallengeName;
  challengeParameters: any;
  cognitoUser: CognitoUser;
  constructor(
    cognitoUser: CognitoUser,
    challengeName: ChallengeName,
    challengeParameters: any
  ) {
    super("MFA authentification is required");
    this.cognitoUser = cognitoUser;
    this.challengeName = challengeName;
    this.challengeParameters = challengeParameters;
  }
}

export class UnretrievableCognitoUserSession extends Error {
  constructor() {
    super("Unable to retrieve session from local credentials");
  }
}
