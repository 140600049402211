import { axiosAuthenticated } from "./Auth";

export const getHistoryTournaments = async () => {
  try {
    const response = await axiosAuthenticated.get(
      `${process.env.REACT_APP_BASE_API_NT_URL}/history/tournaments`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching tournaments data:", error);
  }
};

export const getUserHistoryTournaments = async (userId: string) => {
  try {
    const response = await axiosAuthenticated.get(
      `${process.env.REACT_APP_BASE_API_NT_URL}/history/tournaments/users/${userId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching tournaments data:", error);
  }
};

export const getHistoryMatchs = async () => {
  try {
    const response = await axiosAuthenticated.get(
      `${process.env.REACT_APP_BASE_API_NT_URL}/history/matchs`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching match history data:", error);
  }
};


export const getHistoryMatchsById = async (matchId: string) => {
  try {
    const response = await axiosAuthenticated.get(
      `${process.env.REACT_APP_BASE_API_NT_URL}/history/matchs/${matchId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching match history data:", error);
  }
};

export const getTournamentById = async (tournamentId: string) => {
  try {
    const response = await axiosAuthenticated.get(
      `${process.env.REACT_APP_BASE_API_NT_URL}/public/tournaments/${tournamentId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching tournament:", error);
  }
};
