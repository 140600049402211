import {
  Button,
  ButtonTheme,
  ButtonType,
  NCInput,
  NCLoader,
  NCToastType,
  ToastModel,
} from "@wolfiesports/srm-component";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { getUserInfos, populateStorage } from "../../../services/Auth";
import { useStoreActions } from "../../../store";
import {
  useConfirmRegistration,
  useLogin,
  useResendConfirmationCode,
  useSignup,
} from "../../../utils/session/session";
import "./Register.scss";

export const Register = () => {
  const intl = useIntl();
  const signupCognito = useSignup();
  const loginCognito = useLogin();
  const confirmRegistration = useConfirmRegistration();
  const pushToast = useStoreActions((a) => a.toast.pushToast);

  const [email, setEmail] = useState("");
  const [nickname, setNickname] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [error, setError] = useState("");
  const [disabledButton, setDisabledButton] = useState(false);
  const [isOptin, setIsOptin] = useState("");
  const [mandatorySign, setMandatorySign] = useState(false);
  const setUser = useStoreActions((s) => s.login.setUser);

  const [referalCode, setReferalCode] = useState("");

  useEffect(() => {
    const isFormIncomplete =
      !email || !nickname || !password || !passwordConfirmation;
    const isPasswordMismatch = password !== passwordConfirmation;
    const isMandatorySignUnchecked = !mandatorySign;
    const shouldDisableButton =
      isFormIncomplete || isPasswordMismatch || isMandatorySignUnchecked;

    setDisabledButton(shouldDisableButton);
  }, [email, nickname, password, passwordConfirmation, mandatorySign]);

  const setIsLoggedIn = useStoreActions((s) => s.login.setIsLoggedIn);
  const [emailConfirmationField, setEmailConfirmationField] =
    useState<boolean>(false);
  const [confirmationCode, setConfirmationCode] = useState("");
  const resendConfirmationCode = useResendConfirmationCode();

  const [isSignupLoading, setIsSignupLoading] = useState(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);

  const handleSignup = () => {
    const optin = isOptin;
    setIsSignupLoading(true);
    signupCognito.mutate(
      {
        login: email,
        password,
        attributes: {
          email,
          nickname,
          "custom:referalCode": referalCode,
          "custom:optIn": optin,
        },
      },
      {
        onSuccess: () => {
          setEmailConfirmationField(true);
          setIsSignupLoading(false);
        },
        onError: (e) => {
          setError(e.message);
          setIsSignupLoading(false);
        },
      }
    );
  };

  const handleLogin = () => {
    loginCognito.mutate(
      { login: email, password: password },
      {
        onSuccess: async (data) => {
          const accessToken = data
            .getSignInUserSession()
            ?.getAccessToken()
            .getJwtToken();
          const refreshToken = data
            .getSignInUserSession()
            ?.getRefreshToken()
            .getToken();

          if (refreshToken) {
            localStorage.setItem("refreshToken", refreshToken);
          }
          if (accessToken) {
            localStorage.setItem("accessToken", accessToken);
            const user = await getUserInfos();
            if (user) {
              setIsLoggedIn(true);
              setUser(await populateStorage(user));
              window.location.href = "/onboarding";
            }
          }
          setIsConfirmLoading(false);
        },
        onError: (e) => {
          if (e.message === "Email must be confirmed") {
            setEmailConfirmationField(true);
            setIsConfirmLoading(false);
          }
          setError(e.message);
        },
      }
    );
  };

  const handleLoginOnConfirmation = () => {
    setError("");
    setIsConfirmLoading(true);
    confirmRegistration.mutate(
      { username: email, code: confirmationCode },
      {
        onSuccess: async () => {
          handleLogin();
        },
        onError: (e) => {
          console.log(e.message);
        },
      }
    );
  };

  const handleEmailChange = (value: string) => {
    setEmail(value);
  };

  const handleNicknameChange = (value: string) => {
    setNickname(value);
  };

  const handleReferalChange = (value: string) => {
    setReferalCode(value);
  };

  const handlePasswordChange = (value: string) => {
    setPassword(value);
  };

  const handleCheckboxChange = () => {
    setIsOptin(isOptin === "0" ? "1" : "0");
  };

  const handleCheckMandatorySign = () => {
    setMandatorySign(!mandatorySign);
  };

  const handlePasswordConfirmationChange = (value: string) => {
    setPasswordConfirmation(value);

    setTimeout(() => {
      if (password !== value) {
        setError(
          intl.formatMessage({
            id: "webapp.auth.register.password-are-different-err-message",
          })
        );
      } else {
        setError("");
      }
    }, 500);
  };

  const handleConfirmationCodeChange = (value: string) => {
    setConfirmationCode(value);
  };

  const handleResendCode = () => {
    resendConfirmationCode.mutate(
      { username: email },
      {
        onSuccess: () => {
          pushToast(
            new ToastModel(
              "",
              intl.formatMessage({ id: "webapp.resend-code.sent" }),
              NCToastType.SUCCESS
            )
          );
        },
        onError: (error) => {
          setError(error.message);
        },
      }
    );
  };

  return (
    <div className="register-page-container">
      <div className="register-form-container">
        <h2 className="login-title">
          <FormattedMessage id={"webapp.auth.register"} />
        </h2>
        {!emailConfirmationField && (
          <>
            <NCInput
              autofocus
              value={email}
              type="email"
              label={
                <FormattedMessage id={"webapp.auth.register.email-input"} />
              }
              onChange={handleEmailChange}
            />
            <NCInput
              value={nickname}
              label={
                <FormattedMessage id={"webapp.auth.register.nickname-input"} />
              }
              type="nickname"
              onChange={handleNicknameChange}
            />
            <NCInput
              value={password}
              label={<FormattedMessage id={"webapp.auth.register.password"} />}
              type="password"
              onChange={handlePasswordChange}
            />
            <NCInput
              value={passwordConfirmation}
              label={
                <FormattedMessage
                  id={"webapp.auth.register.confirm-password"}
                />
              }
              type="password"
              onChange={handlePasswordConfirmationChange}
            />
            <NCInput
              value={referalCode}
              label={
                <FormattedMessage id={"webapp.auth.register.referal-code"} />
              }
              onChange={handleReferalChange}
            />
            <div className="opt-in-check">
              <input
                type="checkbox"
                checked={mandatorySign}
                onChange={handleCheckMandatorySign}
              />
              <span>
                <FormattedMessage
                  id={"webapp.auth.register.over-twelve-years-old-cta"}
                />
              </span>
            </div>
            <div className="opt-in-check">
              <input
                type="checkbox"
                checked={isOptin === "1"}
                onChange={handleCheckboxChange}
              />
              <span>
                <FormattedMessage
                  id={"webapp.auth.register.accept-receive-marketing-email-cta"}
                />
              </span>
            </div>
            <span>
              <FormattedMessage
                id={"webapp.auth.register.by-registrig-i-accept"}
              />
              <Link to="/privacy">
                <FormattedMessage
                  id={"webapp.auth.register.cookies-policy-and-privacy"}
                />
              </Link>
              .
            </span>
            <Button
              setClick={handleSignup}
              label={
                <FormattedMessage id={"webapp.auth.register.signup-button"} />
              }
              disabled={disabledButton || isSignupLoading}
            />
            {isSignupLoading && <NCLoader />}
          </>
        )}

        {emailConfirmationField && (
          <>
            <span>
              <FormattedMessage
                id={"webapp.auth.login.confirm-code-check-mail-message"}
              />
            </span>
            <NCInput
              value={confirmationCode}
              label={
                <FormattedMessage id={"webapp.auth.login.confirmation-code"} />
              }
              onChange={handleConfirmationCodeChange}
            />
            <Button
              setClick={handleLoginOnConfirmation}
              disabled={isConfirmLoading}
              label={<FormattedMessage id={"webapp.auth.login.login-button"} />}
            />
            <Button
              theme={ButtonTheme.RED}
              disabled={isConfirmLoading}
              type={ButtonType.SECONDARY}
              setClick={handleResendCode}
              label={
                <FormattedMessage id={"webapp.auth.login.resend-code-button"} />
              }
            />
            {isConfirmLoading && <NCLoader />}
          </>
        )}
        {error && (
          <span style={{ color: "red", fontWeight: "bold" }}>{error}</span>
        )}
      </div>
    </div>
  );
};
