import { ReactSRMWrapper } from "@cactus/react-srm-wrapper";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const GameAccountPage = () => {
  const originUrl =
    process.env.REACT_APP_BASE_BUNDLE_URL + "webapp-gameaccount";
  const navigate = useNavigate();
  const [language, setLanguage] = useState(
    localStorage.getItem("i18nextLng") ?? "en"
  );

  useEffect(() => {
    const handleLanguageChange = () => {
      const newLang = localStorage.getItem("i18nextLng") ?? "en";
      setLanguage(newLang);
    };

    window.addEventListener("languageChange", handleLanguageChange);

    return () => {
      window.removeEventListener("languageChange", handleLanguageChange);
    };
  }, []);

  return (
    <ReactSRMWrapper
      key={language}
      originUrl={originUrl}
      exportPath={"nicecactus.gameaccount"}
      nav={navigate}
      language={language}
      basename="/game-accounts"
    />
  );
};
