import { Icon, IconType } from '@wolfiesports/srm-component';
import React from 'react';
import { Broadcast } from '../../models/Broadcast';
import { useIntl } from 'react-intl';

export enum BroadcastMessageType {
    TOURNAMENT = 'tournament',
}

interface BroadcastMessageProps {
    broadcast: Broadcast;
    broadcastMessageType?: BroadcastMessageType;
    closeCallback: (broadcast: Broadcast) => void;
}

export const BroadcastMessage: React.FunctionComponent<BroadcastMessageProps> = ({
    broadcast,
    broadcastMessageType,
    closeCallback,
}) => {
    const closeBroadcast = () => {
        closeCallback(broadcast);
    };
    const intl = useIntl();

    return (
        <div className="board p-3 broadcast-container d-flex flex-row justify-content-between">
            {broadcastMessageType === BroadcastMessageType.TOURNAMENT && (
                <div className="position-relative d-flex">
                    <Icon icon={IconType.Tournament} />
                </div>
            )}
            {broadcast?.message && (
                <span className="d-flex align-items-center mx-1">
                    {intl.formatMessage({ id: broadcast.message }) }
                </span>
            )}
            {broadcast?.htmlMessage && (
                <span
                    className="d-flex align-items-center mx-1 flex-wrap justify-content-center"
                    dangerouslySetInnerHTML={{ __html: broadcast.htmlMessage }}
                ></span>
            )}
            <div className="d-flex justify-content-end">
                <button
                    className="close-button my-auto"
                    onClick={closeBroadcast}
                ></button>
            </div>
        </div>
    );
};