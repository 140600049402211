import { Button } from "@wolfiesports/srm-component";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { GameModel } from "../../../models/GameModel";
import { PlatformModel } from "../../../models/PlatformModel";
import { UserModel } from "../../../models/User";
import {
  getPublicGames,
  getPublicPlatforms,
  getUserGames,
  putUserGames,
  putUserPlatforms,
} from "../../../services/no-games";

type OnBoardingFavoriteGamesProps = {
  user: UserModel;
  nextStep: () => void;
};
export const OnBoardingFavoriteGames = ({
  user,
  nextStep,
}: OnBoardingFavoriteGamesProps) => {
  const [selectedGames, setSelectedGames] = useState<string[]>([]);
  const [games, setGames] = useState<Array<GameModel>>();
  const [platforms, setPlatforms] = useState<Array<PlatformModel>>();
  const [selectedPlatforms, setSelectedPlatforms] = useState<string[]>([]);
  const [isGamesFetched, setIsGamesFetched] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    if (!isGamesFetched) {
      getGames();
      getPlaforms();
    }
  }, [isGamesFetched]);

  const getGames = async () => {
    try {
      const publicGamesRes = await getPublicGames();
      setGames(publicGamesRes.list);
      const userGamesRes = await getUserGames(user.identity.id);

      const userGameRoutes = userGamesRes.list.map((game: any) => game.route);
      const filteredGames = publicGamesRes.list.filter((game: any) => {
        return userGameRoutes.includes(game.route + "/" + game.id);
      });

      setSelectedGames(filteredGames.map((game: any) => game.id));
    } catch (error) {
      console.error("Failed to fetch games:", error);
    }
    setIsGamesFetched(true);
  };

  const getPlaforms = async () => {
    try {
      const publicPlatformRes = await getPublicPlatforms();
      setPlatforms(publicPlatformRes.list);
    } catch (e) {
      console.error(e);
    }
  };

  const toggleGameSelection = (game: string) => {
    setSelectedGames((prevSelectedGames) => {
      if (prevSelectedGames.includes(game)) {
        return prevSelectedGames.filter(
          (selectedGame) => selectedGame !== game
        );
      } else {
        return [...prevSelectedGames, game];
      }
    });
  };

  const togglePlatformSelection = (platform: string) => {
    setSelectedPlatforms((prevSelectedPlatforms) => {
      if (prevSelectedPlatforms.includes(platform)) {
        return prevSelectedPlatforms.filter(
          (selectedPlatform) => selectedPlatform !== platform
        );
      } else {
        return [...prevSelectedPlatforms, platform];
      }
    });
  };

  useEffect(() => {
    setIsButtonDisabled(selectedGames.length === 0);
  }, [selectedGames]);

  const handleSendGames = async () => {
    await putUserGames(user.identity.id, selectedGames).then(() => {
      handleSendPlatforms();
    });
  };

  const handleSendPlatforms = async () => {
    await putUserPlatforms(user.identity.id, selectedPlatforms).then(() => {
      nextStep();
    });
  };

  return (
    <div className="favorite-games-container">
      <h5>
        <FormattedMessage
          id={"webapp.onboarding.favorite-games.select-your-favorite-games"}
        />
      </h5>
      <div className="selection">
        {games?.map((game) => {
          return (
            <div
              key={game.id}
              className={`item ${
                selectedGames.includes(game.id) ? "active" : ""
              }`}
              onClick={() => toggleGameSelection(game.id)}
            >
              <div className="card">
                <div
                  className="card-background"
                  style={{
                    backgroundImage: `url(${process.env.REACT_APP_AWS_S3_BUCKET}/media/games/${game.id}/BannerImage)`,
                  }}
                ></div>
              </div>
              <div
                className="card-logo"
                style={{
                  backgroundImage: `url(${process.env.REACT_APP_AWS_S3_BUCKET}/media/games/${game.id}/LogoImage)`,
                }}
              />
              <div className="title">{game.title}</div>
            </div>
          );
        })}
      </div>
      <h5>
        <FormattedMessage
          id={"webapp.onboarding.favorite-games.select-your-favorite-platform"}
        />
      </h5>
      <div className="selection">
        {platforms?.map((platform) => {
          return (
            <div
              key={platform.id}
              className={`item ${
                selectedPlatforms.includes(platform.id) ? "active" : ""
              }`}
              onClick={() => togglePlatformSelection(platform.id)}
            >
              <div className="card">
                <div
                  className="card-background"
                  style={{
                    backgroundImage: `url(${process.env.REACT_APP_AWS_S3_BUCKET}/media/platforms/backgrounds/${platform.id}.png)`,
                  }}
                ></div>
              </div>
              <div
                className="card-logo"
                style={{
                  backgroundImage: `url(${process.env.REACT_APP_AWS_S3_BUCKET}/media/platforms/${platform.id}.svg)`,
                }}
              />
              <div className="title">{platform.title}</div>
            </div>
          );
        })}
      </div>
      <div className="send-button">
        <Button
          setClick={handleSendGames}
          label={
            <FormattedMessage
              id={"webapp.onboarding.favorite-games.send-button"}
            />
          }
          disabled={isButtonDisabled}
        />
      </div>
    </div>
  );
};
