import { E164Number } from "libphonenumber-js";
import { axiosAuthenticated } from "./Auth";

export const getUserById = async (userId: string) => {
  try {
    const response = await axiosAuthenticated.get(
      `${process.env.REACT_APP_BASE_API_NU_URL}/users/${userId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching user data:", error);
  }
};

export const patchBirhday = async (userId: string, newBirthdate: string) => {
  try {
    const response = await axiosAuthenticated.patch(
      `${process.env.REACT_APP_BASE_API_NU_URL}/users/${userId}/birthday`,
      { newBirthdate }
    );
    return response.data;
  } catch (error) {
    console.error("Error patching user newBirthdate:", error);
  }
};

export const patchCountry = async (userId: string, newCountryCode: string) => {
  try {
    const response = await axiosAuthenticated.patch(
      `${process.env.REACT_APP_BASE_API_NU_URL}/users/${userId}/country`,
      { newCountryCode }
    );
    return response.data;
  } catch (error) {
    console.error("Error patching user newCountryCode:", error);
  }
};

export const patchEmail = async (userId: string, newEmail: string) => {
  try {
    const response = await axiosAuthenticated.patch(
      `${process.env.REACT_APP_BASE_API_NU_URL}/users/${userId}/email`,
      { newEmail }
    );
    return response.data;
  } catch (error) {
    console.error("Error patching user newEmail:", error);
  }
};

export const patchFirstname = async (userId: string, newFirstname: string) => {
  try {
    const response = await axiosAuthenticated.patch(
      `${process.env.REACT_APP_BASE_API_NU_URL}/users/${userId}/firstname`,
      { newFirstname }
    );
    return response.data;
  } catch (error) {
    console.error("Error patching user newFirstname:", error);
  }
};

export const patchLastname = async (userId: string, newLastname: string) => {
  try {
    const response = await axiosAuthenticated.patch(
      `${process.env.REACT_APP_BASE_API_NU_URL}/users/${userId}/lastname`,
      { newLastname }
    );
    return response.data;
  } catch (error) {
    console.error("Error patching user newLastname:", error);
  }
};

export const patchNickname = async (userId: string, newNickname: string) => {
  try {
    const response = await axiosAuthenticated.patch(
      `${process.env.REACT_APP_BASE_API_NU_URL}/users/${userId}/nickname`,
      { newNickname }
    );
    return response.data;
  } catch (error) {
    console.error("Error patching user newNickname:", error);
  }
};

export const patchPhone = async (userId: string, phoneNumber: E164Number) => {
  try {
    const response = await axiosAuthenticated.patch(
      `${process.env.REACT_APP_BASE_API_NU_URL}/users/${userId}/phone`,
      { phoneNumber }
    );
    return response.data;
  } catch (error) {
    console.error("Error patching user phoneNumber:", error);
  }
};

export const patchAvatar = async (userId: string, avatar: string) => {
  try {
    const response = await axiosAuthenticated.patch(
      `${process.env.REACT_APP_BASE_API_NU_URL}/users/${userId}/avatar`,
      { avatar }
    );
    return response.data;
  } catch (error) {
    console.error("Error patching user avatar:", error);
  }
};

export const patchBanner = async (userId: string, banner: string) => {
  try {
    const response = await axiosAuthenticated.patch(
      `${process.env.REACT_APP_BASE_API_NU_URL}/users/${userId}/banner`,
      { banner }
    );
    return response.data;
  } catch (error) {
    console.error("Error patching user banner:", error);
  }
};

export const patchOptIn = async (userId: string, optIn: boolean) => {
  try {
    const response = await axiosAuthenticated.patch(
      `${process.env.REACT_APP_BASE_API_NU_URL}/users/${userId}/optIn`,
      { optIn }
    );
    return response.data;
  } catch (error) {
    console.error("Error patching user optIn:", error);
  }
};

export const putUserCurrency = async (userId: string, newCurrency: string) => {
  try {
    const response = await axiosAuthenticated.patch(
      `${process.env.REACT_APP_BASE_API_NU_URL}/users/${userId}/currency`,
      { newCurrency }
    );
    return response.data;
  } catch (error) {
    console.error("Error put user currency:", error);
  }
};

export const putUserLanguage = async (userId: string, newLanguage: string) => {
  try {
    const response = await axiosAuthenticated.patch(
      `${process.env.REACT_APP_BASE_API_NU_URL}/users/${userId}/language`,
      { newLanguage }
    );
    return response.data;
  } catch (error) {
    console.error("Error put user language:", error);
  }
};
