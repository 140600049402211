import { axiosAuthenticated } from "./Auth";

export const patchBiography = async (userId: string, biography: string) => {
  try {
    const response = await axiosAuthenticated.patch(
      `${process.env.REACT_APP_BASE_API_NP_URL}/users/${userId}/about`,
      { newAbout: biography, language: "bio" }
    );
    return response.data;
  } catch (error) {
    console.error("Error patching user biography:", error);
  }
};

export const getUserSettings = async (userId: string) => {
  try {
    const response = await axiosAuthenticated.get(
      `${process.env.REACT_APP_BASE_API_NP_URL}/user/${userId}/settings`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching user settings:", error);
  }
};
