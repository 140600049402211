import "./Legals.scss";

export const TermOfUsePage = () => {
   window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  return (
    <div className="terms-of-use">
      <h1>TERMS OF USE</h1> <br />
      <br />
      These General Terms of Use (hereinafter the “ Terms ”) apply to any use or
      consultation of the 
      <a href="https://www.wolfiesports.com"> https://www.wolfiesports.com </a>
      website (hereinafter the “ Site ”), edited by GAMMANIA WOLF LIMITED a
      limited liability company registered under Registration number PVT-V7UAM25Z
      and having its registered address at Fortis Tower, Westlands, Nairobi, Kenya
      (hereinafter the “ Company ”). The Site is hosted by AMAZON WEB
      SERVICES EMEA, a limited liability company registered with the “Registre
      du Commerce et des Sociétés” of Luxembourg under the number 831 001 334,
      headquarters at 38 Avenue John F. Kennedy L 1855 99137 Luxembourg
      (hereinafter the “ Host ”).
      <br />
      <br /> <br />
      <b>ARTICLE 1 : Purpose</b>
      <br />
      <br />
      The Company is an international online platform 
      <a href="https://wolfiesports.com"> https://wolfiesports.com</a> dedicated to
      esport players, teams and organizations, and aims to entertain players through gaming and esports activities. 
      Players can assess team management tools and participate in tournaments. <br />
      <br />
      These Terms of Use define the conditions under which the user (“ You ”, “ Your ”)
      may use and consult the Site.
      <br />
      <br />
      Consequently, You must accept the Terms of Use by checking the box “I accept the
      Terms of Use” when You register.
      <br />
      <br />
      The Company reserves the right to modify the Terms of Use at any time by publishing
      a new version on the Site. The Terms of Use thus modified will be applicable as
      soon as they are put on the Site.
      <br />
      <br /> <br /> <br />
      <b>ARTICLE 2 : Registration on the Site</b> <br />
      <br />
      When You register on the Site, You must enter Your email, Your username
      and Your password. <br />
      <br />
      You are the sole holder of Your account and acknowledge that You are
      responsible for any problem that may result from it and more generally for
      any action taken through Your account. The creation of Your account is
      personal and must not harm a third party.
      <br />
      <br />
      Furthermore, You agree to provide only accurate information. This
      information must not have sexual or racist connotations. You hereby agree
      to regularly update Your information. <br />
      <br />
      In case of non-compliance with these Terms of Use, the Company reserves the right
      to suspend or delete Your user account. You will no longer be able to
      access Your information, Your account, and all rights that You have
      acquired on the Site will be deleted. <br />
      <br />
      The Company reserves the right to modify these Terms at any time, by
      publishing a new version on the Website. The applicable Terms are those in
      effect on the date the order is placed.
      <br />
      <br /> <br />
      <br /> <br />
      <b>ARTICLE 3 : Wolfie Sports Mobile Application </b> <br />
      <br />
      The Wolfie Sport Mobile Application allows You to access the
      functionalities of the Site and much more directly from Your mobile :
      <br />
      <br />• Find tournaments via a calendar <br />• Simply register/register
      <br />
      • Create and manage Your team <br />• Take a photo of their results when
      participating in tournaments and upload them as proof <br />
      • Call an arbiter or send a message to the customer support <br />
      • Receive notifications of matches in progress and upcoming
      <br />
      <br />
      For more information on the treatment of Your data, please find our
      Privacy Policy here : 
      <a href="https://wolfiesports.com/privacy">
          https://wolfiesports.com/privacy
      </a>
      . <br />
      <br /> <br />
      <b>ARTICLE 4 : Participation in tournaments</b> <br />
      <br />
      4.1. Participation of minors in tournaments
      <br />
      <br /> <i>4.1.1. Participation of minors under 12 years old</i> <br />
      <br />
      In accordance with Article R321-44 of the « Code de la sécurité intérieure
      », the participation of child under 12 years old in esport tournaments
      offering monetary rewards is prohibited. <br />
      <br />
      For tournaments that do not offer monetary reward, parental authorization
      is required. This authorization must be accompanied by a copy of the
      passport or Identity card of the legal representative and the child’s
      passport or Identity card. <br /> <br />
      <i> 4.1.2. Participation of minors over 12 years old </i>
      <br />
      <br />
      The participation of minors over 12 years old in esport tournaments
      offering monetary reward is subject to parental authorization in
      accordance with the Article R321-43 of the “Code de la sécurité
      intérieure”. This parental authorization must be accompanied by valid
      documents proving the identity of the legal representative and that of the
      minor.
      <br />
      <br />
      4.2. Prizes
      <br />
      <br />
      <i> 4.2.1. Monetary rewards</i>
      <br />
      <br />
      The Company reserves the right to modify the amount or nature of the
      reward at any time and without prior information.
      <br />
      <br />
      If You are the winner of Tournament or are one of the winners according
      the terms and conditions of the Tournament, You must recover Your award(s).
      <br />
      <br />
      In addition, for the conditions of recovering the award, You will receive
      an email on the address email that You have given for Your registration,
      in which we ask You the documents to be provided to recover Your reward :
      bank details, valid proof of identity, etc. <br />
      You must have an Mpesa Mobile Money account attached to the mobile number
      used for registration. This is will the primary method used to pay out
      winnings. <br />
      <br />
      Once You are sending the documents requested, You will receive Your reward
      within 90 working days from Your claim. <br />
      <br />
      Be careful, if You don’t claim our reward during the 90 days following the
      end of the tournament, You will not be able to recover it. After this
      delay, the reward is cancelled. <br />
      <br /> <i> 4.2.2. Material rewards</i> <br />
      <br />
      The Company reserves the right to modify the nature of the reward at any
      time and without prior information.
      <br />
      <br />
      If You are the winner of the tournament, or one of the winners, You must
      recover Your reward. <br />
      <br />
      Concerning the conditions to recover Your reward, You will receive an
      email on the address email that You have given for Your registration, in
      which we ask You the documents to be provided to recover Your reward :
      valid proof of identity, delivery address, telephone number, Your size if
      the Reward is a cloth, etc.
      <br />
      Once Your have sent the documents requested, You receive the reward within
      90 working days. <br />
      <br />
      Be careful, if You don’t claim our reward during the 90 days following the
      end of the tournament, You will not be able to recover it. After this
      delay, the reward is cancelled.
      <br />
      <br />
      4.3. Penalties
      <br />
      <br />
      <i>4.3.1. Cheat</i> <br />
      <br />
      Any participation in the tournament is subject to the respect of the rules
      and Your loyalty in the game. It is strictly prohibited to modify or
      attempt to modify the features of the proposed game, in particular to
      modify the results. <br />
      <br />
      The use of any third programs is also prohibited (cheating, scripting,
      etc). You may also not use spam, it’s prohibited. If You don’t respect
      these rules, the Company reserves the right to exclude You from the
      tournament, and from the Site. In addition, You will be “blacklisted” by
      the Company and You no longer be able to participate in its tournaments
      and benefits from other advantages of the Site.
      <br />
      <br /> <i>4.3.2. Language abuse and inappropriate behavior </i> <br />
      <br />
      The participation of tournaments is conditioned by Your commitment to be
      have a respectfully behavior with the Company, the administrators of
      tournaments, the players, the partners, etc. You agree to not attempt of
      the rights of any third party by behaving or saying anything that is rude,
      abusive or contrary to the principles of the Company. <br />
      <br />
      The no respect of these rules will be immediately sanctioned by the
      Company, she reserves the right to exclude You from all its tournaments
      and the Site. <br />
      <br /> <br />
      <b>ARTICLE 5 : Acceptance of risk</b> <br />
      <br />
      You understand and agree that there are risks associated with esport,
      including, but not limited to, eye damage, “carpal tunnel syndrome”,
      repetitive stress and other damages associated with long periods of
      computer and console training. You hereby expressly assume all such risks.
      <br />
      <br /> <br />
      <b>ARTICLE 6 : PEGI</b> <br />
      <br />
      The Pan European Game Information (PEGI) is an European rating system for
      video games, which classifies video games according to age and aims to
      help consumer, especially the parents, to inform themselves about each
      types of video games using different logo, which You can find at
      <a href="https://pegi.info/fr."> https://pegi.info/fr.</a> <br />
      <br />
      The PEGI of the games is also specified on the parental authorization
      intended for parents and/or legal representatives of minors over 12 years
      old who want to participate in esport tournament on the Site. <br />
      <br /> <br />
      <b>ARTICLE 7 : Intellectual property</b> <br />
      <br />
      The use of the Site doesn’t give You any right on the elements on the Site
      and in particular the texts, photos, videos, data, posters, logos,
      trademarks and other elements reproduced on the Site. These elements are
      reserved and protected by the intellectual property law and remain the
      exclusive property of the Company, and, where applicable, of third parties
      who have authorized it to reproduce them on the Site. <br />
      <br />
      As such and in accordance with the Intellectual Property Code, only use
      for private use within a family circle is authorized. Any other use
      constitutes counterfeiting and infringement of neighboring rights,
      punishable by the Intellectual Property Code. <br />
      <br />
      Consequently, You may not under circumstances, represent, distribute,
      market, modify or concede all or part of any of the elements reproduced on
      the Site and all or part of the Site in general, without the prior and
      express consent of the Company. <br />
      <br />
      Any illegal use of all or part of the Site (piracy, counterfeiting, etc)
      may give rise to legal proceedings. <br />
      <br /> <br />
      <b>ARTICLE 8 – PERSONAL DATA</b> <br />
      <br />
      You and the Company acknowledge that you are fully aware to the
      obligations of regulation relating to the personal data, i.e., any
      European or national legislative or regulatory provisions, resulting in
      particular from Regulation (UEÀ 2016/679 of April 27 th , 2016, on the
      protection of individuals regarding the process of personal data, as well
      as any other applicable regulations, which may be added to or replace them
      at a later date.
      <br />
      <br />
      Consequently, the Company undertakes only Your personal data that is
      strictly necessary for the functioning of their services. <br />
      <br />
      The Company informs You that it will collect the following personal data
      concerning You :<br />
      <br />• First name and surname <br />• Email address <br />• Address
      <br />• Username <br />• Game history <br />• Conversation history on the
      Site's Forum <br />• Country of origin for statistical purposes
      <br />
      <br />
      You are hereby informed that this data will be kept by the Company for the
      time necessary for processing and until Your account is deleted. <br />
      <br />
      In addition, You have the right to access, rectify and delete personal
      data concerning You. You can contact the customer service via the forms
      available on the website
      <a href="https://www.wolfiesports.com"> https://www.wolfiesports.com</a> <br />
      <br />
      If you are not connected to your user account you can access the contact
      form at the bottom of the wolfiesport.com home page entitled "Contact Us".
      You must fill in the following fields: name, email, select an option from
      the drop-down menu (sponsorship, support, etc.) and complete your request
      by explaining it in the box provided. Then, you must click on "send".
      <br />
      <br />
      If you are logged into your Wolfie Sport user account, you can access the
      contact form in the menu on the left side of your home screen "Contact
      Us". You must complete the following fields: name (optional), email,
      explain your request in the box provided. You have the possibility to
      attach up to 5 files. Finally, you must click on "send”.
      <br />
      <br />
      Also, specific mentions appear on each form to ensure that You are fully
      informed before any process if data concerning You. <br />
      <br />
      For more information about the process of Your data, please find the
      following link :
      <a href="https://wolfiesports.com/privacy">
         https://wolfiesports.com/privacy
      </a>
      .<br />
      <br /> <br />
      <b>ARTICLE 9 : Cookies</b> <br />
      <br />
      During Your registration or Your log on the Site, a tab appears to recover
      Your acceptation or refuse cookies during Your navigation. <br />
      <br />
      If You accept, the cookies integrated in the pages and content that You
      have consulted will be stored temporarily in a dedicated area of Your
      computer and will be readable only by their transmitter. <br />
      <br />
      On the other hand, if You refuse the registration of cookies or delete
      those register, You will no longer be able to benefit from the
      functionalities necessary to navigate on certain pages on the Site. Where
      applicable, the Company declines all responsibility for the consequences
      related to the degraded functioning of the Site. <br />
      <br />
      The configuration of cookies varies according to browsers, it is described
      in the help menu of Your browser. This will allow You to know how to
      modify Your wishes concerning cookies. <br />
      <br /> <br />
      <b>ARTICLE 10 : Hypertext links</b> <br />
      <br />
      The Site is likely to contain hypertext links giving access to other
      websites managed by third parties.
      <br />
      <br />
      The Company may not be held responsible, directly or indirectly, for any
      legal or regulatory violation whether Kenyan, French or European, of a
      website managed by a third party and for the consequences resulting from
      such violation. Consequently, the Company doesn’t control and doesn’t
      contribute in any way to the development and editing of the content
      available on these third-party sites.
      <br />
      <br /> <br />
      <b> ARTICLE 11 : Warranty</b>
      <br />
      <br />
      Subject to the legal and regulatory provisions in force, You expressly
      acknowledge and accept that the Site is provided to You in tis current
      state, that it is accessible according to its availability and doesn’t
      present any express or implicit guarantee on the part of the Company. Its
      use is at Your own risk and under Your full responsibility. <br />
      <br />
      The Company doesn’t guarantee that the Site, the contents and products
      offered, will meet Your expectations. The Company also doesn’t guarantee
      that the Site will not be interrupted and free of all errors, that these
      interruptions or errors will be corrected and that it will not contain any
      virus.
      <br />
      <br />
      Any material downloaded or obtained in any manner whatsoever during the
      use of the Site will be downloaded at Your own risk. Consequently, the
      Company shall not be liable for any damage or loss of data suffered by
      Your computer or any computer equipment You use. <br />
      <br />
      Also, the Company reserves the right to modify, suspend, delete or
      deactivate access to all or part of the Site at any time and without
      notice and shall not be held liable for any such modification, suspension,
      deletion or deactivation. <br />
      <br />
      You warrant to the Company that Your use of the Site is legal and
      authorized in all states where You may be required to connect. <br />
      In addition, You are informed that the Site and the content it offers
      cannot be translated into all the languages existing to date and isn’t
      available in all countries of the world. The Company isn’t responsible for
      the inaccuracy of the information displayed on the Site. <br />
      Finally, no advice or information, whether written or oral, that You may
      have obtained from the Company or during the use of the Site, may be
      likely to characterize any warranty not expressly provide for in the Terms of Use.
      <br />
      IN EVERY EXTEND PERMITTED BY LAW, YOU RELEASE THE COMPANY FROM ANY AND ALL
      CLAIMS OR DAMAGES OF ANY KIND, KNOWN OR UNKNOWN, DISCLOSED OR UNDISCLOSED,
      ARISING OUT OF YOUR PARTICIPATION IN ANY EVENT, TOURNAMENT, USE OF GUIDES
      OR TRAINING TO IMPROVE YOUR PERFORMANCE. YOU AGREE THAT THIS LIABILITY
      SHALL THIS BINDING UPON YOU AND YOUR INHERITORS, EXECUTORS, CONTRACTORS
      AND ASSIGNS. <br />
      <br /> <br />
      <b>Article 12 : Liability and force majeure</b> <br />
      <br />
      Subject to applicable laws and regulations, the Company shall not be
      liable for any damage or loss of any kind (including, but not limited to,
      direct, indirect, economic loss or damage) that is directly or indirectly
      related to : <br />
      <br />• The use or inability to use the Site, including but not limited to
      loss of customers, work interruption, breakdown or malfunction of computer
      equipment ; <br />• To the loss or deterioration of information related to
      the user account, statistics, information, lack of stock ; <br />• The
      interruption of services, including but not limited to operating system
      problems, hardware failure, billing system failure, or any other event
      that may result in the loss or interruption of services on the Site ;
      <br />• To the content of the Site ; <br />• Your use of the Site ; <br />
      • To all measures taken by competent persons, internal or external to the
      Company, in consideration of Your use of the information or the sue made
      by another person ; <br />• Any measures relating to copyright and
      intellectual property rights ; <br />• Any error or omission in the
      operation of the services of the Site ; <br />• Any damage caused to the
      computer hardware or other equipment, including but not limited, to any
      damage resulting from violation of a security standard, viruses, bugs,
      fraud, error, omission, interruption, network failure, delay in operation,
      even if it could be within the framework of contractual relations or if it
      results from contractual responsibility (including but not limited to
      damage cause by force majeure, theft or destruction of property). <br />
      <br />
      Finally, the Company shall not be liable when the amount of compensation
      for the damage suffered is greater than the amount paid by You during the
      12 months preceding the damage.
      <br />
      <br /> <br />
      <b>ARTICLE 13 : Closure or suspension of the Site</b> <br />
      <br />
      The Company reserves the right, when it wishes, to modify, interrupt
      temporarily or permanently, all or part of the Site, without notice or
      compensation of any kind. <br />
      <br />
      Also, the Company may not be liable to You or to any third party for any
      modification, interruption or suspension of the Site. The version of Terms of Use
      that You must respect, and which will be opposed to You in the event of a
      dispute is the one in force on the Site. <br />
      <br /> <br />
      <b> ARTICLE 14 : Rules of conduct</b>
      <br />
      <br />
      By registering on the Site You agree to respect the following rules :
      <br />
      <br />• You must not participate in any activity who violates the laws and
      regulations in force ; <br />• You must not use the services to which You
      have access to develop, host or distribute cheating elements, robotic
      software, hacks, or any other unauthorized software ; <br />• You may not
      copy, alter or modify the purpose of the source code and content available
      on the Site, including without limitation for commercial use, including
      without limitation : use in cybercafé, data center, or other
      location-based site, without the express consent of the Company (a); the
      collection of money, content or resources from the Site for sale to third
      parties (b); sale of accounts, login information or other confidential
      information (c) ; <br />• You may not disable, hack into or attempt to
      interfere in any way with the security of the Site or anything else that
      would restrict the use of copying of any content of the Site or of
      material owned by the Company and for which the Company hasn’t given its
      consent ; <br />• You agree not to use any third-party software not
      authorized by the Company that intercepts or collects information from or
      through the Site ; <br />• You shall not disrupt the servers or networks
      of the Site, including, but not limited to, by transmitting viruses,
      spyware, malware or any other code of a destructive nature. You are not
      authorized to introduce any content or code, or interfere in any way with
      the Site ; <br />• You mustn’t, unless expressly authorized in writing by
      the Company, host, provide or develop content that interferes with the
      Site, in particular to play on the internet or on a network ; <br />• You
      mustn’t destroy, interfere with or disrupt (including scanning, probing or
      testing for vulnerabilities) any web pages available on the Site, servers
      or networks connected to the Site ; <br />• You may not solicit, collect
      or use the login information or personal data of other users ; <br />• You
      shall not defame, stalk, attempt to intimidate, threaten or impersonate
      any third party and You shall not publicly disclose on the Site any
      personal information about You or any other information about another user
      (bank details, social security number, private telephone number, private
      email, etc) ; <br />• You mustn’t engage in degrading behavior to another
      person or group because of their age, religion, sex, sexual orientation,
      race, ethnic origin or disability ;<br />
      • You mustn’t use the content of the Site for any purpose that is unlawful
      or not provided for by law or the Company ;<br />
      • You may not create or submit any letter, email, comments or any other
      form of commercial communications not consented to by other users ;<br />
      • You may not create accounts in any inappropriate manner, including but
      not limited to, by using an automated device, script, bot, etc ;
      <br />• You may not create accounts in any other manner that isn’t
      authorized by the Company ; <br />• You shall not prevent any user from
      accessing the Site and You shall not encourage the violation of the Terms of Use or
      any other terms and conditions of the Company ; <br />• You agree not to
      infringe any patent, trademark, trade secret, copyright, right of
      publicity or any other right of any third party or other entity ; <br />•
      You agree not promote, in any way whatsoever, a brand competitor to the
      Company.
      <br />
      <br /> <br />
      <b>ARTICLE 15 : Moderation of opinions and comments</b> <br />
      <br />
      The Company makes available to You, on the Site, a chat allowing You to
      communicate with the other users (hereinafter the “Chat”). If You wish to
      post messages on the Chat, You must do so in accordance with the Terms of Use.
      Therefore, You agree to :<br />
      <br />• Use the Chat in accordance with the laws and regulations in force,
      and in no way that is illegal ;
      <br />
      <br />• Not to publish or create a hypertext link that : <br />
      o Contains viruses, files or code designed to interrupt, destroy or limit
      the Site, collect or store personal information about other users
      <br />
      o Damages the identity of a third party, another company, etc o Is
      defamatory, offensive, provocative, hateful, threatening or likely to
      cause nuisance and disturbance
      <br />
      o Infringe or violate the rights of any person, company, corporation,
      including but not limited to, the right to privacy, intellectual property,
      contractual and confidentiality rights
      <br />
      o Constitutes discrimination on the basis of race, sex, religion,
      nationality, disability, sexual orientation or age
      <br />o Contains advertising or any form of undesired promotion such as
      spam <br />
      <br />• Don’t use the Chat to interrupt or prevent the good functioning of
      the Site, alter his its content or make it ineffective ; <br />• Don’t
      attempt to access the Site or its contents in unauthorized manner. <br />
      <br />
      The Chat isn’t the responsibility of the Company, because the Company just
      make a means of communication at Your disposition. Therefore, the messages
      published on the Chat don’t reflect the opinion of the Company and the
      sole responsibility of their author. In addition, the Site benefits from a
      moderation system for messages published on the Chat. So, the Company
      reserves the right to ban any user who doesn’t not comply with these
      rules/. You are responsible for the messages that You publish on the Site,
      You agree to indemnify the Company for any claims (ex: legal fees) or
      damages resulting from the publication of Your messages. <br />
      <br />
      Finally, the Company cannot guarantee the veracity and accuracy of the
      information given on the Chat, which isn’t its responsibility. In the
      event that You wish to exercise a right, make a claim or take action
      against a third party, You will do so independently and without against
      the Company.
      <br />
      <br /> <br />
      <b>ARTICLE 16 : Duration</b> <br />
      <br />
      These Terms of Use are intended for an indefinite period of time. You must respect
      the Terms of Use as soon as You accept them by ticking the box provided for this
      purpose, and during the time You use and consult the Site. <br />
      <br /> <br />
      <b>ARTICLE 17 : Customer service</b> <br />
      <br />
      The Company’s customer service department is available from 9:00 AM to
      5:00 PM (EAT) for all Your questions and requests for information. You can
      contact them via the forms available on the website
      <a href="https://www.wolfiesports.com">
         https://www.wolfiesports.com
      </a> : <br />
      <br />
      If you are not connected to your user account you can access the contact
      form at the bottom of the wolfiesports.com home page entitled "Contact Us".
      You must fill in the following fields: name, email, select an option from
      the drop-down menu (sponsorship, support, etc.) and complete your request
      by explaining it in the box provided. Then, you must click on "send".
      <br />
      <br />
      If you are logged into your Wolfie Sports user account, you can access the
      contact form in the menu on the left side of your home screen "Contact
      Us". You must complete the following fields: name (optional), email,
      explain your request in the box provided. You have the possibility to
      attach up to 5 files. Finally, you must click on "send”.
      <br />
      <br />
      You can also visit the Company’s social networks listed below :<br />
      <br />• Facebook :
      <a href="https://www.facebook.com/wolfiesport/">
         https://www.facebook.com/wolfiesport/
      </a>
      <br />• Twitch :
      <a href="https://twitch.tv/wolfiesport/ ">
         https://twitch.tv/wolfiesport/
      </a>
      <br />• Twitter :
      <a href="https://twitter.com/wolfiesport">
         https://twitter.com/wolfiesport
      </a>
      <br />
      <br /> <br />
      <b>ARTICLE 20 : State legislation</b>
      <br />
      <br />
      You are subject to the laws and regulations in force in the countries from
      which You connect to the Site and where You use the content of the Site.
      You are solely responsible for complying these laws and regulations in
      force in these territories. <br />
      <br />
      Consequently, You agree that the Company cannot be liable for the laws to
      which You are subject in these territories, which restrict or prohibit You
      registration on the Site or participation in the tournaments. <br />
      <br />
      Also, the Company doesn’t guarantee that the content of the Site, the
      services offered, or the information displayed are appropriate or
      available for use outside the French territory. It’s strictly forbidden to
      access the Site from territories where its use is illegal. Finally, if You
      access the Site from outside France, You do so at Your own risk. You are
      solely responsible for compliance with local laws. <br />
      <br />
      The Company reserves the right to monitor the place from which You connect
      to the Site to block this access whenever it’s illegal or limited.
      <br />
      <br /> <br />
      <b>ARTICLE 19 : Applicable law and settlement of disputes</b> <br />
      <br />
      The Terms of Use are governed and interpreted in accordance with the French Law.
      <br />
      <br />
      Therefore, any dispute related to or resulting from the validity,
      performance, interpretation or formation of these Terms of Use shall be submitted
      to the competent French courts. <br />
      <br />
    </div>
  );
};
