import {
  Button,
  DatePicker,
  NCInput,
  NCSelect,
  NCToastType,
  ToastModel,
} from "@wolfiesports/srm-component";
import parsePhoneNumberFromString, {
  E164Number,
  isValidPhoneNumber,
} from "libphonenumber-js";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { countriesOptions } from "../../../models/Countries";
import {
  patchBirhday,
  patchCountry,
  patchEmail,
  patchFirstname,
  patchLastname,
  patchNickname,
  patchOptIn,
  patchPhone,
} from "../../../services/no-user";
import { useStoreActions } from "../../../store";
import "../UserSettings.scss";

type AccountSettingsContentProps = {
  handleGetUser: () => void;
};

export const AccountSettingsContent = ({
  handleGetUser,
}: AccountSettingsContentProps) => {
  const user = JSON.parse(localStorage.getItem("user") ?? "");

  const [email, setEmail] = useState(user.identity.email);
  const [nickname, setNickname] = useState(user.identity.nickname);
  const [firstname, setFirstname] = useState(user.identity.firstName);
  const [lastname, setLastname] = useState(user.identity.lastName);
  const [birthDate, setBirthDate] = useState(user.identity.birthday);
  const [phone, setPhone] = useState<E164Number | undefined>(
    user.identity.phoneNumber ?? undefined
  );

  const [country, setCountry] = useState<string | undefined>(
    user.identity.countryCode ?? undefined
  );
  const [isValidPhone, setIsValidPhone] = useState(true);
  const [isOptin, setIsOptin] = useState(user?.settings?.isOptin);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const pushToast = useStoreActions((a) => a.toast.pushToast);

  useEffect(() => {
    setIsButtonDisabled(
      user.identity.birthday === birthDate &&
        user.identity.email === email &&
        user.identity.nickname === nickname &&
        user.identity.firstName === firstname &&
        user.identity.lastName === lastname &&
        user.identity.phoneNumber === phone &&
        user.identity.countryCode === country &&
        user.settings.isOptin === isOptin
    );
  }, [
    email,
    nickname,
    firstname,
    lastname,
    birthDate,
    phone,
    country,
    isOptin,
  ]);

  const handleEmailChange = (value: string) => {
    setEmail(value);
  };

  const handleNicknameChange = (value: string) => {
    setNickname(value);
  };

  const handleFirstnameChange = (value: string) => {
    setFirstname(value);
  };

  const handleLastnameChange = (value: string) => {
    setLastname(value);
  };

  const handleChangeCountry = (value: string | undefined) => {
    setCountry(value);
  };

  const handleDateChange = (value: number) => {
    const date = new Date(value);

    const formattedDate = date.toISOString();

    setBirthDate(formattedDate);
  };

  const handlePhoneChange = (value?: E164Number | undefined) => {
    setPhone(value ?? undefined);
  };

  useEffect(() => {
    if (phone) {
      const phoneNumberObject = parsePhoneNumberFromString(phone);

      if (phoneNumberObject && isValidPhoneNumber(phone)) {
        setIsValidPhone(true);
      } else {
        setIsValidPhone(false);
        setIsButtonDisabled(true);
      }
    }
  }, [phone]);

  const handleOptinChange = () => {
    setIsOptin(!isOptin);
  };

  const intl = useIntl();

  const patchMachine = async () => {
    if (user.identity.birthday !== birthDate) {
      await patchBirhday(user.identity.id, birthDate).then(() => {
        pushToast(
          new ToastModel(
            "",
            intl.formatMessage({
              id: "webapp.settings.account.success-message.birth-date",
            }),
            NCToastType.SUCCESS
          )
        );
      });
    }
    if (user.identity.email !== email) {
      await patchEmail(user.identity.id, email).then(() => {
        pushToast(
          new ToastModel(
            "",
            intl.formatMessage({
              id: "webapp.settings.account.success-message.email",
            }),
            NCToastType.SUCCESS
          )
        );
      });
    }
    if (user.identity.nickname !== nickname) {
      await patchNickname(user.identity.id, nickname).then(() => {
        pushToast(
          new ToastModel(
            "",
            intl.formatMessage({
              id: "webapp.settings.account.success-message.nickname",
            }),
            NCToastType.SUCCESS
          )
        );
      });
    }
    if (user.identity.firstName !== firstname) {
      await patchFirstname(user.identity.id, firstname).then(() => {
        pushToast(
          new ToastModel(
            "",
            intl.formatMessage({
              id: "webapp.settings.account.success-message.first-name",
            }),
            NCToastType.SUCCESS
          )
        );
      });
    }
    if (user.identity.lastName !== lastname) {
      await patchLastname(user.identity.id, lastname).then(() => {
        pushToast(
          new ToastModel(
            "",
            intl.formatMessage({
              id: "webapp.settings.account.success-message.last-name",
            }),
            NCToastType.SUCCESS
          )
        );
      });
    }
    if (user.identity.countryCode !== country) {
      await patchCountry(user.identity.id, country ?? "").then(() => {
        pushToast(
          new ToastModel(
            "",
            intl.formatMessage({
              id: "webapp.settings.account.success-message.country",
            }),
            NCToastType.SUCCESS
          )
        );
      });
    }
    if (user.identity.phoneNumber !== phone && phone !== undefined) {
      await patchPhone(user.identity.id, phone).then(() => {
        pushToast(
          new ToastModel(
            "",
            intl.formatMessage({
              id: "webapp.settings.account.success-message.phone-number",
            }),
            NCToastType.SUCCESS
          )
        );
      });
    }
    if (user.settings.isOptin !== isOptin) {
      await patchOptIn(user.identity.id, isOptin || false).then(() => {
        pushToast(
          new ToastModel(
            "",
            intl.formatMessage({
              id: "webapp.settings.account.success-message.optin-options",
            }),
            NCToastType.SUCCESS
          )
        );
      });
    }
  };

  const sendForm = async () => {
    patchMachine().then(() => {
      handleGetUser();
    });
  };

  return (
    <div className="account-settings-content">
      <div className="title">
        <h4>
          <FormattedMessage id={"webapp.settings.account.account-settings"} />
        </h4>
        <h5>
          <FormattedMessage
            id={"webapp.settings.account.personnal-information"}
          />
        </h5>
        <span>
          <FormattedMessage
            id={"webapp.settings.account.personal-information-sentence"}
          />
        </span>
      </div>
      <div className="content">
        <div className="inline-form">
          <div className="input-styles">
            <h6>
              <FormattedMessage id={"webapp.settings.account.email"} />
            </h6>
            <NCInput value={email} onChange={handleEmailChange} />
          </div>
          <div className="input-styles">
            <h6>
              <FormattedMessage id={"webapp.settings.account.nickname"} />
            </h6>
            <NCInput value={nickname} onChange={handleNicknameChange} />
          </div>
        </div>
        <div className="inline-form">
          <div className="input-styles">
            <h6>
              <FormattedMessage id={"webapp.settings.account.firstname"} />
            </h6>
            <NCInput value={firstname} onChange={handleFirstnameChange} />
          </div>
          <div className="input-styles">
            <h6>
              <FormattedMessage id={"webapp.settings.account.lastname"} />
            </h6>
            <NCInput value={lastname} onChange={handleLastnameChange} />
          </div>
        </div>

        <div className="inline-form">
          <div className="input-styles">
            <h6>
              <FormattedMessage id={"webapp.settings.account.country"} />
            </h6>
            <div className="country-select-container">
              <NCSelect
                selectedField={country}
                actionHook={handleChangeCountry}
                fieldValue="value"
                fieldName="label"
                selectFields={countriesOptions}
              />
            </div>
          </div>
          <div className="input-styles">
            <h6>
              <FormattedMessage id={"webapp.settings.account.birthdate"} />
            </h6>
            <DatePicker
              dateChanged={handleDateChange}
              value={birthDate}
              withoutTime
            />
          </div>
        </div>

        <div className="inline-form">
          <div className="input-styles">
            <h6>
              <FormattedMessage id={"webapp.settings.account.phone-number"} />
            </h6>
            <PhoneInput
              placeholder={intl.formatMessage({
                id: "webapp.settings.account.fill-phone-number",
              })}
              value={phone}
              defaultCountry="KE"
              onChange={handlePhoneChange}
            />
            {!isValidPhone && (
              <p>
                <FormattedMessage
                  id={"webapp.settings.account.invalid-phone-format"}
                />
              </p>
            )}
          </div>
        </div>
        <div className="input-styles-checkbox">
          <h6>
            <FormattedMessage
              id={"webapp.settings.account.i-accept-to-recieve-emails"}
            />
          </h6>
          <label className="custom-checkbox">
            <input
              type="checkbox"
              checked={isOptin}
              onChange={handleOptinChange}
            />
            <span className="checkmark"></span>
          </label>
        </div>
        <div className="send-button">
          <Button
            setClick={sendForm}
            label={intl.formatMessage({
              id: "webapp.settings.account.send-button",
            })}
            disabled={isButtonDisabled}
          />
        </div>
      </div>
    </div>
  );
};
