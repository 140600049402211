import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { Button } from "@wolfiesports/srm-component";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import {
  PaymentIntentType,
  SubscriptionPlan,
} from "../../../models/SubscriptionModel";
import {
  deletePaidPaypalIntent,
  postPayPalIntent,
  postPayPalPayment,
  processPaidPaypalIntent,
} from "../../../services/subscription-paypal";

type PremiumModalPaypalMethodProps = {
  selectedPlan: SubscriptionPlan;
  paymentSuccess: () => void;
  paymentFailed: (reason: string) => void;
  handlePrevStep: () => void;
};
export const PremiumModalPaypalMethod = ({
  selectedPlan,
  paymentFailed,
  paymentSuccess,
  handlePrevStep,
}: PremiumModalPaypalMethodProps) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (!isLoaded) {
      setTimeout(() => {
        setIsLoaded(true);
      }, 2700);
    }
  }, [isLoaded]);

  const payPalConfig = !selectedPlan.oneTimePayment
    ? {
        currency: selectedPlan.currency.toUpperCase(),
        clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
        vault: "true",

        createSubscription: async (data: any, actions: any) => {
          console.log(data);
          const x = await actions.subscription.create({
            plan_id: selectedPlan.ids.paypal,
          });
          const serverRes = await postPayPalPayment(
            selectedPlan._id,
            x.toString()
          );
          console.log(serverRes);
          if (serverRes && serverRes.subscriptionId) {
            return serverRes.subscriptionId;
          }
          return x;
        },
        onApprove: async () => {
          console.log("onApprove");
          paymentSuccess();
        },
        onCancel: async (data: any) => {
          await deletePaidPaypalIntent(data.orderID).then(() => {
            paymentFailed("Failed to proceed payment: cancelled");
          });
        },
        onError: async (err: any) => {
          const error = new Error(err);
          if (error.message.search("USER_ACCOUNT_RESTRICTED") > 0) {
            paymentFailed("Failed to proceed payment: create token");
          }
        },
      }
    : {
        currency: selectedPlan.currency.toUpperCase(),
        clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
        vault: true,
        createOrderOnServer: () => {
          return postPayPalIntent(PaymentIntentType.PREMIUM, {
            premiumPlanId: selectedPlan._id,
          });
        },
        onClientAuthorization: async (data: any) => {
          processPaidPaypalIntent(data.id)
            .then(() => {
              paymentSuccess();
            })
            .catch(() => {
              paymentFailed("Failed to proceed payment");
            });
        },
        onApprove: async () => {
          console.log("onApprove");
          paymentSuccess();
        },
        onCancel: async (data: any) => {
          await deletePaidPaypalIntent(data.orderID).then(() => {
            paymentFailed("Failed to proceed payment");
          });
        },
        onError: async (err: any) => {
          const error = new Error(err);
          if (error.message.search("USER_ACCOUNT_RESTRICTED") > 0) {
            paymentFailed("Failed to proceed payment");
          }
        },
      };

  return (
    <div className="paypal-payment-container">
      {!isLoaded ? (
        <div className="premium-loader" />
      ) : (
        <PayPalScriptProvider
          options={{
            clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID ?? "",
            intent: !selectedPlan.oneTimePayment ? "subscription" : "capture",
            vault: !selectedPlan.oneTimePayment,
          }}
        >
          <PayPalButtons
            {...payPalConfig}
            style={{
              layout: "horizontal",
              height: 48,
              label: "checkout",
              color: "black",
              tagline: false,
            }}
          />
        </PayPalScriptProvider>
      )}

      <Button
        setClick={handlePrevStep}
        label={<FormattedMessage id="webapp.premium.go-back" />}
      />
    </div>
  );
};

// this.payPalConfig = {
//     currency: this.selectedPlan.currency.toUpperCase(),
//     clientId: environment.payPalClientId,
//     style,
//     createOrderOnServer: () => {
//         return this.subscriptionService.postPayPalIntent(
//             PaymentIntentType.PREMIUM,
//             { premiumPlanId: this.selectedPlan._id },
//         );
//     },
//     onClientAuthorization: async (data: IClientAuthorizeCallbackData) => {
//         await this.goToSlidePaymentMethod(PaymentMethodEnum.PAY_PAL);
//         this.subscriptionService
//             .processPaidPaypalIntent(data.id)
//             .then(() => {
//                 this.goToSlide(PremiumSlides.CONFIRMATION);
//             }).catch(() => {
//                 this.goToErrorSlide('Fail on processPaidPaypalIntent');
//             });
//     },
//     onCancel: async (data: ICancelCallbackData) => {
//         await this.goToSlidePaymentMethod(PaymentMethodEnum.PAY_PAL);
//         await this.subscriptionService
//             .deletePaidPaypalIntent(data.orderID)
//             .then(() => {
//                 this.goToErrorSlide('Cancelled paypal payment');
//             });
//     },
//     onError: () => {
//         this.goToErrorSlide('Error on paypal');
//     },
// };
