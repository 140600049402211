import {
  Button,
  NCInput,
  NCToastType,
  ToastModel,
} from "@wolfiesports/srm-component";
import { CognitoUser } from "amazon-cognito-identity-js";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { getUserInfos, populateStorage } from "../../../services/Auth";
import { useStoreActions } from "../../../store";
import {
  useForgotPassword,
  useLogin,
  useResetPassword,
} from "../../../utils/session/session";

export const ForgotPasswordPage = () => {
  const intl = useIntl();
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
  const [error, setError] = useState("");
  const [showResetForm, setShowResetForm] = useState(false);
  const [cognitoUser, setCognitoUser] = useState<CognitoUser>();
  const loginCognito = useLogin();

  const forgotPassword = useForgotPassword();
  const resetPassword = useResetPassword();
  //   const resetPassword = useResetPassword();
  const pushToast = useStoreActions((a) => a.toast.pushToast);

  const handleEmailChange = (value: string) => {
    setEmail(value);
  };

  const handleCodeChange = (value: string) => {
    setCode(value);
  };

  const handleNewPasswordChange = (value: string) => {
    setNewPassword(value);
  };

  const handleNewPasswordConfirmChange = (value: string) => {
    setNewPasswordConfirm(value);

    setTimeout(() => {
      if (newPassword !== value) {
        setError(
          intl.formatMessage({
            id: "webapp.auth.register.password-are-different-err-message",
          })
        );
      } else {
        setError("");
      }
    }, 500);
  };

  const handleForgotPassword = () => {
    forgotPassword.mutate(email, {
      onSuccess: (res) => {
        setCognitoUser(res.cognitoUser);
        pushToast(
          new ToastModel(
            "",
            intl.formatMessage({ id: "webapp.forgot-password.sent" }),
            NCToastType.SUCCESS
          )
        );
        setShowResetForm(true);
      },
      onError: (e) => {
        setError(e.message);
      },
    });
  };
  const setIsLoggedIn = useStoreActions((s) => s.login.setIsLoggedIn);
  const setUser = useStoreActions((s) => s.login.setUser);

  const handleLogin = () => {
    loginCognito.mutate(
      { login: email, password: newPassword },
      {
        onSuccess: async (data) => {
          const accessToken = data
            .getSignInUserSession()
            ?.getAccessToken()
            .getJwtToken();
          const refreshToken = data
            .getSignInUserSession()
            ?.getRefreshToken()
            .getToken();

          if (refreshToken) {
            localStorage.setItem("refreshToken", refreshToken);
          }
          if (accessToken) {
            localStorage.setItem("accessToken", accessToken);
            const user = await getUserInfos();
            if (user) {
              setIsLoggedIn(true);
              setUser(await populateStorage(user));
              window.location.href = "/";
            }
          }
        },
        onError: (e) => {
          setError(e.message);
        },
      }
    );
  };

  const handleSendResetPassword = () => {
    if (cognitoUser) {
      resetPassword.mutate(
        { user: cognitoUser, code, newPassword },
        {
          onSuccess: () => {
            handleLogin();
          },
          onError: (e) => {
            setError(e.message);
          },
        }
      );
    }
  };

  return (
    <div className="login-page-container">
      <div className="login-form-container">
        <h2 className="login-title">
          <FormattedMessage id={"webapp.forgot-password"} />
        </h2>
        {!showResetForm && (
          <>
            <NCInput
              value={email}
              label={
                <FormattedMessage id={"webapp.forgot-password.email-field"} />
              }
              onChange={handleEmailChange}
            />
            <Button
              setClick={handleForgotPassword}
              label={
                <FormattedMessage id={"webapp.forgot-password.send-button"} />
              }
            />
          </>
        )}
        {showResetForm && (
          <>
            <NCInput
              value={email}
              label={
                <FormattedMessage id={"webapp.forgot-password.email-field"} />
              }
              onChange={handleEmailChange}
            />
            <NCInput
              value={code}
              label={
                <FormattedMessage id={"webapp.forgot-password.code-field"} />
              }
              onChange={handleCodeChange}
            />
            <NCInput
              value={newPassword}
              type="password"
              label={
                <FormattedMessage
                  id={"webapp.forgot-password.password-field"}
                />
              }
              onChange={handleNewPasswordChange}
            />
            <NCInput
              value={newPasswordConfirm}
              type="password"
              label={
                <FormattedMessage id={"webapp.forgot-password.confirm-field"} />
              }
              onChange={handleNewPasswordConfirmChange}
            />
            <Button
              setClick={handleSendResetPassword}
              label={
                <FormattedMessage
                  id={"webapp.forgot-password.reset-my-password-button"}
                />
              }
            />
          </>
        )}
        {error && (
          <span style={{ color: "red", fontWeight: "bold" }}>{error}</span>
        )}
      </div>
    </div>
  );
};
