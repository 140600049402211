import axios from 'axios';
import { Response } from '../models/api-common.model';
import { EventKey } from '../models/event.model';
import { NotificationEvent } from '../models/notification-event.model';
import { LocalStorageService, StringValueKeys } from './local-storage.service';

export class RTService {
    private static baseUrl: string = String(process.env.REACT_APP_RT_URL);

    static async countEventsUnread(target: string): Promise<number> {
        try {
            const res = (await axios.get<{count: number}>(`${RTService.baseUrl}/rooms/${target}/unread`, {
                headers: {
                    'authorization': LocalStorageService.getStringValue(StringValueKeys.AccessToken)
                }
            })).data;
            return res.count;
        } catch (e) {
            return 0;
        }
    }

    static async getEvents(target: string, key: EventKey | null, limit: number = 20): Promise<Response<NotificationEvent, EventKey>|null> {
        try {
            const res = (await axios.get(`${RTService.baseUrl}/rooms/${target}/events`, {
                params: {
                    date: key?.date,
                    target: key?.target,
                    limit
                },
                headers: {
                    'authorization': LocalStorageService.getStringValue(StringValueKeys.AccessToken)
                }
            })).data;
            return res;
        } catch (e) {
            return null;
        }
    }
}
