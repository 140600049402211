import './TournamentHistory.scss';

import { FormattedMessage } from 'react-intl';
import React, { useEffect, useState } from 'react';

//import { Pagination } from '@wolfiesports/srm-component';

import { PublicUserModel, UserModel } from '../../models/User';
import { TournamentHistoryModel } from '../../models/TournamentModel';
import { getUserHistoryTournaments } from '../../services/no-tournament';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

//const USER_TOURNAMENT_DISPLAYED: number = 10;

interface UserGamesProps {
  user: PublicUserModel;
}

export const TournamentHistory: React.FunctionComponent<UserGamesProps> = ({
  user
}) => {
  const [tournamentHistory, setTournamentHistory] = useState<
    Array<TournamentHistoryModel>
  >([]);
  //const [ page, setPage ] = useState<number>(1);
  const [currentUser, setCurrentUser] = useState<UserModel | null>();
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser) {
      getCurrentUser();
    }
  }, [user]);

  useEffect(() => {
    if (tournamentHistory.length === 0) {
      getTournamentHistory();
    }
  }, [user, currentUser]);

  const getTournamentHistory = async () => {
    // To Update with new route to get history for a specific user
    try {
      const res = await getUserHistoryTournaments(user.userId);
      let tournamentData = res.list.map((item: any) => ({
        date: item.date,
        game: item.info.gameSlug,
        tournamentName: item.info.name,
        rank: item.finalRank,
        id: item.id,
        url: item.url,
      }));
      tournamentData.sort((a: { date: string }, b: { date: string }) => new Date(b.date).getTime() - new Date(a.date).getTime());
      setTournamentHistory(tournamentData);
    } catch (e) {
      console.error("Failed to fetch tournament history:", e);
    }
  };

  const getCurrentUser = async () => {
    const u = JSON.parse(localStorage.getItem("user") ?? "");
    setCurrentUser(u);
  };

  const handleRedirectToTournament = (tournament: any) => {
    if (tournament.id) {
      setTimeout(() => {
        navigate("/tournament/" + tournament.id);
      }, 200);
    }
  };


  return (
    <div className="tournament-history-container">
      <h6 className="tournament-history-title">
        <FormattedMessage id={"webapp.profile.tournament-history"} /> (
        {tournamentHistory.length})
      </h6>
      {tournamentHistory.length ? (
        <table className="tournament-history-table">
          <thead>
            <tr>
              <th>
                <FormattedMessage
                  id={"webapp.profile.tournament-history-table.date"}
                />
              </th>
              <th>
                <FormattedMessage
                  id={
                    "webapp.profile.tournament-history-table.tournament"
                  }
                />
              </th>
              <th>
                <FormattedMessage
                  id={"webapp.profile.tournament-history-table.rank"}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {tournamentHistory.map((entry: any) => (
              <tr
                key={entry.id}
                onClick={() => handleRedirectToTournament(entry)}
                className="cursor-pointer"
              >
                <td>{moment(entry.date).format("DD/MM/YYYY")}</td>
                <td>
                  <div className="tournament-entry">
                    <img
                      src={`${process.env.REACT_APP_AWS_S3_BUCKET}/media/games/${entry.game}/LogoImage`}
                      alt={entry.game}
                    />
                    {entry.tournamentName}
                  </div>
                </td>
                <td>{entry.rank}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : null}
    </div>
  );
};
