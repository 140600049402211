import {
  Button,
  ButtonTheme,
  ButtonType,
  NCInput,
} from "@wolfiesports/srm-component";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { getUserInfos, populateStorage } from "../../../services/Auth";
import { getUserGames } from "../../../services/no-games";
import { useStoreActions } from "../../../store";
import {
  useConfirmRegistration,
  useLogin,
  useResendConfirmationCode,
} from "../../../utils/session/session";
import "./Login.scss";

export const Login = () => {
  const loginCognito = useLogin();
  const confirmRegistration = useConfirmRegistration();
  const resendConfirmationCode = useResendConfirmationCode();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const setIsLoggedIn = useStoreActions((s) => s.login.setIsLoggedIn);
  const setUser = useStoreActions((s) => s.login.setUser);
  const navigate = useNavigate();
  const [emailConfirmationField, setEmailConfirmationField] =
    useState<boolean>(false);

  const [confirmationCode, setConfirmationCode] = useState("");

  const handleLogin = () => {
    loginCognito.mutate(
      { login: email, password: password },
      {
        onSuccess: async (data) => {
          const accessToken = data
            .getSignInUserSession()
            ?.getAccessToken()
            .getJwtToken();
          const refreshToken = data
            .getSignInUserSession()
            ?.getRefreshToken()
            .getToken();

          if (refreshToken) {
            localStorage.setItem("refreshToken", refreshToken);
          }
          if (accessToken) {
            localStorage.setItem("accessToken", accessToken);
            const user = await getUserInfos();
            if (user) {
              setIsLoggedIn(true);
              setUser(await populateStorage(user));
              checkFirstLogin(user);
            }
          }
        },
        onError: (e) => {
          if (e.message === "Email must be confirmed") {
            setEmailConfirmationField(true);
          }
          setError(e.message);
        },
      }
    );
  };

  const checkFirstLogin = async (user: any) => {
    const userGamesRes = await getUserGames(user.userId);

    if (userGamesRes.list.length === 0) {
      window.location.href = "/onboarding";
    } else {
      window.location.href = "/";
    }
  };

  const handleLoginOnConfirmation = () => {
    confirmRegistration.mutate(
      { username: email, code: confirmationCode },
      {
        onSuccess: async () => {
          handleLogin();
        },
        onError: (e) => {
          console.log(e.message);
        },
      }
    );
  };

  const handleEmailChange = (value: string) => {
    setEmail(value);
  };

  const handlePasswordChange = (value: string) => {
    setPassword(value);
  };

  const handleConfirmationCodeChange = (value: string) => {
    setConfirmationCode(value);
  };

  const handleResendCode = () => {
    resendConfirmationCode.mutate(
      { username: email },
      {
        onSuccess: () => {
          setEmailConfirmationField(false);
        },
        onError: (error) => {
          setError(error.message);
        },
      }
    );
  };

  return (
    <div className="login-page-container">
      <div className="login-form-container">
        <h2 className="login-title">
          <FormattedMessage id={"webapp.auth.login"} />
        </h2>
        {!emailConfirmationField && (
          <>
            <NCInput
              autofocus
              value={email}
              label={<FormattedMessage id={"webapp.auth.login.email-input"} />}
              onChange={handleEmailChange}
            />
            <NCInput
              value={password}
              label={
                <FormattedMessage id={"webapp.auth.login.password-input"} />
              }
              type="password"
              onChange={handlePasswordChange}
            />
          </>
        )}
        {emailConfirmationField && (
          <>
            <span>
              <FormattedMessage
                id={"webapp.auth.login.confirm-code-check-mail-message"}
              />
            </span>
            <NCInput
              value={confirmationCode}
              label={
                <FormattedMessage id={"webapp.auth.login.confirmation-code"} />
              }
              onChange={handleConfirmationCodeChange}
            />
          </>
        )}
        <div className="button-part">
          <Button
            setClick={
              !emailConfirmationField ? handleLogin : handleLoginOnConfirmation
            }
            label={<FormattedMessage id={"webapp.auth.login.login-button"} />}
          />
          {emailConfirmationField && (
            <Button
              theme={ButtonTheme.RED}
              type={ButtonType.SECONDARY}
              setClick={handleResendCode}
              label={
                <FormattedMessage id={"webapp.auth.login.resend-code-button"} />
              }
            />
          )}
        </div>
        <span className="forgot" onClick={() => navigate("/forgot")}>
          <FormattedMessage id={"webapp.auth.login.forgot-password-buton"} />
        </span>
        {error && error !== "Email must be confirmed" && (
          <span style={{ color: "red", fontWeight: "bold" }}>{error}</span>
        )}
      </div>
    </div>
  );
};
