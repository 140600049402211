import {
  AuthenticationDetails,
  CodeDeliveryDetails,
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  CognitoUserSession,
  ISignUpResult,
} from "amazon-cognito-identity-js";

import { ClientId, UserPoolId } from "../../config/endpoints";

import {
  CognitoEmailConfirmationRequired,
  CognitoMfaRequired,
  CognitoNewPasswordRequired,
  CognitoPasswordResetRequired,
  UnretrievableCognitoUserSession,
} from "./errors";
import { FakeStorage } from "./fakeStorage";
import { translateCognitoError } from "./translation";
import { ForgotPasswordResult, SignUpAttributes } from "./types";
import { createAttribute, getMfaConfigObj } from "./utils";

/**
 * a class which handle most of cognito requests
 * Provide methodes to get, update or edit a cognito User.
 * you will always start by calling login or retrieveUser
 */
class SessionApi {
  userPool: CognitoUserPool;

  constructor(poolId: string, clientId: string) {
    this.userPool = new CognitoUserPool({
      UserPoolId: poolId,
      ClientId: clientId,
      Storage: new FakeStorage(),
    });
  }

  /***************************
   * SESSION METHODS
   **************************/

  /**
   * create a cognitoUser
   * @param username user's username
   * @returns {CognitoUser} a cognitoUser
   */
  public createUser(username: string) {
    return new CognitoUser({
      Username: username,
      Pool: this.userPool,
      Storage: new FakeStorage(),
    });
  }

  /***************************
   * SESSION METHODS
   **************************/
  /**
   * create an account with provided infos
   * @param username new user's username
   * @param password new user's password
   * @param attributes new user's infos
   * @returns a SignUp result containing cognitoUser and confirmations infos
   */
  public async signUp(
    username: string,
    password: string,
    attributes: SignUpAttributes
  ) {
    // create a list of cognito attribute to send for user signin
    const cognitoAttributes = Object.entries(attributes).map(([name, value]) =>
      createAttribute(name, value)
    );
    const signUpInfos = await new Promise<ISignUpResult>((resolve, reject) =>
      this.userPool.signUp(
        username,
        password,
        cognitoAttributes,
        [],
        (err, res) => {
          if (err) reject(translateCognitoError(err));
          else resolve(res as ISignUpResult);
        }
      )
    );
    return signUpInfos;
  }

  /**
   * create a cognitoUser, log it and returns it
   * three things can happend when we login :
   * - login and that it
   * - you need to change password (throw error)
   * - you need to login with mfa (throw error)
   * @param username a string representing user's username
   * @param password a string representing user's password
   * @returns a logged cognitoUser
   */
  public async login(username: string, password: string) {
    /** create authInfos */
    const authInfos = new AuthenticationDetails({
      Username: username,
      Password: password,
    });
    /** create a user  */
    const user = this.createUser(username);
    /** send request to cognito */
    await new Promise<CognitoUserSession>((resolve, reject) => {
      user.authenticateUser(authInfos, {
        onSuccess: (session) => {
          resolve(session);
        },
        onFailure: (err) => {
          if (err.code === "PasswordResetRequiredException")
            throw new CognitoPasswordResetRequired(user);
          if (err.code === "UserNotConfirmedException")
            throw new CognitoEmailConfirmationRequired(user);
          reject(translateCognitoError(err));
        },
        newPasswordRequired: (userAttributes, requiredAttributes) => {
          throw new CognitoNewPasswordRequired(
            user,
            userAttributes,
            requiredAttributes
          );
        },
        mfaRequired(challengeName, challengeParameters) {
          throw new CognitoMfaRequired(
            user,
            challengeName,
            challengeParameters
          );
        },
      });
    });

    return user;
  }

  /**
   * refresh user's access token using a refresh token
   * @param user a logged cognito user
   * @param refreshToken refresh token
   * @returns an updated cognitoUser containing updated credentials
   */
  public async refreshSession(user: CognitoUser, refreshToken: string) {
    await new Promise<void>((resolve, reject) => {
      user.refreshSession(
        new CognitoRefreshToken({ RefreshToken: refreshToken }),
        (err) => {
          if (err) reject(translateCognitoError(err));
          resolve();
        }
      );
    });
  }

  /**
   * logout a user, invalidating access token
   * @param user a logged cognito user
   */
  public async logout(user: CognitoUser) {
    await new Promise<void>((resolve) => {
      user.signOut(() => {
        resolve();
      });
    });
  }

  /**
   * logout user from all connected devices, invalidating
   * all credentials token from current pool
   * @param user a logged cognito user
   */
  public async globalLogout(user: CognitoUser) {
    await new Promise<void>((resolve, reject) => {
      user.globalSignOut({
        onSuccess: () => {
          resolve();
        },
        onFailure: (err) => reject(translateCognitoError(err)),
      });
    });
  }

  /***************************
   * RETRIEVE SESSION
   **************************/

  /**
   * retrieve a session from local credentials.
   * to be able to retrieve session, we must have :
   * - either an accessToken + idToken
   * - or an idToken + refreshToken
   * to recreate a logged cognito user, we need accessToken + idToken.
   * access token can either be provided or retrieved by trying to refresh session.
   * @param accessToken (optional): the current access token (contains username)
   * @param idToken: (optionna) the current id token (contains username)
   * @param refreshToken: (optional) the current refresh token
   * @returns a logged cognito session
   */
  public async retrieveSession(
    accessToken?: string,
    idToken?: string,
    refreshToken?: string
  ) {
    // a user with unknown username (we unfortunatly can't retrieve it for sure)
    const user = this.createUser("");
    // if we have what it takes to recreate a session
    if (accessToken && idToken) {
      user.setSignInUserSession(
        new CognitoUserSession({
          AccessToken: new CognitoAccessToken({ AccessToken: accessToken }),
          IdToken: new CognitoIdToken({ IdToken: idToken }),
          RefreshToken: refreshToken
            ? new CognitoRefreshToken({ RefreshToken: refreshToken })
            : undefined,
        })
      );
      // if we only have a refreshToken
    } else if (refreshToken) {
      await this.refreshSession(user, refreshToken);
      // if we can't retrieve session
    } else {
      throw new UnretrievableCognitoUserSession();
    }
    return user;
  }

  /***************************
   * MFA METHODS
   **************************/

  /**
   * select a MFA type for current user
   * @param user a logged cognito user
   * @param type can be either SMS, TOTP or NONE
   */
  public async setMfaType(user: CognitoUser, type: "SMS" | "TOTP" | "NONE") {
    return new Promise<void>((resolve, reject) => {
      user.setUserMfaPreference(
        getMfaConfigObj(type === "SMS"),
        getMfaConfigObj(type === "TOTP"),
        (err) => {
          if (err) reject(translateCognitoError(err));
          resolve();
        }
      );
    });
  }

  /**
   * send MFA code received through SMS / TOTP.
   * Should only be used if MFA is requested from login.
   * @param user a cognito user (comming from login)
   * @param code verification code
   * @returns a logged cognito session user
   */
  public async sendMfaCode(
    user: CognitoUser,
    code: string
  ): Promise<CognitoUser> {
    new Promise<void>((resolve, reject) => {
      user.sendMFACode(code, {
        onSuccess: () => {
          resolve();
        },
        onFailure: (err) => reject(translateCognitoError(err)),
      });
    });
    return user;
  }

  /***************************
   * PASSWORD METHODS
   **************************/

  /**
   * ask a way to get a new password for current user
   * @param username user's username
   * @returns cognitoUser and infos on how to retrieve password
   */
  public async forgotPassword(username: string): Promise<ForgotPasswordResult> {
    const user = this.createUser(username);
    const codeDeliveryDetails = await new Promise<CodeDeliveryDetails>(
      (resolve, reject) => {
        user.forgotPassword({
          onSuccess: (res) => resolve(res.CodeDeliveryDetails),
          onFailure: (err) => {
            reject(translateCognitoError(err));
          },
        });
      }
    );
    return { cognitoUser: user, codeDeliveryDetails };
  }

  /**
   * ask a way to get a new password for current user
   * @param username user's username
   * @returns cognitoUser and infos on how to retrieve password
   */
  public async resetPassword(
    user: CognitoUser,
    code: string,
    newPassword: string
  ): Promise<void> {
    await new Promise<void>((resolve, reject) => {
      user.confirmPassword(code, newPassword, {
        onSuccess: () => resolve(),
        onFailure: (err) => reject(translateCognitoError(err)),
      });
    });
  }

  /**
   * Change current logged user's password
   * @param user a logged cognito user
   * @param oldPassword current user's password
   * @param newPassword new user's password
   */
  public async changePassword(
    user: CognitoUser,
    oldPassword: string,
    newPassword: string
  ) {
    return new Promise<void>((resolve, reject) => {
      user.changePassword(oldPassword, newPassword, (err) => {
        if (err) reject(translateCognitoError(err));
        else resolve();
      });
    });
  }

  /**
   * change user password when it is required during login
   * @param user a cognito user (coming from login)
   * @param newPassword user's new password
   * @param sessionUserAttributes session attributes
   * @returns a logged cognito user
   */
  public async completeNewPasswordChallenge(
    user: CognitoUser,
    newPassword: string,
    sessionUserAttributes: CognitoUserAttribute[]
  ) {
    await new Promise<CognitoUserSession>((resolve, reject) => {
      user.completeNewPasswordChallenge(newPassword, sessionUserAttributes, {
        onSuccess: (session) => {
          resolve(session);
        },
        onFailure: (err) => {
          reject(translateCognitoError(err));
        },
        newPasswordRequired: (userAttributes, requiredAttributes) => {
          throw new CognitoNewPasswordRequired(
            user,
            userAttributes,
            requiredAttributes
          );
        },
        mfaRequired(challengeName, challengeParameters) {
          throw new CognitoMfaRequired(
            user,
            challengeName,
            challengeParameters
          );
        },
      });
    });
  }

  /***************************
   * SMS METHODS
   **************************/

  /**
   * ask for a new confirmation code
   * @param user a cognito user (coming from login)
   */
  public async resendConfirmationCode(user: CognitoUser) {
    await new Promise<void>((resolve, reject) => {
      user.resendConfirmationCode((err, res) => {
        if (err) reject(translateCognitoError(err));
        resolve(res);
      });
    });
  }

  /**
   * confirm registration with user's received code
   * @param code the code received by the user
   */
  public async confirmRegistration(user: CognitoUser, code: string) {
    await new Promise((resolve, reject) => {
      user.confirmRegistration(code, true, (err, res) => {
        if (err) reject(translateCognitoError(err));
        else resolve(res);
      });
    });
  }
}

// export as default
const sessionApi = new SessionApi(UserPoolId, ClientId);
export default sessionApi;
