import { NCDialog } from "@wolfiesports/srm-component";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { PublicUserModel } from "../../models/User";
import { UserProfileMedia } from "../../pages/Profile/Profile";
import { updateLocalStorageObject } from "../../services/Auth";
import { patchAvatar, patchBanner } from "../../services/no-user";
import "./EditUserMedias.scss";

const profiles = Array.from(
  { length: 5 },
  (_, i) =>
    `https://assets.next.wolfiesports.com/media/assets/user/profile/${i}.png`
);

const banners = Array.from(
  { length: 5 },
  (_, i) =>
    `https://assets.next.wolfiesports.com/media/assets/user/banner/${i}.png`
);

interface EditUserMediasProps {
  type: UserProfileMedia;
  user: PublicUserModel;
  show: boolean;
  setShow: any;
  updateUser: () => void;
}

export const EditUserMedias: React.FunctionComponent<EditUserMediasProps> = ({
  type,
  user,
  show,
  setShow,
  updateUser,
}) => {
  const [selectedProfile, setSelectedProfile] = useState(user.avatar);
  const [selectedBanner, setSelectedBannner] = useState(user.banner);

  const updateAvatar = async (profileId: string) => {
    await patchAvatar(user.userId, profileId).then(() => {
      updateLocalStorageObject("user", "settings.avatar", profileId);
      updateUser();
    });
  };

  const updateBanner = async (bannerId: string) => {
    await patchBanner(user.userId, bannerId).then(() => {
      updateLocalStorageObject("user", "settings.banner", bannerId);
      updateUser();
    });
  };

  const handleSelectProfile = (profileSelected: string) => () => {
    setSelectedProfile(profileSelected);
    updateAvatar(profileSelected);
    setShow(false);
  };

  const handleBannerProfile = (bannerSelected: string) => () => {
    setSelectedBannner(bannerSelected);
    updateBanner(bannerSelected);
    setShow(false);
  };

  const renderTitle = (): React.ReactElement => {
    if (type === UserProfileMedia.PICTURE) {
      return (
        <FormattedMessage
          id="user.edit.medias.profile"
          description="User - Edit User Medias - Edit"
        />
      );
    } else if (type === UserProfileMedia.BANNER) {
      return (
        <FormattedMessage
          id="user.edit.medias.banner"
          description="User - Edit User Medias - Edit"
        />
      );
    } else {
      return <></>;
    }
  };

  const renderUpdateAvatar = (): React.ReactElement => {
    return (
      <div className="profile-selection">
        {profiles.map((imgSrc, index) => (
          <div
            key={index}
            className={`profile-item ${selectedProfile === index.toString() ? "active" : ""}`}
            onClick={handleSelectProfile(index.toString())}
          >
            <img
              src={imgSrc}
              alt={`Profile ${index}`}
              className="profile-card"
            />
          </div>
        ))}
      </div>
    );
  };

  const renderUpdateBanner = (): React.ReactElement => {
    return (
      <div className="banner-selection">
        {banners.map((imgSrc, index) => (
          <div
            key={index}
            className={`banner-item ${selectedBanner === index.toString() ? "active" : ""}`}
            onClick={handleBannerProfile(index.toString())}
          >
            <img src={imgSrc} alt={`Banner ${index}`} className="banner-card" />
          </div>
        ))}
      </div>
    );
  };

  return (
    <NCDialog show={show} setShow={() => setShow(false)} title={renderTitle()}>
      {type === UserProfileMedia.BANNER && renderUpdateBanner()}

      {type === UserProfileMedia.PICTURE && renderUpdateAvatar()}
    </NCDialog>
  );
};
