import { Button, NCInput, NCTextArea, NCToastType, ToastModel } from "@wolfiesports/srm-component";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useStoreActions } from "../../store";
import "./Contact.scss";

export const ContactPage = () => {
  const intl = useIntl();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [emailContent, setEmailContent] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const pushToast = useStoreActions((a) => a.toast.pushToast);

  const navigate = useNavigate();

  var AWS = require("aws-sdk");
  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_AWS_REGION
  });

  useEffect(() => {
    setIsButtonDisabled(
      name === "" || email === "" || emailContent === "" || subject === ""
    );
  }, [name, email, emailContent]);

  const handleChangeName = (value: string) => {
    setName(value);
  };

  const handleChangeEmail = (value: string) => {
    setEmail(value);
  };

  const handleChangeEmailContent = (value: string) => {
    setEmailContent(value);
  };

  const handleChangeSubject = (value: string) => {
    setSubject(value);
  };

  const sendForm = () => {
    const params = {
      Destination: {
        ToAddresses: ["contact@pl4ymedia.com"]
      },
      Message: {
        Body: {
          Text: {
            Data: `Email from: ${name}\nAnswer To: ${email}\nContent: ${emailContent}`
          }
        },
        Subject: {
          Data: subject
        }
      },
      Source: "no-reply@wolfiesports.com"
    };

    const sendPromise = new AWS.SES({ apiVersion: "2010-12-01" })
      .sendEmail(params)
      .promise();

    sendPromise.then(() => {
      pushToast(
        new ToastModel(
          "",
          intl.formatMessage({ id: "webapp.contact.sent" }),
          NCToastType.SUCCESS
        ))
        navigate("/");
    }).catch((error: any) => {
          console.error("Error sending email:", error);
        });


  };

  return (
    <div className="contact-page-container">
      <h3>
        <FormattedMessage id={"webapp.contact.contact-us"} />
      </h3>
      <div className="contact-form-container">
        <NCInput
          autofocus
          label={<FormattedMessage id={"webapp.contact.name-label"} />}
          value={name}
          onChange={handleChangeName}
        />
        <NCInput
          label={<FormattedMessage id={"webapp.contact.email-label"} />}
          value={email}
          onChange={handleChangeEmail}
        />
        <NCInput
          label={<FormattedMessage id={"webapp.contact.subject-label"} />}
          value={subject}
          onChange={handleChangeSubject}
        />
        <NCTextArea
          value={emailContent}
          placeHolder={intl.formatMessage({
            id: "webapp.contact.email-content-textarea-placeholder",
          })}
          actionHook={handleChangeEmailContent}
          minRows={4}
        />
        <Button
          setClick={sendForm}
          label={intl.formatMessage({
            id: "webapp.settings.biography.send-button",
          })}
          disabled={isButtonDisabled}
        />
      </div>
    </div>
  );
};
