import { Button } from "@wolfiesports/srm-component";
import parsePhoneNumberFromString, {
  E164Number,
  isValidPhoneNumber,
} from "libphonenumber-js";
import { useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { SubscriptionPlan } from "../../../models/SubscriptionModel";
import { mpesaPay } from "../../../services/subscripiton-mpesa";
import {
  EventMessage,
  MessageType,
  useWebSocket,
} from "../../../utils/websocket/WebSocketProvider";
import "../styles.scss";

type PremiumModalMpesaMethodProps = {
  selectedPlan: SubscriptionPlan;
  paymentSuccess: () => void;
  paymentFailed: (reason: string) => void;
  paymentWaiting: () => void;
  handlePrevStep: () => void;
};

export const PremiumModalMpesaMethod = ({
  selectedPlan,
  paymentSuccess,
  paymentFailed,
  paymentWaiting,
  handlePrevStep,
}: PremiumModalMpesaMethodProps) => {
  const intl = useIntl();
  const [isValidPhone, setIsValidPhone] = useState(true);
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState<
    string | null
  >(null);
  const [phone, setPhone] = useState<E164Number | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [isWaitingForPayment, setIsWaitingForPayment] = useState(false);

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const { subscribe, unsubscribe } = useWebSocket();

  const userJson = localStorage.getItem("user");
  const user = userJson ? JSON.parse(userJson) : null;

  const messageReceivedRef = useRef(false);

  useEffect(() => {
    if (phone) {
      const phoneNumberObject = parsePhoneNumberFromString(phone);

      if (phoneNumberObject && isValidPhoneNumber(phone)) {
        setIsValidPhone(true);
        setFormattedPhoneNumber(phoneNumberObject.format("E.164"));
      } else {
        setIsValidPhone(false);
        setFormattedPhoneNumber(null);
      }
    }
  }, [phone]);

  const handleSendPhoneNumber = async () => {
    if (isValidPhone && formattedPhoneNumber) {
      setIsLoading(true);
      await mpesaPay(selectedPlan._id, formattedPhoneNumber)
        .then(() => {
          setIsWaitingForPayment(true);
        })
        .catch((e) => {
          paymentFailed(e.response.data.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      console.log("Invalid phone number.");
    }
  };

  useEffect(() => {
    if (isWaitingForPayment && user && user.identity && user.identity.id) {
      timeoutRef.current = setTimeout(() => {
        if (!messageReceivedRef.current) {
          console.log("No message received within 6 seconds");
          paymentWaiting();
        }
      }, 6000);

      return () => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
          timeoutRef.current = null;
        }
        unsubscribe("payment-intent" as MessageType, user.identity.id);
        unsubscribe("premium" as MessageType, user.identity.id);
      };
    }
  }, [isWaitingForPayment, user]);

  const handleWebsocketMessage = (_room: string, message: EventMessage) => {
    if (
      message.type === ("payment-intent" as MessageType) &&
      message.content.success
    ) {
      messageReceivedRef.current = true;
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
      paymentSuccess();
      setIsWaitingForPayment(false);
    } else if (
      (message.type === ("premium" as MessageType) &&
        message.content.paymentState === "PAYMENTFAILURE") ||
      (message.type === ("payment-intent" as MessageType) &&
        !message.content.success)
    ) {
      messageReceivedRef.current = true;
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
      paymentFailed("Payment failed");
      setIsWaitingForPayment(false);
    }
  };

  subscribe(
    "payment-intent" as MessageType,
    user.identity.id,
    handleWebsocketMessage
  );
  subscribe("premium" as MessageType, user.identity.id, handleWebsocketMessage);

  const handleSetPhone = (value?: E164Number | undefined) => {
    setPhone(value ?? undefined);
  };

  return !isLoading && !isWaitingForPayment ? (
    <>
      <p>
        <FormattedMessage
          id={"webapp.premium.mpesa.please-enter-your-phone-number"}
        />
      </p>
      <PhoneInput
        placeholder={intl.formatMessage({
          id: "webapp.settings.account.fill-phone-number",
        })}
        value={phone}
        defaultCountry="KE"
        onChange={handleSetPhone}
      />
      {!isValidPhone && (
        <p>
          <FormattedMessage
            id={"webapp.settings.account.invalid-phone-format"}
          />
        </p>
      )}
      <Button
        label={<FormattedMessage id={"webapp.settings.account.send"} />}
        setClick={handleSendPhoneNumber}
        disabled={!isValidPhone}
      />
      <Button
        label={<FormattedMessage id="webapp.premium.go-back" />}
        setClick={handlePrevStep}
      />
    </>
  ) : isWaitingForPayment ? (
    <div className="loading-mpesa">
      <p>
        <FormattedMessage id="webapp.premium.mpesa.success-on-our-side" />
      </p>
      <p>
        <FormattedMessage id="webapp.premium.mpesa.processing" />
      </p>
      <div className="premium-loader"></div>
    </div>
  ) : (
    <div className="loading-mpesa">
      <p>
        <FormattedMessage id="webapp.premium.mpesa.loading" />
      </p>
      <div className="premium-loader"></div>
    </div>
  );
};
