import { BillingDetails, PaymentIntentType } from "../models/SubscriptionModel";
import { axiosAuthenticated } from "./Auth";

export const deleteStripeInformation = async (cardId: string) => {
  try {
    const res = await axiosAuthenticated.delete(
      `${process.env.REACT_APP_BASE_API_SUBSCRIPTION_URL}/apply/stripe/information/${cardId}`
    );
    return res;
  } catch (e: any) {
    throw new Error(e.message);
  }
};

export const updateStripeInformation = async (
  cardId: string,
  adress: BillingDetails
) => {
  try {
    const res = await axiosAuthenticated.put(
      `${process.env.REACT_APP_BASE_API_SUBSCRIPTION_URL}/apply/stripe/information/${cardId}`,
      adress
    );
    return res;
  } catch (e: any) {
    throw new Error(e.message);
  }
};

export const updateStripeSubscriptionCard = async (token: string) => {
  try {
    const res = await axiosAuthenticated.put(
      `${process.env.REACT_APP_BASE_API_SUBSCRIPTION_URL}/apply/stripe/subscription/source`,
      token
    );
    return res;
  } catch (e: any) {
    throw new Error(e.message);
  }
};

export const getStripeInformation = async () => {
  try {
    const res = await axiosAuthenticated.get(
      `${process.env.REACT_APP_BASE_API_SUBSCRIPTION_URL}/apply/stripe/information`
    );
    return res;
  } catch (e: any) {
    throw new Error(e.message);
  }
};

export const deleteSubscriptionAddress = async (id: string) => {
  try {
    const res = await axiosAuthenticated.delete(
      `${process.env.REACT_APP_BASE_API_SUBSCRIPTION_URL}/address/${id}`
    );
    return res.data;
  } catch (e: any) {
    throw new Error(e.message);
  }
};

export const putStripeInformation = async (information: any, token: string) => {
  const billing_details = information.billing_details;
  const payload = {
    token,
    name: billing_details.name,
    address: {
      city: billing_details.address.city,
      country: billing_details.address.country,
      line1: billing_details.address.line1,
      line2: billing_details.address.line2,
      postal_code: billing_details.address.postal_code,
      state: "none",
    },
  };

  try {
    const res = await axiosAuthenticated.put(
      `${process.env.REACT_APP_BASE_API_SUBSCRIPTION_URL}/apply/stripe/information`,
      payload
    );
    return res;
  } catch (e: any) {
    throw new Error(e.message);
  }
};

export const putStripeAddress = async (information: any) => {
  const payload = {
    name: information.name,
    address: {
      city: information.city,
      country: information.selectedCountry.iso2,
      line1: information.billingAddress,
      line2: information.secondBillingAddress,
      postal_code: information.zipCode,
      state: "none",
    },
  };

  try {
    const res = await axiosAuthenticated.put(
      `${process.env.REACT_APP_BASE_API_SUBSCRIPTION_URL}/apply/stripe/address`,
      payload
    );
    return res;
  } catch (e: any) {
    throw new Error(e.message);
  }
};

export const postAddress = async (address: any) => {
  const payload = {
    name: address.firstName + " " + address.lastName,
    address: {
      city: address.city,
      country: address.selectedCountry.iso2,
      line1: address.billingAddress,
      line2: address.secondBillingAddress || "",
      postal_code: address.zipCode,
      state: "",
    },
  };

  try {
    const res = await axiosAuthenticated.post(
      `${process.env.REACT_APP_BASE_API_SUBSCRIPTION_URL}/adresses`,
      payload
    );
    return res;
  } catch (e: any) {
    throw new Error(e.message);
  }
};

export const stripePay = async (planId: string, stripeToken: string) => {
  try {
    const res = await axiosAuthenticated.post(
      `${process.env.REACT_APP_BASE_API_SUBSCRIPTION_URL}/apply/stripe/plans/${planId}`,
      { cardId: stripeToken }
    );
    return res;
  } catch (e: any) {
    throw new Error(e.message);
  }
};

export const postStripeIntent = async (type: PaymentIntentType, data: any) => {
  try {
    const res = await axiosAuthenticated.post(
      `${process.env.REACT_APP_BASE_API_SUBSCRIPTION_URL}/paymentintent/stripe`,
      { type, data }
    );
    return res;
  } catch (e: any) {
    throw new Error(e.message);
  }
};

export const deleteStripeIntent = async (id: string) => {
  try {
    const res = await axiosAuthenticated.delete(
      `${process.env.REACT_APP_BASE_API_SUBSCRIPTION_URL}/paymentintent/stripe/${id}`
    );
    return res;
  } catch (e: any) {
    throw new Error(e.message);
  }
};

export const processPaidStripeIntent = async (id: string) => {
  try {
    const res = await axiosAuthenticated.post(
      `${process.env.REACT_APP_BASE_API_SUBSCRIPTION_URL}/paymentintent/stripe/${id}/succeded`
    );
    return res;
  } catch (e: any) {
    throw new Error(e.message);
  }
};
