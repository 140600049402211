import { ToastModel } from "@wolfiesports/srm-component";
import {
  Action,
  action,
  createStore,
  createTypedHooks,
  persist,
} from "easy-peasy";
import { UserModel } from "./models/User";

export interface LoginStore {
  accessToken: string;
  setAccessToken: Action<LoginStore, string>;
  refreshToken: string;
  setRefreshToken: Action<LoginStore, string>;
  authToken: string;
  setAuthToken: Action<LoginStore, string>;
  isLoggedIn: boolean;
  setIsLoggedIn: Action<LoginStore, boolean>;
  logOutAction: Action<LoginStore>;
  websiteLang: string;
  setWebsiteLang: Action<LoginStore, string>;
  contentLang: string;
  setContentLang: Action<LoginStore, string>;
  languages: any;
  setLanguages: Action<LoginStore, any>;
  menuItemCollapse: Array<any>;
  setMenuItemCollapse: Action<LoginStore, Array<any>>;
  user: UserModel | null;
  setUser: Action<LoginStore, UserModel | null>;
}

interface ToastStore {
  list: Array<ToastModel>;
  pushToast: Action<ToastStore, ToastModel>;
  deleteToast: Action<ToastStore, number>;
}

export interface RootStore {
  login: LoginStore;
  toast: ToastStore;
}

export const store = createStore<RootStore>(
  persist(
    {
      login: {
        accessToken: "",
        setAccessToken: action((state, data) => {
          state.accessToken = data;
        }),
        refreshToken: "",
        setRefreshToken: action((state, data) => {
          state.refreshToken = data;
        }),
        authToken: "",
        setAuthToken: action((state, data) => {
          state.authToken = data;
        }),
        isLoggedIn: false,
        setIsLoggedIn: action((state, data) => {
          state.isLoggedIn = data;
        }),
        languages: null,
        setLanguages: action((state, data) => {
          state.languages = data;
        }),
        websiteLang: "en",
        setWebsiteLang: action((state, data) => {
          state.websiteLang = data;
        }),
        contentLang: "en",
        setContentLang: action((state, data) => {
          state.contentLang = data;
        }),
        menuItemCollapse: [],
        setMenuItemCollapse: action((state, data) => {
          state.menuItemCollapse = data;
        }),
        logOutAction: action((state) => {
          state.accessToken = "";
          state.authToken = "";
          state.isLoggedIn = false;
          state.refreshToken = "";
          state.languages = [];
          state.websiteLang = "";
          state.contentLang = "";
        }),
        user: null,
        setUser: action((state, data) => {
          state.user = data;
        }),
      },
      toast: {
        list: [],
        pushToast: action((state, toast) => {
          state.list.push(toast);
        }),
        deleteToast: action((state, toastId) => {
          state.list = state.list.filter((t) => t.id !== toastId);
        }),
      },
    },
    {
      storage: "localStorage",
    }
  )
);

const typedHooks = createTypedHooks<RootStore>();

export const useStoreActions = typedHooks.useStoreActions;
export const useStoreState = typedHooks.useStoreState;
