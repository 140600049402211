import { Response } from '../models/api-common.model';
import { EventKey } from '../models/event.model';
import { NotificationEvent } from '../models/notification-event.model';
import { NotificationService } from '../services/notification.service';
import { RTService } from '../services/rt.service';

export const getNotifications = async (max: number, intl : any) => {
    const user = JSON.parse(localStorage.getItem("user") ?? "")

    if (!user || !user.identity.id) {
        return [];
    }

    const res: Response<NotificationEvent, EventKey> | null =
        await RTService.getEvents(
            user.identity.id,
            null,
            max
        );
    if (!res) {
        return [];
    }
    const events = res.list
        .map((e) =>
            NotificationService.formatEventToNotification(
                e,
                intl
            ),
        )
        .filter((e) => e) as Array<NotificationEvent>;

    return events;
}
