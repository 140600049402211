export const cognitoErrorTranslation: Record<string, string> = {
  "Incorrect username or password.": "Email ou mot de passe incorrect.",
  "Password attempts exceeded": "Nombre d'essais dépassé.",
  "Password reset required for the user": "Changement de mot de passe requis.",
  "Invalid code provided, please request a code again.":
    "Code invalide. Veuillez demander un nouveau code.",
  "Attempt limit exceeded, please try after some time.":
    "Limite de demandes dépassée, réessayez plus tard.",
};

/** translate message from a cognito error to french
 * @param error an Error class
 * @returns the initial error with its message translated
 * (if a matching translation has been found)
 */
export const translateCognitoError = (error: Error) => {
  try {
    error.message = cognitoErrorTranslation[error.message] || error.message;
    return error;
  } catch (e) {
    return error;
  }
};
