import {
  CognitoUser,
  CognitoUserPool,
  ISignUpResult,
} from "amazon-cognito-identity-js";
import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from "react-query";

import appQueryClient from "../../config/queryConfig";
import { ACCESS_TOKEN, ID_TOKEN, REFRESH_TOKEN } from "../../config/varStorage";
import { getCookie } from "../cookies";

import sessionApi from "./sessionApi";
import {
  ForgotPasswordResult,
  LoginProps,
  MfaTypeMutationProps,
  PasswordMutationOnLoginProps,
  PasswordMutationProps,
  PasswordResetProps,
  SendMfaCodeProps,
  SigninProps,
} from "./types";
import { clearSessionCookies, storeSessionCookies } from "./utils";

/** react-query key for user */
export const USER_QUERY_KEY = ["user"];

/*******************************************************************
 ******************************* DATAS *****************************
 *******************************************************************/

/**
 * a react query hook to use wherever you want to get current user
 * first time it is called on top of app, retrieve a potential existing session
 * from credentials stored in cookies
 * @param options react-query options
 */
export const useSession = (options?: UseQueryOptions<CognitoUser>) =>
  useQuery<CognitoUser>(
    USER_QUERY_KEY,
    async () => {
      const accessToken = await getCookie(ACCESS_TOKEN);
      const idToken = await getCookie(ID_TOKEN);
      const refreshToken = await getCookie(REFRESH_TOKEN);
      const user = await sessionApi.retrieveSession(
        accessToken?.value,
        idToken?.value,
        refreshToken?.value
      );
      storeSessionCookies(user.getSignInUserSession());
      return user;
    },
    { enabled: false, ...options }
  );

/*******************************************************************
 ************************* LOGIN MUTATION **************************
 *******************************************************************/

/**
 * a react query mutation hook which triggers a request to log an user
 * @param options react-query mutation options
 */
export const useSignup = (
  options?: UseMutationOptions<ISignUpResult, any, LoginProps>
) =>
  useMutation(
    ({ login, password, attributes }: SigninProps) =>
      sessionApi.signUp(login, password, attributes || {}),
    options
  );

/**
 * a react query mutation hook which triggers a request to log an user
 * @param options react-query mutation options
 */
export const useLogin = (
  options?: UseMutationOptions<CognitoUser, any, LoginProps>
) =>
  useMutation(
    ({ login, password }: LoginProps) => sessionApi.login(login, password),
    {
      ...options,
      onSuccess: (data, variables, context) => {
        appQueryClient.setQueryData(USER_QUERY_KEY, data);
        storeSessionCookies(data.getSignInUserSession());
        options?.onSuccess?.(data, variables, context);
      },
    }
  );

/**
 * a react query mutation hook which triggers a request to change password on login
 * @param options react-query mutation options
 */
export const useChangePasswordOnLogin = (
  options?: UseMutationOptions<void, any, PasswordMutationOnLoginProps>
) =>
  useMutation(
    ({ user, newPassword }: PasswordMutationOnLoginProps) =>
      sessionApi.completeNewPasswordChallenge(user, newPassword, []),
    {
      ...options,
      onSuccess: (data, variables, context) => {
        appQueryClient.setQueryData(USER_QUERY_KEY, variables.user);
        storeSessionCookies(variables.user.getSignInUserSession());
        options?.onSuccess?.(data, variables, context);
      },
    }
  );

/**
 * a react query mutation hook which triggers a request to change password for logged user
 * @param options react-query mutation options
 */
export const useChangePassword = (
  options?: UseMutationOptions<void, any, PasswordMutationProps>
) =>
  useMutation(
    ({ user, oldPassword, newPassword }: PasswordMutationProps) =>
      sessionApi.changePassword(user, oldPassword, newPassword),
    options
  );

/**
 * a react query mutation hook which triggers a request to loggout an user localy
 * @param options react-query mutation options
 */
export const useLogout = (
  options?: UseMutationOptions<void, any, CognitoUser>
) =>
  useMutation((user: CognitoUser) => sessionApi.logout(user), {
    ...options,
    onSettled: (data, error, variables, context) => {
      appQueryClient.resetQueries();
      clearSessionCookies();
      options?.onSettled?.(data, error, variables, context);
    },
  });

/**
 * a react query mutation hook which triggers a request to loggout an user globaly
 * @param options react-query mutation options
 */
export const useGlobalLogout = (
  options?: UseMutationOptions<void, any, CognitoUser>
) =>
  useMutation((user: CognitoUser) => sessionApi.globalLogout(user), {
    ...options,
    onSettled: (data, error, variables, context) => {
      appQueryClient.resetQueries();
      clearSessionCookies();
      options?.onSettled?.(data, error, variables, context);
    },
  });

/******************************************** */
/********************* MFA ****************** */
/******************************************** */

/**
 * a react query mutation hook which triggers a request to change MFA type
 * @param options react-query mutation options
 */
export const useChangeMfaType = (
  options?: UseMutationOptions<void, any, MfaTypeMutationProps>
) =>
  useMutation(
    ({ user, type }: MfaTypeMutationProps) => sessionApi.setMfaType(user, type),
    options
  );

/**
 * a react query mutation hook which triggers a request to send a mfa code
 * @param options react-query mutation options
 */
export const useSendMfaCode = (
  options?: UseMutationOptions<CognitoUser, any, SendMfaCodeProps>
) =>
  useMutation(
    ({ user, code }: SendMfaCodeProps) => sessionApi.sendMfaCode(user, code),
    {
      ...options,
      onSuccess: (data, variables, context) => {
        appQueryClient.setQueryData(USER_QUERY_KEY, data);
        storeSessionCookies(data.getSignInUserSession());
        options?.onSuccess?.(data, variables, context);
      },
    }
  );

/******************************************** */
/************** PASSWORD RESET ************** */
/******************************************** */

/**
 * a react query mutation hook which triggers a request to ask for a way to
 * retrieve a lost password
 * @param options react-query mutation options
 */
export const useForgotPassword = (
  options?: UseMutationOptions<ForgotPasswordResult, any, string>
) =>
  useMutation(
    (username: string) => sessionApi.forgotPassword(username),
    options
  );

/**
 * a react query mutation hook which triggers a request to ask for a way to
 * retrieve a lost password
 * @param options react-query mutation options
 */
export const useResetPassword = (
  options?: UseMutationOptions<void, any, PasswordResetProps>
) =>
  useMutation(
    ({ user, code, newPassword }: PasswordResetProps) =>
      sessionApi.resetPassword(user, code, newPassword),
    options
  );

/******************************************** */
/**************** REGISTRATION ************** */
/******************************************** */

/**
 * a react query mutation hook which triggers a request to ask again
 * for a confirmation code
 * @param options react-query mutation options
 */
export const useResendConfirmationCode = (
  options?: UseMutationOptions<void, any, { username: string }>
) =>
  useMutation(({ username }) => {
    const user = createCognitoUser(username);
    return sessionApi.resendConfirmationCode(user);
  }, options);

/**
 * a react query mutation hook which triggers a request to confirm
 * registration with a code
 * @param options react-query mutation options
 */
// export const useConfirmRegistration = (
//   options?: UseMutationOptions<void, any, ConfirmRegistrationProps>
// ) =>
//   useMutation(
//     ({ user, code }: ConfirmRegistrationProps) =>
//       sessionApi.confirmRegistration(user, code),
//     {
//       ...options,
//       onSuccess: (data, variables, context) => {
//         appQueryClient.setQueryData(USER_QUERY_KEY, variables.user);
//         storeSessionCookies(variables.user.getSignInUserSession());
//         options?.onSuccess?.(data, variables, context);
//       },
//     }
//   );

// Function to create a CognitoUser object
const createCognitoUser = (username: string) => {
  const userPoolData = {
    UserPoolId: process.env.REACT_APP_AWS_USER_POOL_ID?.toString() || "",
    ClientId: process.env.REACT_APP_AWS_CLIENT_ID?.toString() || "",
  };
  const userPool = new CognitoUserPool(userPoolData);
  const userData = {
    Username: username,
    Pool: userPool,
  };

  return new CognitoUser(userData);
};

export const useConfirmRegistration = (
  options?: UseMutationOptions<void, any, { username: string; code: string }>
) =>
  useMutation(
    ({ username, code }) => {
      const user = createCognitoUser(username);
      return sessionApi.confirmRegistration(user, code);
    },
    {
      ...options,
      onSuccess: (data, variables, context) => {
        options?.onSuccess?.(data, variables, context);
      },
      onError: (error, variables, context) => {
        options?.onError?.(error, variables, context);
      },
    }
  );
