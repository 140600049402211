export const calculateAge = (dateString: string) => {
    var today = new Date();
    var birthDate = new Date(dateString);

    var yearsDiff = today.getFullYear() - birthDate.getFullYear();
    var monthsDiff = today.getMonth() - birthDate.getMonth();
    var daysDiff = today.getDate() - birthDate.getDate();

    if (monthsDiff < 0) {
        yearsDiff--;
        monthsDiff += 12;
    }
    if (daysDiff <= 0) {
        daysDiff = 1;
    }

    return{years: yearsDiff, months: monthsDiff, days: daysDiff}
}