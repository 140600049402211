import { Icon, IconType } from "@wolfiesports/srm-component";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  NotificationEvent,
  NotificationState,
} from "../../models/notification-event.model";

import { useNavigate } from "react-router-dom";
import { NotificationService } from "../../services/notification.service";
import "./Notifications.scss";
import { getNotifications } from "../../utils/Notifications";

const maxDisplayed = 20;

export const NotificationPage = () => {
  const navigate = useNavigate();
  const navigateByUrl = (url: string | undefined) => {
    if (url) {
      navigate(url);
    }
  };
  const [notifications, setNotifications] = useState<any>([]);
  const intl = useIntl();

  useEffect(() => {
    const fetchNotifications = async () => {
      const fetchedNotifications = await getNotifications(maxDisplayed, intl);
      setNotifications(fetchedNotifications);
    };

    fetchNotifications();
  }, [intl]);

  const getNotificationIconUrl = (
    notification: NotificationEvent
  ): string | null => {
    switch (notification.state) {
      case NotificationState.good:
        return `${process.env.REACT_APP_AWS_S3_BUCKET}/media/assets/img/premium/badge.svg`;
      case NotificationState.warning:
        return `${process.env.REACT_APP_AWS_S3_BUCKET}/media/assets/img/notification/premium-notifications-02.svg`;
      case NotificationState.pending:
        return `${process.env.REACT_APP_AWS_S3_BUCKET}/media/assets/img/notification/premium-notifications-03.svg`;
      case NotificationState.error:
        return `${process.env.REACT_APP_AWS_S3_BUCKET}/media/assets/img/notification/premium-notifications-04.svg`;
      case NotificationState.challengeEnded:
        return `${process.env.REACT_APP_AWS_S3_BUCKET}/media/assets/img/system/info.svg`;
      default:
        return null;
    }
  };

  const renderNotification = (notification: NotificationEvent) => {
    const notificationIconUrl = getNotificationIconUrl(notification);
    const tradNotification =
      NotificationService.formatEventToNotification(notification, intl) ||
      notification;

    const defaultStyle = [
      NotificationState.good,
      NotificationState.warning,
      NotificationState.pending,
      NotificationState.error,
      NotificationState.challengeRewarded,
      NotificationState.challengeEnded,
    ];
    return (
      <div key={notification.target} className="notification-container">
        <div className="first-line d-flex p-2">
          <div className="notification-icon d-flex">
            {notificationIconUrl &&
              tradNotification.state &&
              defaultStyle.includes(tradNotification.state) && (
                <img src={notificationIconUrl} alt="" />
              )}
            {tradNotification.state === NotificationState.tournament && (
              <Icon icon={IconType.Tournament} />
            )}
          </div>
          <div className="notification-body">
            <div className="notification-title d-flex flex-column align-self-center ml-1">
              <span>{tradNotification?.i18n}</span>
              {tradNotification?.link && (
                <a
                  className="notification-link text-underscore clickable"
                  onClick={() => navigateByUrl(tradNotification.link?.to)}
                >
                  {tradNotification?.link?.text}
                </a>
              )}
            </div>
          </div>
        </div>
        <div className="notification-date text-right">
          <small>
            {intl.formatDate(tradNotification?.date, {
              timeStyle: "short",
              dateStyle: "short",
            })}
          </small>
        </div>
      </div>
    );
  };

  return (
    <div className="notification-list-page p-2">
      <div className="notifications-request-container d-flex flex-column">
        {notifications.slice(0, 20).map((n: NotificationEvent) => renderNotification(n))}
      </div>
      <div className="no-notifications mt-2 d-flex flex-column">
        {notifications.length === 0 && (
          <span>
            {intl.formatMessage({
              id: "notification.no_notifications",
            })}
          </span>
        )}
      </div>
    </div>
  );
};
