import { ReactSRMWrapper } from "@cactus/react-srm-wrapper";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    EventSend,
    EventSubscribe,
    useWebSocket,
} from "../../utils/websocket/WebSocketProvider";
import { EventSRMType } from "@wolfiesports/srm-component";

export const TournamentPage = () => {
  const originUrl = process.env.REACT_APP_BASE_BUNDLE_URL + "webapp-tournament";
  const [event, setEvent] = useState<any>(null);
  const navigate = useNavigate();
  const [language, setLanguage] = useState(
    localStorage.getItem("i18nextLng") ?? "en"
  );

  useEffect(() => {
    const handleLanguageChange = () => {
      const newLang = localStorage.getItem("i18nextLng") ?? "en";
      setLanguage(newLang);
    };

    window.addEventListener("languageChange", handleLanguageChange);

    return () => {
      window.removeEventListener("languageChange", handleLanguageChange);
    };
  }, []);
  const { subscribe, sendMessage } = useWebSocket();

  const handleNavigateByUrl = async (url: string) => {
    navigate(url);
  };

  const sendSrmEvent = async (data?: any): Promise<any> => {
    //console.log('sendSrmEvent', data);
    setEvent({ type: "ws", data: data });
  };

  const handleSubscribe = async (data?: EventSubscribe) => {
    //console.log('subscribe', data);
    if (data?.rt) {
      subscribe("srm", data.id, (room, message) => {
        if (!message || !room) {
          return;
        }
        sendSrmEvent(message);
      });
    }
    return;
  };

  const handleEventSend = async (data?: EventSend) => {
    if (data) {
      sendMessage(data.type, data.target, data.content);
    }
  };

  const handleLogin = async () => {
    navigate("/login");
  };

  const eventHandlers = {
    [EventSRMType.NAVIGATE_BY_URL]: handleNavigateByUrl,
    [EventSRMType.WS_SUBSCRIBE]: handleSubscribe,
    "srm.rt-send": handleEventSend,
    'login-user': handleLogin,
    /*'seo.generate-tags': this.setSEO.bind(this),
    'modal.payment': this.openPaymentModal.bind(this),
    'gtag.tournament_registration': this.gtagEvent.bind(this),*/
  };

  return (
      <ReactSRMWrapper
        key={language}
        originUrl={originUrl}
        exportPath={"nicecactus.tournament"}
        nav={navigate}
        language={language}
        basename={"/tournament"}
        eventHandlers={eventHandlers}
        event={event}
      />
  );
};
