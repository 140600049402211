export const languageToCountryMap: { [key: string]: string } = {
    af: 'ZA', // Afrikaans (South Africa)
    sq: 'AL', // Albanian (Albania)
    ar: 'SA', // Arabic (Saudi Arabia)
    hy: 'AM', // Armenian (Armenia)
    bn: 'BD', // Bengali (Bangladesh)
    bs: 'BA', // Bosnian (Bosnia and Herzegovina)
    ca: 'ES', // Catalan (Spain)
    hr: 'HR', // Croatian (Croatia)
    cs: 'CZ', // Czech (Czech Republic)
    da: 'DK', // Danish (Denmark)
    nl: 'NL', // Dutch (Netherlands)
    en: 'US', // English (United States)
    eo: 'ZZ', // Esperanto (No specific country)
    et: 'EE', // Estonian (Estonia)
    tl: 'PH', // Filipino (Philippines)
    fi: 'FI', // Finnish (Finland)
    fr: 'FR', // French (France)
    de: 'DE', // German (Germany)
    el: 'GR', // Greek (Greece)
    gu: 'IN', // Gujarati (India)
    hi: 'IN', // Hindi (India)
    hu: 'HU', // Hungarian (Hungary)
    is: 'IS', // Icelandic (Iceland)
    id: 'ID', // Indonesian (Indonesia)
    it: 'IT', // Italian (Italy)
    ja: 'JP', // Japanese (Japan)
    jw: 'ID', // Javanese (Indonesia)
    kn: 'IN', // Kannada (India)
    km: 'KH', // Khmer (Cambodia)
    ko: 'KR', // Korean (South Korea)
    la: 'VA', // Latin (Vatican City)
    lv: 'LV', // Latvian (Latvia)
    lt: 'LT', // Lithuanian (Lithuania)
    mk: 'MK', // Macedonian (North Macedonia)
    ml: 'IN', // Malayalam (India)
    mr: 'IN', // Marathi (India)
    my: 'MM', // Burmese (Myanmar)
    ne: 'NP', // Nepali (Nepal)
    no: 'NO', // Norwegian (Norway)
    pl: 'PL', // Polish (Poland)
    pt: 'PT', // Portuguese (Portugal)
    pa: 'IN', // Punjabi (India)
    ro: 'RO', // Romanian (Romania)
    ru: 'RU', // Russian (Russia)
    sr: 'RS', // Serbian (Serbia)
    si: 'LK', // Sinhala (Sri Lanka)
    sk: 'SK', // Slovak (Slovakia)
    sl: 'SI', // Slovenian (Slovenia)
    es: 'ES', // Spanish (Spain)
    su: 'ID', // Sundanese (Indonesia)
    sw: 'KE', // Swahili (Kenya)
    sv: 'SE', // Swedish (Sweden)
    ta: 'IN', // Tamil (India)
    te: 'IN', // Telugu (India)
    th: 'TH', // Thai (Thailand)
    tr: 'TR', // Turkish (Turkey)
    uk: 'UA', // Ukrainian (Ukraine)
    ur: 'PK', // Urdu (Pakistan)
    vi: 'VN', // Vietnamese (Vietnam)
    cy: 'GB', // Welsh (United Kingdom)
    xh: 'ZA', // Xhosa (South Africa)
    zu: 'ZA', // Zulu (South Africa)
    zh: 'CN', // Chinese (China)
    'zh-HK': 'HK', // Chinese (Hong Kong)
    'zh-TW': 'TW', // Chinese (Taiwan)
  };
  
  // Utility function to get the country code from the user's locale
  export const getUserCountry = (): string => {
    const formatter = new Intl.DateTimeFormat();
    const options = formatter.resolvedOptions();
    const locale = options.locale;
  
    const parts = locale.split('-');
    
    let countryCode = parts.length > 1 ? parts[1] : null;
  
    if (!countryCode) {
      const language = parts[0];
      countryCode = languageToCountryMap[language] || 'KE';  
    }
  
    return countryCode.toUpperCase();
  };