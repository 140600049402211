import './Profile.scss';

import { Icon, IconType, ProfileBanner, ProfilePicture } from '@wolfiesports/srm-component';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { getUserById } from '../../services/no-user';
import { UserInformations } from '../../components/UserInformations/UserInformations';
import { FavoriteGames } from '../../components/FavoriteGames/FavoriteGames';
import { TournamentHistory } from '../../components/TournamentHistory/TournamentHistory';
import { EditUserMedias } from '../../components/EditUserMedias/EditUserMedias';
import { PublicUserModel } from '../../models/User';
import { UserTeams } from '../../components/UserTeams/UserTeams';

export enum UserProfileMedia {
    BANNER = 'BannerImage',
    PICTURE = 'ProfileImage',
}

export const ProfilePage = () => {
    const { userId } = useParams();
    const [user, setUser] = useState<PublicUserModel>();
    const [isOwner, setIsOwner] = useState<boolean>(false);
    const [currentUser, setCurrentUser] = useState<any>();
    const [refresh, setRefresh] = useState<boolean>(false);
    const [showMediaEdition, setShowMediaEdition] = useState<boolean>(false);
    const [selectedMediaType, setSelectedMediaType] = useState<UserProfileMedia>();
    const [showUploadBannerIcon, setShowUploadBannerIcon] = useState<boolean>(false);
    const [showUploadProfileIcon, setShowUploadProfileIcon] = useState<boolean>(false);
    const intl = useIntl();

    useEffect(() => {
        getCurrentUser();
        getUserById(userId || "").then((response) => {
            setUser(response);
        });
        if (refresh) {
            setRefresh(false);
        }
    }, [refresh]);

    useEffect(() => {
        setIsOwner((currentUser && currentUser.identity.id === userId) ?? false);
    }, [user, currentUser]);

    const handleUpdateUser = async () => {
        setRefresh(true);
    };

    const editMedia = (type: UserProfileMedia) => {
        setSelectedMediaType(type);
        setShowMediaEdition(true);
    };

    const getCurrentUser = async () => {
        const u = JSON.parse(localStorage.getItem("user") ?? "");
        setCurrentUser(u);
    };

    if (!user) {
        return <></>;
    }

    return (
        <div className="user-profile">
            <div className="user-profile-wrapper">
                <div
                    className={`header ${isOwner ? 'owner' : ''}`}
                    onClick={() => {
                        if (isOwner) {
                            editMedia(UserProfileMedia.BANNER);
                        }
                    }
                }
                onMouseEnter={() => setShowUploadBannerIcon(true)}
                onMouseLeave={() => setShowUploadBannerIcon(false)}
                >

                    <ProfileBanner
                        isOwner={isOwner}
                        bannerId={user.banner}
                           >
                        {showUploadBannerIcon && isOwner
                            ? <div
                                className="upload-media"
                                title={
                                    intl.formatMessage({ id: 'tooltip.newpicture' })}
                            >
                                <Icon
                                    icon={IconType.ArtGallery}
                                    width={64}
                                    height={64}
                                />
                            </div>
                            : <></>
                        }
                    </ProfileBanner>
                </div>
                <div className="main-content">
                    <div className="left-panel">
                        <div
                            className={`user-picture ${isOwner ? 'owner' : ''}`}
                            onClick={() => {
                                if (isOwner) {
                                    editMedia(UserProfileMedia.PICTURE);
                                }
                            }}
                        >
                            <ProfilePicture
                                size={225}
                                isOwner={isOwner}
                                onMouseOver={setShowUploadProfileIcon}
                                avatarId={user.avatar}
                                isPremium={user.premium.status}                            >
                                {showUploadProfileIcon && isOwner
                                    ? <div
                                        className="upload-media"
                                        title={
                                            intl.formatMessage({ id: 'tooltip.newpicture' })}
                                    >
                                        <Icon
                                            icon={IconType.ArtGallery}
                                            width={64}
                                            height={64}
                                        />
                                    </div>
                                    : <></>
                                }
                            </ProfilePicture>
                        </div>
                        <UserInformations
                            user={user}
                            setCallback={setRefresh}
                            data-testid="user-profile-informations"
                        />
                        <FavoriteGames
                            user=
                            {user}
                            setCallback={setRefresh} />
                    </div>
                    <div className="right-panel">
                        <div className="user-teams">
                            <UserTeams user={user} />
                        </div>
                        <div className="tournament-history">
                            <TournamentHistory user={user} />
                        </div>

                    </div>
                </div>
            </div>
            {selectedMediaType && (
                <EditUserMedias
                    type={selectedMediaType}
                    user={user}
                    show={showMediaEdition}
                    setShow={setShowMediaEdition}
                    updateUser={handleUpdateUser}
                />
            )}
        </div>
    );
};
