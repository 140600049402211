import {
  CognitoUserAttribute,
  CognitoUserSession,
} from "amazon-cognito-identity-js";

import { ACCESS_TOKEN, ID_TOKEN, REFRESH_TOKEN } from "../../config/varStorage";
import { deleteCookie, setCookie } from "../../utils/cookies";

/**
 * create a MFA config object
 * @param enabled a boolean
 * @returns a json object representing a MFA configuration
 */
export const getMfaConfigObj = (enabled: boolean) => ({
  PreferredMfa: true,
  Enabled: enabled,
});

/**
 * create a CognitoUserAttribute
 * @param name name of the attribute
 * @param value value of the attribute
 * @returns a promise supposed to return a cognitoUserAttribute object
 */
export const createAttribute = (
  name: string,
  value: string
): CognitoUserAttribute => {
  return new CognitoUserAttribute({
    Name: name,
    Value: value,
  });
};

/**
 * transform expiration token value to a readable UTC date
 * @param expiration a number representing an amount of second since 1901
 * @returns a utc date as a string
 */
export const tokenExpirationToUTCString = (expiration: number): string =>
  new Date(expiration * 1000).toUTCString();

  export const tokenMaxExpirationToUTCString = (expiration: number): string =>
  new Date((expiration+31536000)* 1000).toUTCString();

/**
 * extract and store session credential in browser cookies
 * @param session a cognitoUserSession
 */
export const storeSessionCookies = (
  session?: CognitoUserSession | null
): void => {
  if (session) {
    const accessToken = session.getAccessToken();
    const accessTokenExpiration = tokenExpirationToUTCString(
      accessToken.getExpiration()
    );
    const idToken = session.getIdToken();
    const idTokenExpiration = tokenExpirationToUTCString(
      idToken.getExpiration()
    );

    const idTokenMaxExpiration = tokenMaxExpirationToUTCString(idToken.getExpiration());
    setCookie(ID_TOKEN, idToken.getJwtToken(), { Expires: idTokenExpiration });
    setCookie(ACCESS_TOKEN, accessToken.getJwtToken(), {
      Expires: accessTokenExpiration,
    });
    setCookie(REFRESH_TOKEN, session.getRefreshToken().getToken(), { Expires: idTokenMaxExpiration });
  }
};

/**
 * clear all credentials from cookies
 */
export const clearSessionCookies = (): void => {
  deleteCookie(ID_TOKEN);
  deleteCookie(ACCESS_TOKEN);
  deleteCookie(REFRESH_TOKEN);
};
