import { Game } from "@wolfiesports/srm-component";

export interface TranslatedString {
    [lang: string]: string;
}

export class Broadcast {
    _id: string;
    disabled: boolean;
    primary: boolean;
    games: Game[];
    readonly createdAt: Date;
    readonly finishedAt?: Date;
    message?: string;
    htmlMessage?: string;

    constructor({
        _id = '',
        disabled = false,
        primary = false,
        games = [],
        createdAt = new Date(),
        finishedAt,
        message,
        htmlMessage,
    }: Partial<Broadcast> = {}) {
        this._id = _id;
        this.disabled = disabled;
        this.primary = primary;
        this.games = games;
        this.createdAt = createdAt;
        this.finishedAt = finishedAt;
        this.message = message;
        this.htmlMessage = htmlMessage;
    }
}