import "./Legals.scss";

export const TermOfSalesPage = () => {
  window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  return (
    <div className="terms-of-use">
      <h1>TERMS OF SALE</h1> <br />
      <br /> <br />
      <b>PREAMBLE</b> <br /> <br />
      The present Terms of Sale (hereinafter referred to as the ”Terms ” ) shall
      apply to any order placed by a consumer, as per the meaning in the Sale of
      Goods Act (cap 31) of the laws of Kenya of the Services offered for sale
      on the Website 
      <a href="https://www.wolfiesports.com"> https://www.wolfiesports.com </a>
      (hereinafter referred to as the ”Website”) by the Company, a limited
      liability company registered under Registration number PVT-V7UAM25Z and
      having its registered address at Fortis Tower, Westlands, Nairobi , Kenya 
      (hereinafter referred to as the “ Company”). The Website is hosted by
      AMAZON WEB SERVICES EMEA, a limited liability company, registered with the
      Luxembourg Trade and Companies Register under number 831 001 334,
      headquartered at 38 Avenue John F. Kennedy L 1855 99137 LUXEMBOURG
      (hereinafter the "Web host").
      <br /> <br />
      <b>ARTICLE 1 - OBJECT</b> <br /> <br />
      The Company sells subscription-based services on its Website (hereinafter
      the " Services ") to consumers registered on its Website (hereinafter the
      "Customers"). <br />
      The Customer shall be understood as a natural person of legal age or have
      at least the legal capacity to contract, and who acts for purposes that do
      not fall within the scope of their commercial, industrial, craft, or
      liberal activity.
      <br /> <br />
      For the purposes hereof, the Customer and The Company will be collectively
      referred to as the “Parties” and individually referred to as the “Party”.
      <br /> <br />
      These Terms shall apply to any subscription to the Services purchased via
      the Website. The Customer must accept the Terms before proceeding with
      payment by ticking the box "I accept the Terms of Sale". <br /> <br />
      The purpose of these Terms is to define the rights and obligations of the
      Parties resulting from the online sale of Services available on the
      Website. They govern the various steps required to conclude and execute
      orders between the Parties. <br /> <br />
      The Company reserves the right to modify these Terms at any time, by
      publishing a new version on the Website. The applicable Terms are those in
      effect on the date the order is placed. <br /> <br /> <br />
      <b>ARTICLE 2 - SERVICES </b> <br /> <br />
      Only the Services featured on the Website on the day the order is placed
      and identified as available may be sold and delivered to the Customer.
      <br />
      The Service offer and applicable prices are valid for as long as the
      Services are available on the Website.
      <br /> <br />
      Customers shall refer to Service feature lists for details of each Service
      plan.
      <br />
      <br /> <br />
      <b>ARTICLE 3 - PRICES </b> <br /> <br />
      Service prices are indicated in Euros, Dollars or Kenyan Shillings, depending on the
      currency selected by the user in the account settings. The Value Added Tax
      (VAT) is the one in effect on the day the order is placed or renewed. Any
      change in the VAT applicable rate can be reflected in Service prices.
      <br /> <br />
      The prices applied are those indicated on the Website at the time the
      order is placed or upon renewal of the Service. These prices may be
      updated at any time after that date.
      <br /> <br />
      If an obviously erroneous price is indicated on the Website due to a
      typing error or other similar reasons, such as a technical issue (computer
      bug, manual error, technical error) the Company shall not be bound by the
      erroneous price. In such a case, the Company will inform the Customer as
      soon as possible and the order will be canceled.
      <br /> <br />
      The Company may offer promotional codes. These promotional codes can only
      be generated by the Company and will not apply to the price of Services
      that are subject to other promotional offers (sales, bonus sales, price
      reduction, sponsorship offers, etc.).
      <br /> <br />
      The Company cannot be held responsible for any promotional codes released
      on forums, to which the Company did not consent, except those generated
      and released by the Company. <br /> <br /> <br />
      <b>ARTICLE 4 – DURATION</b>
      <br /> <br />
      The Terms are accepted by the Customer upon subscription. The Customer
      must accept the Terms before proceeding with the payment. The Terms thus
      accepted are applicable for the duration necessary for the provision of
      the Services, and until the expiry of the guarantees provided for herein.
      <br /> <br />
      The Customer must accept the Terms for each new order.
      <br /> <br /> <br />
      <b>ARTICLE 5 - ORDERS</b> <br /> <br />
      5.1. Order placement
      <br /> <br />
      To place an order, the Customer must first create a personal account. To
      create an account, he must accurately fill in the fields identified by an
      asterisk as mandatory. They must provide the information necessary for
      identification, including a valid email address and a private password of
      their choice, which they must keep confidential. These will serve as
      identifiers to log on to the Website. For security purposes, the private
      password must be at least six (6) characters long and contain letters and
      numbers. The Customer is responsible for the choice of the conservation of
      his identifiers and must ensure their confidentiality. The Company cannot,
      under any circumstances, be held responsible for any identity theft and
      connections to customer accounts by third parties, if the Customer has not
      followed the aforementioned instructions.
      <br /> <br />
      The provision of personal information collected in the context of distance
      selling is mandatory. This information is necessary to process order
      deliveries and issue invoices. This information is strictly confidential.
      The Company undertakes to collect only the data strictly necessary for
      account creation and order placement purposes.
      <br /> <br />
      Once his personal account has been created, Customers can subscribe to a
      Premium Account by clicking on “Go premium” in his profile. This is a
      tacitly renewable monthly subscription.
      <br /> <br />
      5.2. Payment
      <br /> <br />
      The price to be paid by the Customer is the amount stated on the order
      summary the Customer reads before the final validation of his purchase.
      Once payment has been made, the price is also stated in the invoice, sent
      by email to the Customer by Stripe (see Article 5.3).
      <br /> <br />
      The Stripe platform is the payment platform used by the Company to manage
      all payments. All Customers' banking data are directly processed by their
      services. For more information or to contact them, you can visit
      www.stripe.com. Consequently, the Company cannot be held liable for any
      payment issues or problems that may result from the bank details provided
      by the Customer.
      <br /> <br />
      The Customer can choose one of the following methods to pay his purchases:
      <br />• By credit card via the payment platform Stripe (Blue card, Visa,
      Eurocard, Mastercard, American Express) <br />• Via PayPal <br />• Mpesa
      Mobile Money Wallet
      <br /> <br />
      Regarding payment by credit card, payment terms are as follows:
      <br /> <br />
      Notwithstanding the application of article R. 642-3 of the French Penal
      Code, the Customer will pay his order by credit card (cards with the CB
      symbol, cards of the br/ands VISA or EUROCARD or MASTERCARD) in accordance
      with the provisions of this article.
      <br /> <br />
      To pay his order by credit card, the Customer must enter his credit card
      number on the Website, along with its expiry date and the security code
      (3-digit number printed on the back of the credit card). This data is
      encoded on Stripe servers, which is the payment processing platform used
      by the Company to manage all payments. The Company does not keep
      Customers' banking data, they are directly managed by Stripe. Stripe keeps
      the Customers' banking data for the duration of their subscription. Such
      data will only be deleted by Stripe when the subscription is terminated.
      <br /> <br />
      In addition, the card will be debited at the time of the order. The amount
      debited corresponds to the Service(s) actually ordered.
      <br /> <br />
      In all cases, the online supply of the credit card number or any other
      payment method and the final validation of the order shall be proof of the
      entire order, pursuant to articles 1353 et seq. of the French Civil Code.
      They will also cause the sums incurred for the order to become due and
      payable. <br /> <br />
      This validation is worth signing and express acceptance of all the
      operations performed on the Website.
      <br /> <br />
      Finally, the Customer ticks the box "I accept the Terms of Sale" and
      clicks on "Agree and pay" to finalize his order.
      <br /> <br />
      For any provision of a Service, the bank installments corresponding to the
      renewal of monthly subscriptions will be processed with the bank
      information provided by the Customer when he first placed an order.
      <br /> <br />
      Since these Services may be renewed each month, Stripe, the payment
      platform on which the Customer's bank data is stored, will inform the
      Customer by email, seven (7) days before the payment is to be processed
      for the upcoming month.
      <br />
      <br />
      The Mpesa Terms and Conditions can be found here
      <a href="https://www.safaricom.co.ke/about/media-center/publications/terms-and-conditions/m-pesa-customer-terms-conditions">
        https://www.safaricom.co.ke/about/media-center/publications/terms-and-conditions/m-pesa-customer-terms-conditions
      </a>
      <br /> <br />
      5.3. Order acknowledgment
      <br /> <br />
      Upon completion of payment, Stripe sends a summary of the order named
      "Invoice" by email to the email address provided by the Customer.
      <br /> <br />
      This document serves as an acknowledgment of receipt and includes all the
      elements of the contract concluded between the Parties.
      <br /> <br />
      5.4. Order follow-up and history
      <br /> <br />
      For ongoing order follow-ups, the Customer can log into his personal
      account and go to the section "Active subscriptions". <br /> <br />
      In the "Subscription history" section, he can find all past subscriptions
      he has purchased on the platform.
      <br /> <br />
      5.5. Length of contract
      <br /> <br />
      For the order of Services, unless otherwise agreed, all subscriptions take
      effect from the first day of subscription, for a fixed term of one month,
      renewable.
      <br /> <br />
      At the end of the initial period, in order to avoid any discontinuity in
      the Service, subscription contracts are automatically and tacitly renewed,
      at the current rate communicated on the Site, unless the user cancels the
      subscription by clicking on "Account Settings" and "Cancel Subscription",
      before the expiry date of the current month.
      <br />
      Finally, once the Customer has paid for his Service for the month started,
      even if he requests the cancellation of his subscription, it continues to
      run for the current mont paid for. The Customer continues to benefit from
      his subscription for the entire month that he has paid for. Once the month
      is over, the subscription ends and the Customer can no longer benefit from
      the Service.
      <br /> <br /> <br />
      <b>ARTICLE 6 - RIGHT OF WITHDRAWAL AND REFUNDS</b> <br /> <br />
      6.1. Trial period
      <br /> <br />
      The Customer has the possibility to benefit from a fifteen (15) day trial
      period of the premium subscription. At the end of this period, the
      Customer is no longer able to benefit from the services offered by the
      premium subscription. To do so, he will have to subscribe to a paid
      premium offer, at the rate displayed on the Site.
      <br /> <br />
      6.2. Absence of the right of withdrawal
      <br /> <br />
      The withdrawal period relating to the order of a Service is governed by
      the Consumer Code.
      <br /> <br />
      In addition, article L.121-21-8 of the Consumer Code provides that the
      right of withdrawal does not apply to the following contracts:
      <br /> <br />• Services contracts fully executed before the end of the
      withdrawal period and whose execution has begun after prior express
      consent of the consumer and express waiver of his right of withdrawal;
      <br />• Digital content not supplied on a material medium and whose
      execution has begun after prior express consent of the consumer and
      express waiver of his right of withdrawal.
      <br /> <br />
      Prior express consent of the consumer shall be understood as the
      acceptance of these Terms upon placing the order.
      <br /> <br />
      Directive 2011/83/EU confirms the Consumer Code by stating in Article 16
      that it is impossible to apply the right of withdrawal for the supply of
      digital content "if the execution has begun with the prior express consent
      of the consumer, who has also acknowledged that he will thus lose his
      right of withdrawal". <br /> <br /> <br />
      <b>ARTICLE 7 - CUSTOMER SERVICE</b> <br /> <br />
      The Company's customer service is available from 9:00 AM to 5:00 PM (EAT)
      to answer at queries and information requests. The Customer can contact
      the customer service via the forms available on the website
      <a href="https://www.wolfiesports.com"> https://www.wolfiesports.com </a>:
      <br /> <br />
      If the Customer are not connected to his/her user account he/she can
      access the contact form at the bottom of the wolfiesports.com home page
      entitled "Contact Us". He/she must fill in the following fields: name,
      email, select an option from the drop-down menu (sponsorship, support,
      etc.) and complete his/her request by explaining it in the box provided.
      Then, he/she must click on "send". <br /> <br />
      If the Customer are logged into his/her Wolfiesport user account, he/she
      can access the contact form in the menu on the left side of his/her home
      screen "Contact Us". The Customer must complete the following fields: name
      (optional), email, explain his/her request in the box provided. The
      Customer have the possibility to attach up to 5 files. Finally, he/she
      must click on "send”.
      <br /> <br /> <br />
      <b>ARTICLE 8 – GUARANTEE</b> <br /> <br />
      Our Services shall enjoy the legal guarantee scheme as per this article,
      provided that they have been used properly.
      <br /> <br />
      The Customer may decide to execute the guarantee against latent defects of
      the goods sold, pursuant to article 1641 of the French Civil Code. In this
      case, the Customer may choose between the cancelation of the sale or a
      reduction of the selling price pursuant to article 1644 of the French
      Civil Code. <br /> <br />
      Any request concerning these guarantees shall be sent to the Customer
      service via the forms available on the website
      <a href="https://wolfiepsports.com"> https://wolfiepsports.com </a> : <br />
      <br />
      If the Customer are not connected to his/her user account he/she can
      access the contact form at the bottom of the wolfiesports.com home page
      entitled "Contact Us". He/she must fill in the following fields: name,
      email, select an option from the drop-down menu (sponsorship, support,
      etc.) and complete his/her request by explaining it in the box provided.
      Then, he/she must click on "send". <br /> <br />
      If the Customer are logged into his/her Wolfiesport user account, he/she
      can access the contact form in the menu on the left side of his/her home
      screen "Contact Us". He/she must complete the following fields: name
      (optional), email, explain his/her request in the box provided. He/she
      have the possibility to attach up to 5 files. Finally, he/she must click
      on "send”.
      <br /> <br />
      It should be noted that, in the event of a professional purchase, while
      the Customer is informed of his declared quality of Consumer, the Company
      will not be liable for any damage that may arise as a result of the
      purchase or use of the Services for professional use.
      <br /> <br /> <br />
      <b>ARTICLE 9 – TRANSACTION PROOF, RETENTION AND ARCHIVING</b> <br />
      <br />
      The Company recommends that Customers keep a hard copy record, or a record
      on a reliable IT support, of any data related to his order. <br /> <br />
      Computer records, kept in the Company computer systems under reasonable
      security conditions, shall be considered as proof of communications,
      orders, deliveries and payments between the Parties.
      <br /> <br />
      Invoice archiving is carried out on a reliable and durable medium in order
      to correspond to a faithful and durable copy.
      <br /> <br />
      In addition, and in accordance with Kenyan Data Protection act of 2019 ,
      the Company is committed to keep and archive on all media, for 10 years,
      all contracts concluded between the Customer and the Company of a value
      equal to or greater than 120 EUROS (€) and to guarantee the Customer
      access to them at any time. <br /> <br />
      This right of access may be exercised via the forms available on the
      website
      <a href="https://wolfiesports.com"> https://wolfiesports.com </a>. <br />
      <br />
      If the Customer are not connected to his/her user account he/she can
      access the contact form at the bottom of the wolfiesports.com home page
      entitled "Contact Us". He/she must fill in the following fields: name,
      email, select an option from the drop-down menu (sponsorship, support,
      etc.) and complete his/her request by explaining it in the box provided.
      Then, he/she must click on "send". <br /> <br />
      If the Customer are logged into his/her Wolfiesport user account, he/she
      can access the contact form in the menu on the left side of his/her home
      screen "Contact Us". He/she must complete the following fields: name
      (optional), email, explain his/her request in the box provided. He/she
      have the possibility to attach up to 5 files. Finally, he/she must click
      on "send”.
      <br /> <br /> <br />
      <b>ARTICLE 10 – PERSONAL DATA</b> <br /> <br />
      In the context of distance selling, the Company is required to process
      personal data concerning the Customer.
      <br /> <br />
      The Customer and the Company acknowledge that they are fully aware of the
      obligations of the regulations relating to the protection of personal
      data, i.e. any European or national legislative or regulatory provision,
      resulting in particular from Regulation (EU) 2016/679 of 27 April 2016 on
      the protection of individuals with regard to the processing of personal
      data, as well as any other applicable regulations on the subject, which
      may subsequently supplement or replace them. <br /> <br />
      Thus, the Company undertakes to collect only the Customer's personal data
      that is strictly necessary for the proper functioning of the services it
      offers. <br /> <br />
      The Company informs the Customer that it will collect the following
      personal data concerning the Customer:
      <br /> <br />• First name and surname <br />• Address <br />• Email <br />
      • Username <br />• Game history <br />• Conversation history on the Site's
      Forum <br />• Country of origin for statistical purposes
      <br /> <br />
      The Customer is hereby informed that this data will be kept by the Company
      for the time necessary for processing and until his account is deleted.
      <br /> <br />
      In addition, the Customer has the right to access, rectify and delete
      personal data concerning him/her. The Customer may address any request to
      the department in charge. The Customer can contact the Company via the
      forms available on the
      <a href="https://www.wolfiesports.com"> https://www.wolfiesports.com </a> website,
      as follows: <br /> <br />
      If the Customer are not connected to his/her user account, he/she can
      access the contact form at the bottom of the wolfiesports.com home page
      entitled "Contact Us". The Customer must fill in the following fields:
      name, email, select an option from the drop-down menu (sponsorship,
      support, etc.) and complete his/her request by explaining it in the box
      provided. Then, the Customer must click on "send". <br /> <br />
      If the Customer are connected to his/her Wolfiesport user account, he/she
      can access the contact form in the menu on the left side of his/her home
      screen "Contact Us". The Customer must complete the following fields: name
      (optional), email, explain his/her request in the box provided. The
      Customer have the possibility to attach up to 5 files. Finally, the
      Customer must click on "send". <br /> <br />
      In addition, specific mentions appear on each collection form in order to
      ensure that the Customer is fully informed before any processing of the
      data concerning him/her.
      <br /> <br />
      Finally, the Customer is informed that his banking data will be fully
      processed by the services of the payment platform Stripe, the payment
      platform used by the Company. Consequently, the Company cannot be held
      responsible for the processing of the Customer's banking data. If the
      Customer wishes to have more information on the processing of his data by
      the Stripe platform, he can consult the following link : www.stripe.com.
      <br /> <br />
      For more information concerning the processing of the Customer's personal
      data, the Customer may consult the Personal Data Protection Charter
      accessible from the following link :
      <a href="https://wolfiesports.com/privacy"> https://wolfiesports.com/privacy </a>
      .<br /> <br /> <br />
      <b>ARTICLE 11 - FORCE MAJEURE</b> <br /> <br />
      The Company shall not be held responsible for the total or partial failure
      to comply with its obligations in respect of the Terms, if this failure to
      comply results either from the Customer or from an event of force majeure
      as defined in article 1218 of the French Civil Code, including in
      particular total or partial strikes of communication services. <br />
      <br />
      The Company will notify the Customer of an event of force majeure within
      five (5) working days of its occurrence. <br /> <br />
      The Parties agree to consult each other as soon as possible to determine
      how the order is to be processed during the event of force majeure. <br />
      <br />
      Beyond a period of thirty (30) days of interruption for events of force
      majeure, the Parties will be released from their obligations to one
      another. If applicable, the Company will refund the Customer as soon as
      possible. <br /> <br /> <br />
      <b>ARTICLE 12 - ENTIRE TERMS</b> <br /> <br />
      In the event that any of the provisions of these Terms would be null or
      void as a result of a change in legislation, regulation or a court
      decision, this cannot in any way affect the validity and compliance of the
      other provisions of the Terms. <br /> <br /> <br />
      <b>ARTICLE 13 - APPLICABLE LAW AND DISPUTE SETTLEMENT</b> <br /> <br />
      These Terms are governed by French law.
      <br /> <br />
      In case of litigation, the Company and the Customer undertake to seek an
      amicable solution before any legal action is taken. <br /> <br />
      13.1. Mediation and amicable dispute settlement
      <br /> <br />
      Pursuant to the provisions of articles L. 611-1 et seq. of the French
      Consumer Code, the Customer may have recourse to the services of a
      mediator, free of charge, for the amicable settlement of any unresolved
      dispute with the Company.
      <br /> <br />
      13.2. Conditions of admissibility
      <br /> <br />
      It is hereby stated that the dispute may only be examined by the mediator
      under the following conditions:
      <br /> <br />• The consumer will have to justify having attempted to
      resolve his dispute directly with the Company, by means of a written
      claim;
      <br />• His request must not be manifestly unfounded or clearly abusive;
      <br />• The dispute must not have been examined before or be under
      examination by another mediator or court; <br />• The consumer has
      submitted his request to the mediator within a period of less than one
      year after his written claim to the Company; <br />• The dispute does not
      come within his scope of jurisdiction. <br /> <br />
      Finally, the Company informs its Customers of the existence of a European
      platform for amicable settlement of online disputes (ODR). You can use it
      to settle your disputes, through their website:
      <a href="http://ec.europa.eu/odr"> http://ec.europa.eu/odr</a> .<br />
      <br />
    </div>
  );
};
