import "./Auth.scss";
import { Login } from "./Login/Login";
import { Register } from "./Register/Register";

export const AuthPage = () => {
  const location = window.location.pathname;

  return (
    <>
      {location === "/login" && <Login />}
      {location === "/register" && <Register />}
    </>
  );
};
