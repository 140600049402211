import { ReactSRMWrapper } from "@cactus/react-srm-wrapper";
import { useNavigate } from "react-router-dom";

export const TeamPage = () => {
  const originUrl = process.env.REACT_APP_BASE_BUNDLE_URL + "webapp-team";
  
  const navigate = useNavigate();
  const language = localStorage.getItem("i18nextLng") ?? "en";

  const handleNavigateByUrl = async (url: string) => {
    navigate(url);
  };

  const eventHandlers = {
    navigateByUrl: handleNavigateByUrl,
  };

  return (
    <ReactSRMWrapper
      originUrl={originUrl}
      exportPath={"nicecactus.team"}
      nav={navigate}
      language={language}
      basename="/team"
      eventHandlers={eventHandlers}
    />
  );
};
